import React, {useState, useEffect, useContext} from "react";
import { Stack, Typography, Button, Pagination} from "@mui/material";
import useLanguage from "../hooks/useLanguage";
import SearchFilter from "../tools/SearchFilter";
import useData from "../hooks/useData";
import TableRow from "../tools/TableRow";
import { GifsContext } from "../state/gifContext";
import axiosInstance from "../../axios";
import { AuthStateContext } from "../state/AuthStateContext";
import { formatDate2, limit2Nbr } from "../../helper/formatDate";
import SlidingTypography from "../tools/SlidingTypography";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PopAccountant from "./PopAccountant";
import { Link } from "react-router-dom";
import { baseURL } from "../../helper/conf";
import { cData } from "../tools/Snackbars";

const InvoiceAuto = ({setStatement}) => {
    const { globalFilter } = useContext(GifsContext)
    const [filter, setFilter] = useState(() => ({
        ...globalFilter,
        country: '',
        payment: ''
    }))

    const { text } = useLanguage()
    const { authState } = useContext(AuthStateContext)
    const { languagesList, paymentList, getV } = useData()
    

    // LIST INVOICE AUTO PAYMENT
    const [ datas, setDatas ] = useState()
    const [ total, setTotal ] = useState()
    const [ pagination, setPagination ] = useState()
    const [page, setPage] = useState(1);
    const [ period, setPeriod ] = useState()

    const handleChange = (event, value) => {
        setPage(value);
    };

    useEffect(()=>{
        const type = ""
        axiosInstance.get(`/paymentBy?type=${type}&year=${filter?.year}&month=${filter?.month}&date=${formatDate2(filter?.date)}&platform=${filter?.platforms}&week=${filter?.semaine}&startingDate=${formatDate2(filter?.startingDate)}&closingDate=${formatDate2(filter?.closingDate)}&page=${page}&payment=${filter?.payment}&agent=${filter?.agents}&country=${filter?.country}&language=${filter?.language}`, {
            headers: {
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then((response) => {
            setPeriod(response.data.period)
            setDatas(response.data.data)
            setTotal(response.data.total)
            setPagination(response.data.pagination)
        })
        .catch((error) => {
            // console.log(error)
        })
    },[filter, page])

    // POP-UP
    const [ open, setOpen ] = useState(false)
    const [ dataTo, setDataTo ] = useState()
    const [ typePop, setTypePop ] = useState()

    // GET PRINT PDF INVOICE AUTO
    const [ loading, setLoading ] = useState(false)
    const handlePrint = () => {
        const type = ""
        const isPrint = true
        setLoading(true)
        axiosInstance.get(`/paymentBy?isPrint=${isPrint}&type=${type}&year=${filter?.year}&month=${filter?.month}&date=${formatDate2(filter?.date)}&platform=${filter?.platforms}&week=${filter?.semaine}&startingDate=${formatDate2(filter?.startingDate)}&closingDate=${formatDate2(filter?.closingDate)}&page=${page}&payment=${filter?.payment}&agent=${filter?.agents}&country=${filter?.country}&language=${filter?.language}&title=${cData(getV(1))}`, {
            headers: {
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then((response) => {
            // console.log(response.data.pdfName)
            const pdfName = response.data.pdfName;

            // Ouvrir le PDF dans un nouvel onglet
            const downloadUrl = `${baseURL}/print/${pdfName}`;
            window.open(downloadUrl, '_blank');

            setLoading(false)
        })
        .catch((error) => {
            // console.log(error)
            setLoading(false)
        })
    }

    return (
        <Stack spacing={2} padding="20px 0">
            <PopAccountant type={typePop} data={dataTo} open={open} setOpen={setOpen} setDatas={setDatas} setStatement={setStatement} period={period} />

            <SearchFilter payment
                onDateChange={{
                    year: y => setFilter({...filter, year: y}),
                    month: m => setFilter({...filter, month: m}),
                }}
                items= {[
                    { label: text.platform , comp: 'TextInput', onChange: e => setFilter({...filter, platforms: e.target.value}) },                    
                    { label: text.language, comp: 'Select', choices: languagesList, onChange: e => setFilter({ ...filter, language: e.target.value }) },
                    { label: text.agent, comp: 'TextInput', onChange: e => setFilter({...filter, agents: e.target.value}) },
                    { label: text.agcountry, comp: 'TextInput', onChange: e => setFilter({...filter, country: e.target.value}) },
                ]}
            />
            
            <Stack spacing={'10px'}>
                <TableRow header columns={[
                    { xs: 0.9,  text: "Agent Full Name"},
                    { xs: 0.9,  text: "Agent Full Address"},
                    { xs: 0.9,  text: "Agent Country of residency"},
                    { xs: 0.7,  text: text.language},
                    { xs: 0.9,  text: text.login},
                    { xs: 0.9,  text: "Total payment"},
                    { xs: 0.9,  text: text.paymeth},
                    { xs: 1.2,  text: "Email address of payment / IBAN-BIC"},
                    { xs: 0.9,  text: "Invoice actions"},
                    { xs: 1.2,  text: "Invoice status"},
                    { xs: 0.9,  text: "Payment status"},
                ]}/>
                { datas?.map((data, i) => (
                    <TableRow
                        key={i}
                        columns={[
                            { xs: 0.9, text: data.fullName },
                            { xs: 0.9, text: data.fullAddress },
                            { xs: 0.9, text: data.country },
                            { xs: 0.7, text: data.language },
                            { xs: 0.9, text: data.login },
                            { xs: 0.9, text: `${limit2Nbr(data.totalPayment)} €` },
                            { xs: 0.9, text: data.paymentMethod },
                            {
                                xs: 1.2,
                                customContent: true,
                                content: data.paymentMethod == "wireTransfer" ? (
                                    <Stack sx={{ width: '100%'}}>
                                        <SlidingTypography color='#000' delm textAlign="center" fast>
                                            Name : {data.bankDetails.name}
                                        </SlidingTypography>
                                        <SlidingTypography color='#000' delm textAlign="center" fast>
                                            IBAN: {data.bankDetails.iban}
                                        </SlidingTypography>
                                        <SlidingTypography color='#000' delm textAlign="center" fast>
                                            BIC: {data.bankDetails.bic}
                                        </SlidingTypography>
                                    </Stack>
                                ) : (
                                    <Stack sx={{ width: '100%'}}>
                                        <SlidingTypography color='#000' delm textAlign="center" fast>
                                            Email: {data.bankDetails.email}
                                        </SlidingTypography>
                                    </Stack>
                                ),
                            },
                            {
                                xs: 0.9,
                                customContent: true,
                                content: (
                                    <Stack sx={{ width: '100%', gap: "8px", justifyContent: "center", alignItems: "center"}}>
                                        <Button 
                                            // disabled = {data.generate.status}
                                            variant='contained' 
                                            sx={{ 
                                                fontWeight: "600", 
                                                fontSize: "12px",
                                                maxWidth: "80px",
                                                bgcolor: "#311b92", 
                                                color: "white",
                                                "&:hover": {
                                                    bgcolor: "#512da8",
                                                }
                                            }}
                                            onClick={() => { setTypePop("generate"); setDataTo(data); setOpen(true)} }
                                        >
                                            Generate
                                        </Button>
                                        { data.generate.status ? 
                                            <Button 
                                                variant='contained' 
                                                sx={{ 
                                                    fontWeight: "600", 
                                                    fontSize: "12px",
                                                    maxWidth: "80px",
                                                    bgcolor: "#0d47a1",
                                                    color: "white",
                                                    "&:hover": {
                                                        bgcolor: "#1976d2",
                                                    }
                                                }}
                                                onClick={() => { setTypePop("send"); setDataTo(data); setOpen(true)} }
                                            >
                                                {data.send.status ? "Resend" : "Send"}
                                            </Button>
                                            :
                                            <Button 
                                                disabled
                                                variant='contained' 
                                                sx={{ 
                                                    fontWeight: "600", 
                                                    fontSize: "12px",
                                                    maxWidth: "80px",
                                                    bgcolor: "#0d47a1",
                                                    color: "white",
                                                    "&:hover": {
                                                        bgcolor: "#0d47a1",
                                                    }
                                                }}
                                            >
                                                Send
                                            </Button>
                                        }
                                    </Stack>
                                ),
                            },
                            {
                                xs: 1.2,
                                customContent: true,
                                content: (
                                    <Stack sx={{ width: '100%', justifyContent: "center", alignItems: "center"}}>
                                        <SlidingTypography color='#000' delm textAlign="center" fast>
                                            Generated: <span style={{ color: '#1b5e20' }}>{data.generate.status ? data.generate.date : "Not yet"}</span>
                                        </SlidingTypography>
                                        <SlidingTypography color='#000' delm textAlign="center" fast>
                                            Sent on: <span style={{ color: '#1b5e20' }}>{data.send.date != "" ? data.send.date : "Not yet"}</span>
                                        </SlidingTypography>
                                        { data.generate.status ? 
                                            <Link 
                                                to={`${baseURL}/invoice/${data.invoicePdf}`}
                                                target="_blank" 
                                                rel="noopener noreferrer"
                                            >
                                                <Button 
                                                    variant='contained'
                                                    sx={{ 
                                                        fontWeight: "600", 
                                                        fontSize: "12px",
                                                        maxWidth: "80px",
                                                        bgcolor: "#dd2c00",
                                                        color: "white",
                                                        "&:hover": {
                                                            bgcolor: "#f4511e",
                                                        }
                                                    }}
                                                >
                                                    See
                                                </Button>
                                            </Link>
                                            : 
                                            <Button 
                                                disabled
                                                variant='contained' 
                                                sx={{ 
                                                    fontWeight: "600", 
                                                    fontSize: "12px",
                                                    maxWidth: "80px",
                                                    bgcolor: "#dd2c00",
                                                    color: "white",
                                                    "&:hover": {
                                                        bgcolor: "#f4511e",
                                                    }
                                                }}
                                            >
                                                See
                                            </Button>
                                        }
                                    </Stack>
                                ),
                            },
                            {
                                xs: 0.9,
                                customContent: true,
                                content: (
                                    <div style={{ width: '100%', height: "100%" }}>
                                        { data.payment.status ? 
                                            <Stack sx={{ width: '100%', height: "100%", bgcolor: "#4caf50", justifyContent: "center", alignItems: "center"}}>
                                                <Typography color='#000' textAlign="center" >
                                                    <CheckCircleOutlineIcon  />
                                                </Typography>
                                                <SlidingTypography color='#000' delm textAlign="center" fast>
                                                    {data.payment.date}
                                                </SlidingTypography>
                                            </Stack>
                                            : 
                                            ( data.send.status ?
                                                <Stack 
                                                    sx={{ width: '100%', height: "100%", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
                                                    onClick={() => { setTypePop("status"); setDataTo(data); setOpen(true)} }
                                                >
                                                    <Typography color='#000' textAlign="center" sx={{ fontWeight: "600"}} >
                                                        Change the Status
                                                    </Typography>
                                                </Stack>
                                                : 
                                                <Stack 
                                                    sx={{ width: '100%', height: "100%", justifyContent: "center", alignItems: "center"}}
                                                >
                                                    <Typography color='#757575' textAlign="center" sx={{ fontWeight: "600"}} >
                                                        Change the Status
                                                    </Typography>
                                                </Stack>
                                            )
                                        }
                                    </div>
                                ),
                            },
                        ]}
                    />
                ))}
                <TableRow
                    columns={[
                        { xs: 0.9,  text: "Gross Total"},
                        { xs: 0.9,  text: ""},
                        { xs: 0.9,  text: ""},
                        { xs: 0.7,  text: ""},
                        { xs: 1.9,  text: `${limit2Nbr(total)} €` },
                        { xs: 0.9,  text: ""},
                        { xs: 1.2,  text: ""},
                        { xs: 0.9,  text: ""},
                        { xs: 1.2,  text: ""},
                        { xs: 0.9,  text: ""},
                    ]}
                />
            </Stack>

            <Stack justifyItems="center" alignItems="center" >
                <Pagination
                    page={page}
                    onChange={handleChange}
                    count={pagination?.total_pages}
                    variant="outlined" 
                    shape="rounded" 
                    color="secondary"
                />
            </Stack>

            <Button 
                variant="formrow" 
                style={{ alignSelf:"flex-end"}}
                onClick={() => handlePrint()}
            >
                <Typography variant="pfsboldwhite" style={{textTransform: "capitalize"}}>
                    { loading ? "loading" : text.prstat }
                </Typography>
            </Button>

        </Stack>
    )
}

export default InvoiceAuto