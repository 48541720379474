import React, {useContext, useEffect, useMemo, useState} from "react";
import { Stack, Button, Typography } from "@mui/material";
import useLanguage from "../../hooks/useLanguage";
import Title from "./Title";
import TableRow from "../../tools/TableRow";
import { StatContext } from "../../state/StatContext";
import { formatDate2, limit2Nbr } from "../../../helper/formatDate";
import axiosInstance from "../../../axios";
import { AuthStateContext } from "../../state/AuthStateContext";
import Pusher from "pusher-js";
import { apiURL, CLUSTER, PUSHER_SECRET } from "../../../helper/conf";
import TimeSelect from "../../tools/TimeSelect";

export default ({onDetails}) => {
    const [filter, setFilter] = useState({
        year: "",
        month: "",
        date: ""
    })
    const { text } = useLanguage()
    const [ totalAccount, setTotalAccount ] = useState()
    const [ total, setTotal ] = useState({ wireTransfer: 0, paypal: 0, wise: 0, yoursafe: 0, total: 0 })
    const { authState } = useContext(AuthStateContext)
    const [ accounts, setAccouts ] = useState();
    

    // GET STATISTIQUE ACCOUTANTS
    useEffect(() => {
        const date = formatDate2(filter.date);
        axiosInstance.get(`/statistic/accoutant?year=${filter.year}&month=${filter.month}&date=${date}`, {
            headers: { 
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then((resp) => {
            console.log("data", resp.data.data.accountant)
            setAccouts(resp.data.data.accountant)
        })
        .catch((error) => {
            console.log(error)
        })
    }, [filter])

    //PUSHER STATISTIC ACCOUTANTS
    const [ data, setData ] = useState()
    useEffect(()=>{
        const pusher = new Pusher( PUSHER_SECRET, {
            cluster: CLUSTER,
            channelAuthorization:{
                endpoint: `${apiURL}/pusher/user-auth`,
                transport: 'jsonp'
            }
        })
        const channel = pusher.subscribe("private-statistic2")
        channel.bind(`${authState?.userId}`, (data)=> {
            if(data.name == "accountant"){
                setData(data)
                setTotalAccount(data);
            }
        })
        return()=>{
            channel.unbind()
            pusher.unsubscribe("private-statistic2")
        }
    },[])

    useEffect(()=>{
        if(data){
            setAccouts((prev) => {
                const updatedStat = { ...prev };
                updatedStat[data.language][data.platform][data.field] += data.value;
                return updatedStat;
            });
        }
    }, [data])

    const languages = Object.keys(accounts ?? {});
    useEffect(() => {
        setTotal({ wireTransfer: 0, paypal: 0, wise: 0, yoursafe: 0, total: 0 })
        languages.forEach(l =>{
            const platforms = Object.keys(accounts[l] ?? {})
            platforms.forEach(platform =>{
                setTotal(t => ({...t, wireTransfer: t.wireTransfer + accounts[l][platform].wireTransfer}))
                setTotal(t => ({...t, paypal: t.paypal + accounts[l][platform].paypal}))
                setTotal(t => ({...t, wise: t.wise + accounts[l][platform].wise}))
                setTotal(t => ({...t, yoursafe: t.yoursafe + accounts[l][platform].yoursafe}))
                setTotal(t => ({...t, total: t.total + accounts[l][platform].total}))
            })
        })
    }, [accounts])

    useEffect(() => {
        if(totalAccount) {
            setTotal(t => ({...t, [totalAccount.field]: t[totalAccount.field] + totalAccount.value }))
        }
    }, [totalAccount])

    const StatAccountTab = (language) => {
        const platforms = Object.keys(accounts[language] ?? {});
        return (
            <TableRow columns={[
                { xs: 1.62,
                    customContent: true,
                    content: <Stack>{language.toUpperCase()}</Stack>
                },
                { xs: 1.62,
                    customContent: true,
                    content: <Stack>
                        {platforms.map(platform => (
                            <Typography key={platform}>
                                {platform.charAt(0).toUpperCase() + platform.slice(1)}
                            </Typography>
                        ))}

                    </Stack>
                },
                { xs: 1.62,
                    customContent: true,
                    content: <Stack>
                        {platforms.map(platform => (
                            <Typography  key={platform}>
                                {limit2Nbr(accounts[language][platform]?.wireTransfer)}
                            </Typography>
                        ))}
                    </Stack>
                },
                { xs: 1.62,
                    customContent: true,
                    content: <Stack>
                        {platforms.map(platform => (
                            <Typography key={platform}>
                                {limit2Nbr(accounts[language][platform]?.paypal)}
                            </Typography>
                        ))}
                    </Stack>
                },
                { xs: 1.62,
                    customContent: true,
                    content: <Stack>
                        {platforms.map(platform => (
                            <Typography key={platform}>
                                {limit2Nbr(accounts[language][platform]?.wise)}
                            </Typography>
                        ))}
                    </Stack>
                },
                { xs: 1.62,
                    customContent: true,
                    content: <Stack>
                        {platforms.map(platform => (
                            <Typography key={platform}>
                                {limit2Nbr(accounts[language][platform]?.yoursafe)}
                            </Typography>
                        ))}
                    </Stack>
                },
                { xs: 1.62,
                    customContent: true,
                    content: <Stack>
                        {platforms.map(platform => (
                            <Typography key={platform}>
                                {limit2Nbr(accounts[language][platform]?.total)}
                            </Typography>
                        ))}
                    </Stack>
                },
            ]}/>
        );
    };



    return <Stack padding="2%" spacing={3} height="100%" style={{ overflowY: "scroll" }}>
        <Title>{text.acc}</Title>
        <Stack direction="row" justifyContent="space-between">
            <TimeSelect type='year' onChange={e => {setFilter({ ...filter, year: e.target.value}); console.log(e.target.value)}}/>
            <TimeSelect type='month' onChange={e => setFilter({ ...filter, month: e.target.value})}/>
            <TimeSelect type='date' selected={filter.date} onChange={e => setFilter({ ...filter, date: e})}/>
            <Button
                variant="contained"
                onClick={() => setFilter({
                    year: "",
                    month: "",
                    date: ""
                })}
            >
                {text.Alld}
            </Button>
        </Stack>
        <Stack spacing={1}>
            <TableRow header columns={[
                { xs: 11.85,  text: text.paymnts},
            ]}/>
            <TableRow header columns={[
                { xs: 1.62,  text: text.language},
                { xs: 1.62,  text: text.platform},
                { xs: 1.62,  text: `${text.bnk} €`},
                { xs: 1.62,  text: `${text.ppl} €`},
                { xs: 1.62,  text: `${text.ws} €`},
                { xs: 1.62,  text: `${text.yrsf} €`},
                { xs: 1.62,  text: `${text.total} €`},
            ]}/>
            {languages.map((language) => (
                <Stack key={language}>{StatAccountTab(language)}</Stack>
            ))}
            <TableRow footer columns={[
                { xs: 3.33,  text: `${text.grotot} €`},
                { xs: 1.62,  text: limit2Nbr(total.wireTransfer)},
                { xs: 1.62,  text: limit2Nbr(total.paypal)},
                { xs: 1.62,  text: limit2Nbr(total.wise)},
                { xs: 1.62,  text: limit2Nbr(total.yoursafe)},
                { xs: 1.62,  text: limit2Nbr(total.total)},
            ]}/>
        </Stack>
        
        <Button onClick={onDetails} style={{ backgroundColor: '#feba3b', textTransform: 'capitalize', color: '#000', borderRadius: '15px', border: '2px solid #fff', width: 'fit-content', alignSelf: 'center'}}><Typography style={{fontFamily: 'Chewy Pro'}}>{ text.details}</Typography></Button>
    </Stack>
}

