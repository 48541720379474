import { Stack, Typography, Button, DialogTitle, DialogContent, Box, DialogActions, Pagination, FormControl, InputLabel, Select, MenuItem, Input, TextField } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import useLanguage from "../hooks/useLanguage";
import SearchFilter from "../tools/SearchFilter";
import TableRow from "../tools/TableRow";
import useData from "../hooks/useData";
import axiosInstance from "../../axios";
import { AuthStateContext } from "../state/AuthStateContext";
import CustomAlert from "../Admin/CustomAlert";
import CustomPopup from "../Admin/CustomPopup";
import Pusher from 'pusher-js'
import { CLUSTER, PUSHER_SECRET, apiURL } from "../../helper/conf";
import { filterData } from "../../helper/filter";
import { formatDate } from "../../helper/formatDate";
import SlidingTypography from "../tools/SlidingTypography";
import { format } from "date-fns";
import { GifsContext } from "../state/gifContext";
import Title from "../Admin2/Comps/Title";

const PanicRoom = () => {
    const { globalFilter } = useContext(GifsContext)
    const { authState } = useContext(AuthStateContext)
    const { languagesList } = useData()
    const { text } = useLanguage()

    const [filter, setFilter] = useState(() => ({
        ...globalFilter
    }))

    // GET ALL MESSAGES PANIC ROOM
    const [messages, setMessages] = useState()
    const [pagination, setPagination] = useState()
    const dayRef = useRef(null)
    const [page, setPage] = useState(1);
    const handleChange = (event, value) => {
        setPage(value);
    };
    useEffect(() => {
        axiosInstance.get(`/panicRoom/filter?year=${filter?.year}&month=${filter?.month}&date=${formatDate(filter?.date).split('-')[0]}&startingDate=${formatDate(filter?.startingDate)}&closingDate=${formatDate(filter?.closingDate)}&platforms=${filter?.platforms}&language=${filter?.language}&agents=${filter?.agents}&page=${page}`, {
            headers: {
                'Authorization': `Bearer ${authState.token}`,
            },
        })
            .then((response) => {
                // console.log(response.data.data)
                setMessages(response.data.data)
                setPagination(response.data.pagination)
            })
            .catch((error) => {
                // console.log(error)
            })
    }, [page, filter])

    //PUSHER ADD PANIC MESSAGE
    useEffect(() => {
        const pusher = new Pusher(PUSHER_SECRET, { cluster: CLUSTER, channelAuthorization: { endpoint: `${apiURL}/pusher/user-auth`, transport: 'jsonp' } })
        const channel = pusher.subscribe('private-PanicRoom')
        channel.bind(`add-${authState.userId}`, (data) => {
            // console.log('panic new : ',data)
            setMessages((last) => [data.request, ...last])
        })
        return () => {
            channel.unbind()
            pusher.unsubscribe('private-PanicRoom')
        }
    }, [])

    // DELETE MESSAGES TO PANIC ROOM
    const [loadingDelete, setLoadingDelete] = useState(false)
    const [alerto, setAlerto] = useState(false)
    const [alertContenu, setAlertContenu] = useState("")
    const [popPanic, setPopPanic] = useState(null)
    const [messageToDelete, setMessageToDelete] = useState()
    const handleDelete = () => {
        setLoadingDelete(true)
        axiosInstance.delete(`/panic_room/delete/${messageToDelete.message_id}`, {
            headers: {
                'Authorization': `Bearer ${authState.token}`,
            },
        })
            .then((response) => {
                // console.log(response.data)
                setAlertContenu(response.data.message)
                setLoadingDelete(false)
                setMessages((last) => last.filter((m) => m.message_id !== messageToDelete.message_id))
                setAlerto(true)
                setPopPanic(null)
            })
            .catch((error) => {
                // console.log(error)
                setLoadingDelete(false)
            })
    }
    const suspendMsg = (index) => {
        setMessageToDelete(index)
        setPopPanic(2)
    }
    const [type, setType] = useState({ tp0: 0, tp1: 0 })
    const [suspendChk, setSuspendChk] = useState(1)
    const tabTypeSuspend = [0, 1]
    const dateF = (date) => format(new Date(date), 'dd/MM/yyyy hh:mm:ss')

    const suspendConv = async () => {
        const elemnt = messages[messageToDelete]
        const convId = elemnt.conversation_id
        await axiosInstance.post(`/Suspend/${convId}`, { type1: type.tp0, type2: type.tp1, days: suspendChk, messageId: elemnt.message_id }, { headers: { 'Authorization': `Bearer ${authState.token}` } }).then(rep => {
            if(type.tp1)
            setMessages(p => p.map(k => k.conversation_id === convId ? { ...k, status: {type0:type.tp0,type1:type.tp1,date:dateF(rep.data)} } : k))
            else setMessages(p => p.map(k => k.user_profil_id === elemnt.user_profil_id ? { ...k, status: {type0:type.tp0,type1:type.tp1,date:dateF(rep.data)} } : k))
            setPopPanic(null)
        }).catch(e => {})
    }
    return (
        <Stack padding="2% 0%" spacing={3} height="100%" style={{ overflowY: "scroll" }}>
            <CustomAlert
                open={alerto}
                autoHideDuration={1500}
                onClose={() => setAlerto(false)}
                severity="success"
                message={alertContenu}
            />
            <CustomPopup open={popPanic ? true : false} onClose={() => setPopPanic(null)}>
                {popPanic && popPanic === 1 ?
                    <Stack>
                        <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
                            <p style={{ fontWeight: "800", fontSize: "22px" }}>
                                {text.deltitre1} <br /> {text.panicmsg}
                            </p>
                        </DialogTitle>
                        <DialogContent>
                            <Box>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", mr: "6px" }}>
                                        {text.delauteur}:
                                    </Stack>
                                    <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", color: "#757575" }}>
                                        {messageToDelete?.sender_profil.Name}
                                    </Stack>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", mr: "6px" }}>
                                        {text.mess} :
                                    </Stack>
                                    <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", color: "#757575" }}>
                                        {messageToDelete?.content}
                                    </Stack>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", mr: "6px" }}>
                                        {text.raison} :
                                    </Stack>
                                    <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", color: "#757575" }}>
                                        {messageToDelete?.reason}
                                    </Stack>
                                </div>
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant='contained'
                                sx={{
                                    fontWeight: "700",
                                    bgcolor: "error.main",
                                    color: "white",
                                    "&:hover": {
                                        bgcolor: "error.dark",
                                    }
                                }}
                                onClick={() => setPopPanic(null)}
                            >
                                {text.delrefuse}
                            </Button>
                            <Button
                                variant='contained'
                                sx={{
                                    fontWeight: "700",
                                    bgcolor: "success.main",
                                    color: "white",
                                    "&:hover": {
                                        bgcolor: "success.dark",
                                    }
                                }}
                                onClick={() => handleDelete()}
                            >
                                {loadingDelete ? text.delload : text.delaccept}
                            </Button>
                        </DialogActions>
                    </Stack>
                    : <Stack>
                        <DialogContent style={{ fontSize: "22px" }}>
                            <Stack style={{ fontWeight: "800", placeItems: 'center', gap: 8 }}>
                                <Stack style={{ flexDirection: 'row', gap: 8, placeItems: 'center' }}>
                                    <FormControl>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={type.tp0}
                                            onChange={e => setType({ ...type, tp0: e.target.value })}
                                            style={{ height: '2.5em', fontWeight: '800', textAlign: 'center', minWidth: '9em' }}
                                        >
                                            {tabTypeSuspend.map((p, i) => <MenuItem key={i} value={p} >{p ? text.suspendre : text.bannir}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                    <FormControl>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={type.tp1}
                                            onChange={e => setType({ ...type, tp1: e.target.value })}
                                            style={{ height: '2.5em', fontWeight: '800', textAlign: 'center', minWidth: '10em' }}
                                        >
                                            {tabTypeSuspend.map((p, i) => <MenuItem key={i} value={p} >{p ? text.conversation : text.user}</MenuItem>)}
                                        </Select>
                                    </FormControl>:
                                </Stack>

                                {popPanic === 2 && <Stack style={{}}>
                                    {type.tp1 ? <Stack style={{ flexDirection: 'row', placeItems: 'center', gap: 10 }}>
                                        <Box>{messages[messageToDelete].sender_profil.Name}</Box> {'-->'}
                                        <Box>{messages[messageToDelete].receiver_profil.Name}</Box>
                                    </Stack> :
                                        <Box>{messages[messageToDelete].sender_profil.Name}</Box>}
                                </Stack>
                                }
                                <Stack style={{ opacity: type.tp0 ? 1 : .3, pointerEvents: type.tp0 ? '' : 'none', flexDirection: 'row', placeItems: 'center', gap: 8 }}>
                                    <FormControl disabled={!type.tp0}>
                                        {/* <input placeholder="1" style={{textAlign:'right'}}></input> */}
                                        <Input placeholder='1' defaultValue={suspendChk} onChange={e => setSuspendChk(e.target.value)} style={{width:'2em',textAlign:'center'}}></Input>
                                    </FormControl>
                                    <Typography style={{ color: type.tp0 ? '#000' : '#adadad', width: '4em', fontWeight: 800 }}>{suspendChk > 1 ? text.jours : text.jour}</Typography>
                                </Stack>

                            </Stack>
                        </DialogContent>
                        {popPanic && popPanic === 1 && <DialogContent style={{ padding: '10px' }}>
                        </DialogContent>}
                        <DialogActions style={{ justifyContent: popPanic && popPanic === 1 ? '' : 'space-around' }}>
                            <Button variant='contained' sx={{ fontWeight: "700", bgcolor: "error.main", color: "white", "&:hover": { bgcolor: "error.dark" } }} onClick={() => setPopPanic(null)}>{text.annuler}</Button>
                            <Button variant='contained' sx={{ fontWeight: "700", bgcolor: "success.main", color: "white", "&:hover": { bgcolor: "success.dark" } }} onClick={() => suspendConv()}>{text.valider}</Button>
                        </DialogActions>
                    </Stack>
                }
            </CustomPopup>
            {/* <Stack style={{ display: 'flex', alignItems: 'center', gap: '15px', justifyContent: 'center', flexDirection: 'row' }}>
                <Typography variant="h1" alignSelf='center' style={{ fontFamily: 'Fascinate Regular', fontWeight: 'bold', color: '#7ed957', WebkitTextStroke: '2px #cbf0bc' }}
                >{text.panicroom}</Typography>
                <img className="card-img-resize-2" src={"/images/supervisor/3pan.png"} alt="" height='50px' width='50px' />
            </Stack> */}
            <Title>{text.panicroom}</Title>

            <Stack padding='0 10px' spacing={2}>
                <SearchFilter
                    selectedDate={filter.date}
                    onDateChange={{
                        year: y => setFilter({ ...filter, year: y }),
                        month: m => setFilter({ ...filter, month: m }),
                        date: d => setFilter({ ...filter, date: d }),
                    }}
                    items={[
                        { label: text.startingdate, comp: 'DatePicker', selected: filter.startingDate, onChange: d => setFilter({ ...filter, startingDate: d }) },
                        { label: text.closingdate, comp: 'DatePicker', selected: filter.closingDate, onChange: d => setFilter({ ...filter, closingDate: d }) },
                        { label: text.agents, comp: 'TextInput', onChange: e => setFilter({ ...filter, agents: e.target.value }) },
                        { label: text.platform, comp: 'TextInput', onChange: e => setFilter({ ...filter, platforms: e.target.value }) },
                        { label: text.language1, comp: 'Select', choices: languagesList, onChange: e => setFilter({ ...filter, language: e.target.value }) },
                    ]}
                />
                <Stack spacing={'10px'}>
                    <TableRow header columns={[
                        { xs: 1.1, text: text.date },
                        { xs: 1.1, text: text.usrprfl },
                        { xs: 1.1, text: text.receiver },
                        { xs: 1.1, text: text.mess },
                        { xs: .8, text: text.idmess },
                        { xs: .8, text: text.idconv },
                        { xs: 1.1, text: text.platform },
                        { xs: .5, text: text.language },
                        { xs: 1.1, text: text.raison },
                        { xs: 1.1, text: text.etat },
                        { xs: 1.1, text: text.actions },
                    ]} />
                    {
                        messages?.map((message, i) =>
                            <TableRow key={i} columns={[
                                { xs: 1.1, text: message.created_at },
                                { xs: 1.1, text: message.sender_profil.Name },
                                { xs: 1.1, text: message.receiver_profil.Name },
                                { xs: 1.1, text: message.content },
                                { xs: .8, text: message.message_id },
                                { xs: .8, text: message.conversation_id },
                                { xs: 1.1, text: message.platform.name },
                                { xs: .5, text: message.language },
                                { xs: 1.1, text: message.reason },
                                {
                                    xs: 1.1, customContent: true,
                                    content: <Stack style={{ fontFamily: 'Delm', width: '100%', height: '100%', placeItems: 'center', justifyContent: 'center' }}>
                                        {message.status ?
                                            message.status.type0 ?
                                                <Stack style={{ width: '100%', height: '100%' }}>
                                                    <Stack style={{flex:1,placeItems:'center',justifyContent:'center',fontFamily:'Delm', backgroundColor: '#d5d5' }}>
                                                        <Box>{message.status.type1 ? 'Conversation':'Profil'}</Box>
                                                        <Box>{text.suspendu}{`${message.status.type1 ? 'e':''}`}</Box>
                                                    </Stack>
                                                    <Stack style={{flex:1,width:'100%',placeItems:'center'}}>
                                                        <SlidingTypography delm fast>{message.status.date}</SlidingTypography>
                                                    </Stack>
                                                    
                                                </Stack>
                                                :
                                                <Stack style={{color:'#fff', width: '100%', height: '100%', backgroundColor: '#fb9c9c' }}>
                                                    <Stack style={{flex:1,placeItems:'center',justifyContent:'center',fontFamily:'Delm' }}>
                                                        <Box>{message.status.type1 ? text.conversation:text.user}</Box>
                                                        <Box>{text.expulser}</Box>
                                                    </Stack>
                                                    <Stack style={{flex:1,width:'100%',placeItems:'center'}}>
                                                        <SlidingTypography delm fast>{message.status.date}</SlidingTypography>
                                                    </Stack>
                                                </Stack>
                                            :
                                            <p style={{ color: 'deepskyblue' }}>OK</p>}
                                    </Stack>
                                },
                                {
                                    xs: 1.1,
                                    customContent: true,
                                    content: <Stack style={{ width: '100%' }}>
                                        <Button style={{ width: '100%', height: '100%', textTransform: 'capitalize', color: '#000' }}>
                                            <Typography style={{ fontFamily: 'Delm' }}>
                                                {text.edit}
                                            </Typography>
                                        </Button>
                                        <Button style={{ width: '100%', height: '100%', textTransform: 'capitalize', color: '#000' }} onClick={() => { setMessageToDelete(message); setPopPanic(1) }}>
                                            <Typography style={{ fontFamily: 'Delm' }}>
                                                {text.dlt}
                                            </Typography>
                                        </Button>
                                        <Button style={{ width: '100%', height: '100%', textTransform: 'capitalize', color: '#000' }} onClick={() => suspendMsg(i)}>
                                            <Typography style={{ fontFamily: 'Delm' }}>
                                                {text.suspend}
                                            </Typography>
                                        </Button>
                                    </Stack>
                                },

                            ]} number={message} stat={3} ind={i > -1 ? true:false} />
                        )
                    }
                </Stack>
                <Stack justifyItems="center" alignItems="center" >
                    <Pagination
                        page={page}
                        onChange={handleChange}
                        count={pagination?.total_pages}
                        variant="outlined"
                        shape="rounded"
                        color="secondary"
                    />
                </Stack>
            </Stack>
        </Stack>
    )
}

export default PanicRoom
