import React, {useState, useEffect, useContext, useMemo} from "react";
import { Stack, Typography, Button, Box, TextField } from "@mui/material";
import useLanguage from "../hooks/useLanguage";
import SearchFilter from "../tools/SearchFilter";
import useData from "../hooks/useData";
import TableRow from "../tools/TableRow";
import useList from "../hooks/useList";
import { GifsContext } from "../state/gifContext";
import { AuthStateContext } from "../state/AuthStateContext";
import axiosInstance from "../../axios";
import CustomAlert from "../Admin/CustomAlert";
import { baseURL } from "../../helper/conf";
import { limit2Nbr } from "../../helper/formatDate";
import { cData } from "../tools/Snackbars";

const RateAndBonus = () => {

    const { globalFilter } = useContext(GifsContext)
    const [filter, setFilter] = useState(() => ({
        ...globalFilter,
    }))


    const { text } = useLanguage()
    const { authState } = useContext(AuthStateContext)
    const {languagesList, languagesgifList, getV } = useData()

    
    // LIST AGENT RATE STATISTIQUE
    const [ rates, setRates ] = useState([])
    const [ period, setPeriod ] = useState()
    useEffect(()=>{
        const formattedMonth = String(filter?.month).padStart(2, '0');
        axiosInstance.get(`/rate?year=${filter?.year}&month=${formattedMonth}&platform=${filter?.platforms}&language=${filter?.language}`, {
            headers: {
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then((response) => {
            setRates(response.data.data)
            setPeriod(response.data.period)
        })
        .catch((error) => {
            // console.log(error)
        })
    },[filter])


    // UPDATE RATE
    const [ data, setData ] = useState()
    const [ alert, setAlert ] = useState()
    const [ alertContenu, setAlertContenu ] = useState()
    const [ newValue, setNewValue ] = useState("")

    const handleDoubleClick = (data1, data2, type) => {
        const now = new Date();
        const nowPeriod = now.toLocaleDateString('en-CA', {
            year: 'numeric',
            month: '2-digit',
        });
        if(nowPeriod == period){
            setData({
                'platform' : data1?.platform,
                'language' : data2?.language,
                'type' : type,
            })
        }
    };

    const handleBlur = () => {
        setData();
        setNewValue()
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            axiosInstance.put("/rate", {
                "platform": data.platform,
                "language": data.language, 
                "type": data.type,
                "value": newValue,
            },{
                headers: {
                    'Authorization': `Bearer ${authState.token}`
                }
            })
            .then((response)=>{
                setRates((prev) =>
                    prev.map((d) => ({
                        ...d,
                        data: d.data.map((i) =>
                            i.id === response.data.data.id ? response.data.data : i
                        ),
                    }))
                );
                setAlertContenu(text.updtdsucc)
                setData();
                setAlert(true)
                setNewValue();
            })
            .catch((error)=>{
                // console.log(error)
            })
        }
    };


    // GET PRINT PDF RATE AND BONUS
    const [ loading, setLoading ] = useState(false)
    const handlePrint = (isExcel) => {
        const isPrint = true
        setLoading(true)
        axiosInstance.get(`/rate?isExcel=${isExcel}&isPrint=${isPrint}&year=${filter?.year}&month=${filter?.month}&platform=${filter?.platforms}&language=${filter?.language}&title=${cData(getV(4))}`, {
            headers: {
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then((response) => {
            // console.log(response.data.pdfName)
            const pdfName = response.data.pdfName;
            const type = response.data.type;
            setLoading(false)

            // Ouvrir le PDF dans un nouvel onglet
            const downloadUrl = `${baseURL}/${type}/${pdfName}`;
            window.open(downloadUrl, '_blank');

        })
        .catch((error) => {
            // console.log(error)
            setLoading(false)
        })
    }

    const handleSave = () => {
        setLoading(true)

        setTimeout(() => {
            setAlertContenu(text.updtdsucc);
            setAlert(true);
            setLoading(false);
        }, 2000);
    }

    
    return (
        <Stack spacing={2} padding="20px 0">
            <CustomAlert
                open={alert}
                autoHideDuration={1500}
                onClose={() => setAlert(false)}
                severity="success"
                message={alertContenu}
            />
            <SearchFilter payment
                onDateChange={{
                    year: y => setFilter({...filter, year: y}),
                    month: m => setFilter({...filter, month: m}),
                }}
                items= {[
                    { label: text.platform , comp: 'TextInput', onChange: e => setFilter({...filter, platforms: e.target.value}) },                    
                    { label: text.language, comp: 'Select', choices: languagesList, onChange: e => setFilter({ ...filter, language: e.target.value }) },
                ]}
            />
            <Button 
                variant="formrow" 
                style={{alignSelf:"flex-end"}}
                onClick={() => handlePrint()}
            >
                <Box className="wrap-content" gap='20px' justifyContent='space-between'>
                    <Typography variant="pfsboldwhite" style={{textTransform: "capitalize"}}>
                        {loading ? "Loading.." : text.prstat}
                    </Typography>
                    <img src="/images/admin/imprimante.png" alt="" height='30px' width='30px'/>
                </Box>
            </Button>
            <Button 
                variant="formrow" 
                style={{alignSelf:"flex-end"}}
                onClick={() => handlePrint(true)}
            >
                <Box className="wrap-content" gap='20px' justifyContent='space-between'>
                    <Typography variant="pfsboldwhite" style={{textTransform: "capitalize"}}>{text.expxls}</Typography>
                    <img src="/images/admin/xls.png" alt="" height='30px' width='50px'/>
                </Box>
            </Button>
            <Stack spacing={'10px'}>
                <TableRow header columns={[
                    { xs: 1.6,  text: text.language},
                    { xs: 1.6,  text: text.platform},
                    { xs: 1.6,  text: text.messrt},
                    { xs: 1.6,  text: text.pkmessrt},
                    { xs: 1.6,  text: text.tsrmessrt},
                    { xs: 1.6,  text: text.stpmessrt},
                    { xs: 1.6,  text: text.bnsrt},
                ]}/>
                {
                    rates?.map((d, i) => 
                        <TableRow key={d.id} columns={[
                            { xs: 1.6,  text: d.language},
                            { xs: 1.6,
                                customContent: true,
                                content: <Stack>
                                    { d.data?.map((dd, i) => 
                                        <Typography key={i}>
                                            {dd.platform}
                                        </Typography>
                                    )}
                                </Stack>
                            },
                            { xs: 1.6,
                                customContent: true,
                                content: <Stack >
                                    { d.data?.map((dd, i) => 
                                        <Stack key={i} onDoubleClick={() => handleDoubleClick(dd, d, 'message_rate')}>
                                            { data?.platform == dd.platform && data?.language == d.language && data?.type == 'message_rate' ? (
                                                <TextField
                                                    value={newValue}
                                                    onChange={(e)=>setNewValue(e.target.value)}
                                                    onBlur={handleBlur}
                                                    onKeyPress={handleKeyPress}
                                                    autoFocus
                                                />
                                            ) : (
                                                <Stack sx={{ justifyItems: 'center', alignItems: 'center'}}>
                                                    <Typography sx={{ cursor: 'pointer'}}>
                                                        {limit2Nbr(dd.message_rate)} €
                                                    </Typography>
                                                </Stack>
                                            )}
                                        </Stack>
                                    )}
                                </Stack>
                            },
                            { xs: 1.6,
                                customContent: true,
                                content: <Stack>
                                    { d.data?.map((dd, i) => 
                                        <Stack key={i} onDoubleClick={() => handleDoubleClick(dd, d, 'poke_message_rate')}>
                                            { data?.platform == dd.platform && data?.language == d.language && data?.type == 'poke_message_rate' ? (
                                                <TextField
                                                    value={newValue}
                                                    onChange={(e)=>setNewValue(e.target.value)}
                                                    onBlur={handleBlur}
                                                    onKeyPress={handleKeyPress}
                                                    autoFocus
                                                />
                                            ) : (
                                                <Stack sx={{ justifyItems: 'center', alignItems: 'center'}}>
                                                    <Typography sx={{ cursor: 'pointer'}}>
                                                        {limit2Nbr(dd.poke_message_rate)} €
                                                    </Typography>
                                                </Stack>
                                            )}
                                        </Stack>
                                    )}
                                </Stack>
                            },
                            { xs: 1.6,
                                customContent: true,
                                content: <Stack>
                                    { d.data?.map((dd, i) => 
                                        <Stack key={i} onDoubleClick={() => handleDoubleClick(dd, d, 'teaser_message_rate')}>
                                            { data?.platform == dd.platform && data?.language == d.language && data?.type == 'teaser_message_rate' ? (
                                                <TextField
                                                    value={newValue}
                                                    onChange={(e)=>setNewValue(e.target.value)}
                                                    onBlur={handleBlur}
                                                    onKeyPress={handleKeyPress}
                                                    autoFocus
                                                />
                                            ) : (
                                                <Stack sx={{ justifyItems: 'center', alignItems: 'center'}}>
                                                    <Typography sx={{ cursor: 'pointer'}}>
                                                        {limit2Nbr(dd.teaser_message_rate)} €
                                                    </Typography>
                                                </Stack>
                                            )}
                                        </Stack>
                                    )}
                                </Stack>
                            },
                            { xs: 1.6,
                                customContent: true,
                                content: <Stack>
                                    { d.data?.map((dd, i) => 
                                        <Stack key={i} onDoubleClick={() => handleDoubleClick(dd, d, 'stop_message_rate')}>
                                            { data?.platform == dd.platform && data?.language == d.language && data?.type == 'stop_message_rate' ? (
                                                <TextField
                                                    value={newValue}
                                                    onChange={(e)=>setNewValue(e.target.value)}
                                                    onBlur={handleBlur}
                                                    onKeyPress={handleKeyPress}
                                                    autoFocus
                                                />
                                            ) : (
                                                <Stack sx={{ justifyItems: 'center', alignItems: 'center'}}>
                                                    <Typography sx={{ cursor: 'pointer'}}>
                                                        {limit2Nbr(dd.stop_message_rate)} €
                                                    </Typography>
                                                </Stack>
                                            )}
                                        </Stack>
                                    )}
                                </Stack>
                            },
                            { xs: 1.6,
                                customContent: true,
                                content: <Stack>
                                    { d.data?.map((dd, i) => 
                                        <Stack key={i} onDoubleClick={() => handleDoubleClick(dd, d, 'bonus_rate')}>
                                            { data?.platform == dd.platform && data?.language == d.language && data?.type == 'bonus_rate' ? (
                                                <TextField
                                                    value={newValue}
                                                    onChange={(e)=>setNewValue(e.target.value)}
                                                    onBlur={handleBlur}
                                                    onKeyPress={handleKeyPress}
                                                    autoFocus
                                                />
                                            ) : (
                                                <Stack sx={{ justifyItems: 'center', alignItems: 'center'}}>
                                                    <Typography sx={{ cursor: 'pointer'}}>
                                                        {limit2Nbr(dd.bonus_rate)} €
                                                    </Typography>
                                                </Stack>
                                            )}
                                        </Stack>
                                    )}
                                </Stack>
                            },
                        ]}/>
                    )
                }

                <Button 
                    variant="login" style={{alignSelf:"center"}}
                    onClick={() => handleSave()}
                >
                    <Box className="wrap-content" gap='20px' justifyContent='space-between'>
                        <Typography variant="pfsboldwhite" style={{textTransform:"uppercase"}}>
                            {loading ? "Loading.." : "Save"}
                        </Typography>
                        <img src="/images/admin/save.png" alt="" height='30px' width='30px'/>
                    </Box>
                </Button>
                
            </Stack>

        </Stack>
    )
} 

export default RateAndBonus;