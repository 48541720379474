import { Box, Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import axiosInstance from '../../axios'
import { AuthStateContext } from '../state/AuthStateContext'
import useLanguage from '../hooks/useLanguage'

const PopupDelete = ({handleCl, setOpenAlert, deleteData, photoList }) => {
    const { authState } = useContext(AuthStateContext)
    const [ loading, setLoading ] = useState(false)
    const { text } = useLanguage()

    const handelDelete = () => {
        setLoading(true)
        axiosInstance.delete(`/photoRequest/delete/${deleteData.request_id}`, {
            headers: { 
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then((response) => {
            // console.log(response.data.message)
            photoList()
            handleCl()
            setOpenAlert(true)
            setLoading(false)
        })
        .catch((error) => {
            // console.log(error)
        })
    }

    return (
        <div style={{ padding: "18px 30px"}}>
            <DialogTitle id="alert-dialog-title" sx={{textAlign: "center"}}>
                <p style={{ fontWeight: "800", fontSize: "22px"}}>
                    {text.dltdmd}
                </p>
            </DialogTitle>
            <DialogContent>
                <Box>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", mr: "6px" }}>
                            {text.delauteur}:
                        </Stack>
                        <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", color: "#757575" }}>
                            {deleteData.profil_username}
                        </Stack>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", mr: "6px" }}>
                            {text.deldesc}:
                        </Stack>
                        <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", color: "#757575" }}>
                            {deleteData.image_type}
                        </Stack>
                    </div>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button 
                    variant='contained' 
                    sx={{ 
                        fontWeight: "700", 
                        bgcolor: "error.main",
                        color: "white",
                        "&:hover": {
                            bgcolor: "error.dark",
                        } 
                    }} 
                    onClick={() => handleCl()}
                >
                    {text.delrefuse}
                </Button>
                <Button 
                    variant='contained' 
                    sx={{ 
                        fontWeight: "700", 
                        bgcolor: "success.main", 
                        color: "white",
                        "&:hover": {
                            bgcolor: "success.dark",
                        } 
                    }} 
                    onClick={handelDelete}
                >
                    {loading ? text.phload : text.delaccept}
                </Button>
            </DialogActions>
        </div>
    )
}

export default PopupDelete