import React from "react";
import { Grid, Typography, Stack, Select, MenuItem, Checkbox, Chip, Box } from "@mui/material";
import Input from './input'
import { Controller } from "react-hook-form";
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import useLanguage from "../hooks/useLanguage";
import FileInput from "./FileInput";

const FormRow = ({label, control,disabled = false, value="",fontSize='', multiline=false, name, error,dateFormat='dd-MM-yyyy',defaultValue='', comp='TextInput',maxLength='', minDate=null,maxDate = null, options=[{}] ,name2, setValue = (a, b)=>{}, onExportFile=() => {},bgCol='',txtCol='', inpCol='#f993', variant="formrow",opt=[],setOpt, className=false, topEnd=false}) =>{
    const {text} = useLanguage()
    const getInputComponent = () =>{
        switch (comp){
            case 'readOnly': return <Stack style={{justifyContent:'center',height: '100%', width: '100%', backgroundColor: inpCol, borderRadius: '3px',  border: '1px solid #cabbbb'}}>
                <Typography style={{paddingLeft:'10px'}}>{value}</Typography>
            </Stack>
            case 'Select': return <Controller
                name={name}
                control={control}
                rules={{ required: text.required }}
                defaultValue={options.length ? options[0].value : ''}
                render={({ field }) => (
                    <Select
                        {...field}
                        style={{ width: '100%', height: '100%', backgroundColor: inpCol }}
                        onChange={(e) => field.onChange(e.target.value)}
                    >
                        {options?.map((opt, index) => (
                            <MenuItem key={index} value={opt.value}>
                                {opt.innerHTML}
                            </MenuItem>
                        ))}
                    </Select>
                )}
            />
            case 'Select1': return <Controller
                name={name}
                disabled={disabled}
                control={control}
                rules={{ required: text.required }}
                defaultValue={options.length ? options[0].value : ''}
                render={({ field }) => (
                    <Select
                        {...field}
                        style={{ width: '100%', height: '100%', backgroundColor: inpCol }}
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={opt}
                        onChange={(e) => setOpt(e.target.value)}
                        renderValue={(selected) => ( ((options.length > 5 && options.length === selected.length) || (options.length < 6 && selected.length > options.length-2)) ? <Typography>All</Typography> :
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: .4 }}>
                                {selected.map((value, i) => i < 3 ? <Chip key={i} sx={{ fontSize:'1rem',fontWeight:600 }} label={options[value]?.innerHTML}></Chip> : i === 3 && <Chip style={{fontWeight:'bold'}} key={i} label={'...'}></Chip>)}
                            </Box>
                        )}>
                        {options.map((value, i) => (
                            <MenuItem key={i} value={i} style={{ height: '2em' }}>
                                <Checkbox checked={opt.indexOf(i) > -1}/>
                                <Typography style={{ fontFamily: 'Delm', fontSize: '1.5rem' }}>{value?.innerHTML}</Typography>
                            </MenuItem>
                        ))}
                    </Select>
                )}
            />
            case 'DatePicker': return <Stack style={{height: "100%"}}>
                <Controller 
                    name={name} 
                    control={control}
                    defaultValue={defaultValue}
                    rules={{required: text.required}}
                    render={({ field }) => (
                        <DatePicker disabled={disabled} dateFormat={dateFormat} showYearDropdown 
                        popperPlacement={topEnd ? "top-end":''}
                            scrollableYearDropdown yearDropdownItemNumber={100} minDate={minDate} maxDate={maxDate}
                            className={ className? "datepicker datepicker-5" : "datepicker datepicker-4"} selected={field.value} onChange={field.onChange}/> 
                    )}
                />
                {error && <Typography variant="error" textAlign='left'>{text.required}</Typography>}
            </Stack>
            case 'Image': return <FileInput name={name} control={control} error={error}
                accept="image/*" required visiblePartVariant="TotalInput"/>
            case 'Gif': return <FileInput name={name} control={control} error={error}
                accept="image/gif" required visiblePartVariant="TotalInput" onExportFile={onExportFile}/>
            case 'FileName': return <FileInput variant={variant} name={name} control={control} error={error}
                required={false} visiblePartVariant="FileName" name2={name2} onExportFile={onExportFile}/>
            case 'Password': return <Input variant={variant} type="password" control={control} name={name} error={error}/>
            default: return <Input variant={variant} multiline={multiline} defaultValue={defaultValue} maxLength={maxLength} control={control} name={name} error={error}/>
        }
    }
    const input = getInputComponent()
    return(
        <Stack>
            <Grid container gap='15px'>
                <Grid item xs={3.5} variant="primarybg" style={{backgroundColor:'', minHeight: '50px' /*border: '1px solid #937d7d'*/}}>
                    <Typography variant="h4" style={{height: '100%', fontWeight: "bold", /*fontFamily: 'Chewy Pro,*/
                    display: 'flex', alignItems: 'center',color:txtCol, backgroundColor:bgCol, justifyContent: 'center',fontSize:fontSize}}>
                        {label}
                    </Typography>
                </Grid>
                <Grid item xs={7}>{ input }</Grid>
            </Grid>
        </Stack>
    )
}

export default FormRow