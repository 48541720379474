import React, {useContext, useEffect, useState} from "react";
import { Stack, Typography, Button} from "@mui/material";
import useLanguage from "../hooks/useLanguage";
import FormRow from "../tools/FormRow";
import {useForm} from 'react-hook-form';
import axiosInstance from "../../axios";
import countriesList from "../../helper/countries";
import useData from "../hooks/useData";
import { AuthStateContext } from "../state/AuthStateContext";
import CustomAlert from "../Admin/CustomAlert";
import useList from "../hooks/useList";

const AddQualityController = ({setQualityControllerList}) => {
    const { authState } = useContext(AuthStateContext)
    const { text } = useLanguage()
    const [error,setError] = useState('')
    const [ppError, setPPError] = useState(false)
    const { languagesList, trafficManagement, languagesgifList, roleList, paymentList  } = useData()
    const [pp, setPP] = useState(false)
    const { control, handleSubmit, reset, watch, formState: {errors} } = useForm()
    const [ alertContenu, setAlertContenu] = useState("")
    const [ alerto, setAlerto ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const selectedPayment = watch('paymentName')

    const newQc = ({role,mail,password,login,confirmpassword,name, fullName, fullAddress, country,languageName, paymentName, bankname, iban, bic, paymail}) => {
        if(error) setError('')
        setPPError(!pp)
        if(password === confirmpassword) {
            setLoading(true)
            axiosInstance.post('/register', {
                mail,
                password,
                login,
                role,
                name,
                country,
                languageName, 
                paymentName, 
                bankname, 
                iban, 
                bic, 
                paymail,
                fullName,
                fullAddress,
            }, {
                headers: {
                    'Authorization': `Bearer ${authState.token}`,
                },
            })
            .then((response) => {
                setQualityControllerList((prev) => ({
                    ...prev,
                    data: [ ...prev.data, response.data.userData]
                }))
                setAlertContenu(text.scc)
                setAlerto(true)
                reset()
                setLoading(false)
            })
            .catch((error) => {
                // console.log(error)
            })
        }
    }
    return (
        <Stack spacing={2} padding="20px 0">
            <CustomAlert
                open={alerto}
                autoHideDuration={1500}
                onClose={() => setAlerto(false)}
                severity="success"
                message={alertContenu}
            />
            <Typography alignSelf="center" sx={{ color: "red"}}>{error}</Typography>
            <FormRow label={text.role} comp="Select" control={control} options={roleList} name="role" error={errors.role} />
            <FormRow label={text.lgn} control={control} name="login" error={errors.login} />
            <FormRow label={text.mail} control={control} name="mail" error={errors.mail} />
            <FormRow label={text.agname} control={control} name="name" error={errors.name} />
            <FormRow label="Full Name" control={control} name="fullName" error={errors.name} />
            <FormRow label="Full Address" control={control} name="fullAddress" error={errors.name} />
            <FormRow label={text.pays} comp="Select" options={countriesList} control={control} name="country" error={errors.country} />
            <FormRow label={text.language} comp="Select" options={languagesgifList} control={control} name="languageName" error={errors.languageName} />
            <FormRow label= {text.paymeth} comp="Select" options={paymentList} control={control} name="paymentName" error={errors.paymentName} />
            {
                (selectedPayment==paymentList[0].value) ?
                    <>
                        <FormRow label= {text.bankn} control={control} name="bankname" error={errors.bankname} />
                        <FormRow label= {text.iban} control={control} name="iban" error={errors.iban} />
                        <FormRow label= {text.bic} control={control} name="bic" error={errors.bic} />
                    </>
                :<FormRow label= {text.paymail} control={control} name="paymail" error={errors.paymail} />
            }
            <FormRow label={text.password} comp="Password" control={control} name="password" error={errors.password} />
            <FormRow label={text.confirmpass} comp="Password" control={control} name="confirmpassword" error={errors.confirmpassword} />
            <Stack style={{width: '85%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
                <Button onClick={handleSubmit(newQc)} variant="formrow">
                    <Typography>
                        {loading ? text.phload : text.crtqc}
                    </Typography>
                </Button>
            </Stack>
        </Stack>
    )
} 

export default AddQualityController;