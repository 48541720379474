import { AuthStateContext } from "../state/AuthStateContext"
import { MessageContext } from "../state/MessageContext"
import axiosInstance from "../../axios"
import { useContext } from "react"
import { GifsContext } from "../state/gifContext"
import incoming from "../../assets/audio/incoming.mp3"

const useMessage = () => {
    const { setMessages,setScrollTs, messageState,setOnHoldCounts,onHoldCount,setOpen } = useContext(MessageContext)
    const {setGifs} = useContext(GifsContext)
    const {authState} = useContext(AuthStateContext)

    const getData = async() => {
        await new Promise(res => setTimeout(res,1000))
        try{
            const {data:data,status} = await axiosInstance.get('/message_on_hold', {headers: { 'Authorization': `Bearer ${authState.token}` }})
            if(status === 200){
                // console.log('wait : ',data)
                const audio = new Audio(incoming)
                audio.play().catch(error => {
                    console.error('Playback failed');
                })
                if(data.wait_conversation !== onHoldCount){
                    // console.log('on hold : ',onHoldCount)
                    setOnHoldCounts({type:true,data:data.wait_conversation})
                }
                return setMessages(data)
            }
        }catch(e){ 
            // console.log(e)
        }
    }
    
    const sendMessage = async(etat,message,phot=null,gif=null) => {
        setMessages(null)
        setScrollTs(null)
        let photo = []
        let gifs = []
        if(etat){
            setOpen(1)
        }
        const type_mess = ( (messageState.type_mess && messageState.type_mess.length)) ? messageState.type_mess : null
        if(phot) phot.forEach(p => photo.push(p.id))
        if(gif) gif.forEach(p => gifs.push(p.id))
        
        // console.log(type_mess, onHoldCount, JSON.stringify(messageState.wait_id), messageState.convId, etat, message)
        axiosInstance.post('/send_message_fp_to_vp', { 
            type_mess: type_mess,
            nbrWait: onHoldCount,
            wait_id: JSON.stringify(messageState.wait_id),
            convId: messageState.convId,
            status: etat,
            message_content: message,
            photo_id: photo.length ? photo : null,
            gif_id: gifs.length ? gifs : null
        }, {
            headers: { 'Authorization': `Bearer ${authState.token}` }
        })
        .then((response) => {
            if(response.data.type){
                const Data = response.data.data
                // console.log(Data)
                if(Data.wait_conversation !== onHoldCount)
                    setOnHoldCounts({type:true,data:Data.wait_conversation})
                new  Promise(res => setTimeout(res,500))
                setMessages(Data)
            }else {
                getData()
            }

            // send to the real plateform
            const data = {
                action: 'sendTm',
                message: response.data.dataSite.message,
                s_id: response.data.dataSite.s_id,
                r_id: response.data.dataSite.r_id,
                mobile: 0,
                gifsName: response.data.dataSite.FileUrl.gifs,
                photoName: response.data.dataSite.FileUrl.images,
            };
            fetch(`${response.data.dataSite.link}`, {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded",
                },
                body: new URLSearchParams(data).toString()
            })
            .then(response => response.text())
            .then(responseData => {
                // console.log(responseData);
            })
            .catch(error => {
                // console.error('Erreur:', error);
            });

            // update statistic admin tm
            axiosInstance.post('/stat_send_message_fp_to_vp', {
                treatedMessage: response.data.datas.treatedMessage,
                type_mess: response.data.datas.type_mess,
                newMessageId: response.data.datas.newMessageId,
            },{
                headers: { 'Authorization': `Bearer ${authState.token}` }
            })
            .then((response) => {
                // console.log(response)
            })
            .catch((error) => {
                // console.log(error)
            })
        })
        .catch((error) => {
            // console.log('error : ',error)
        })

    }
    
    const gifList = () => {
        axiosInstance.get("/gif/list", {
            headers: {
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then((response) => {
            setGifs(response.data.gifs)
        })
        .catch((error) => {
            // console.log(error)
        })
    }

    const getMsgId = (ind) => messageState.message_data[ind].message_id
    const etatMessage = async(ind,type) => {
        type && await axiosInstance.get(`/setEtatMsg/${getMsgId(ind)}`, { headers: {'Authorization': `Bearer ${authState.token}`} })
        setMessages({...messageState,message_data:messageState.message_data.map((p,i)=> i === ind ? {...p,etat : true} : p)})
    }
    const reportMessage = async (ind,content, setLoading) =>{
        const data = {
            id_message: getMsgId(ind),
            content: content,
            id_agent: authState.userId
        }
        try {
            setLoading(true)
            await axiosInstance.post(`/report`,data, { 
                headers: {'Authorization': `Bearer ${authState.token}`} 
            })
            etatMessage(ind)
            return true
        } catch (error) {
            return false
        } finally {
            setLoading(false)
        }
    }

    return {sendMessage,getData,gifList, reportMessage, etatMessage}
}
export default useMessage