import React, {useState, useEffect, useContext, useCallback} from "react";
import { Stack, Button, Typography, Box, CircularProgress, Chip, DialogActions, Dialog, DialogTitle, DialogContent, Grid } from "@mui/material";
import useLanguage from "../../hooks/useLanguage";
import Title from "./Title";
import TableRow from "../../tools/TableRow";
import Subtitle from "./Subtitle";
import { useForm } from "react-hook-form";
import FormRow from "../../tools/FormRow";
import axiosInstance from "../../../axios";
import useData from "../../hooks/useData";
import countriesList from "../../../helper/countries";
import useList from "../../hooks/useList";
import { StatContext } from "../../state/StatContext";
import { format, parseISO } from 'date-fns';
import { AuthStateContext } from "../../state/AuthStateContext";
import FolderPop from "./FolderPop";
import CustomPopup from "../../Admin/CustomPopup";
import { AccessArea, addAll, DialogNote, EditAdmin } from "../../tools/Snackbars";
import ReactDatePicker from "react-datepicker";
import SlidingTypography from "../../tools/SlidingTypography";

export default () => {
    const { text } = useLanguage()
    const { languagesgifList,listCAdmin,qualityControllerOpt1 } = useData()
    const { authState } = useContext(AuthStateContext)
    const { control, reset, handleSubmit, formState: { errors } } = useForm()
    const [error,setError] = useState('')
    const {getPlatform} = useList()
    const [platform,setPlatform] = useState([])
    const [opt,setOpt] = useState(listCAdmin)
    const [plat,setPlat] = useState([0])
    const { stat,setStat } = useContext(StatContext);
    const [adminss, setAdminss] = useState([]) ;
    const [loadingActions, setLoadingActions] = useState(0) ;
    const [editNote,setEditNote] = useState(null)
    const [platform2,setPlatform2] = useState([])
    const [activeDate,setActiveDate] = useState(false)

    useEffect(()=>{
        if(stat && !adminss.length) setAdminss(Object.keys(stat.adminlist))
    },[stat])

    useEffect(() =>{
        getPlatform()
        .then(res => {
            let platform = [{value:0,innerHTML:'All',owner:null}]
            let platform2 = [{value:0,innerHTML:'All',owner:null}]
            res.forEach(p => {
                if(!p.owner) platform2.push({value:p.id,innerHTML:p.name,owner:p.owner})
                platform.push({value:p.id,innerHTML:p.name,owner:p.owner})
            })
            // console.log('platforme : ',platform,platform2)
            if(platform.length > 1) setPlat([1])
            setPlatform(platform)
            setPlatform2(platform2)
        })
    },[])

    const changePlat = (val) => {
        const allPlat = () => {
            let platf = []
            platform2.forEach((p,i) => platf.push(i))
            return platf
        }
        if(val.length) setPlat((val[val.length-1] === 0 || (val[val.length-1] !== 0 && !plat.includes(0) && val.length === platform2.length-1)) ? allPlat() : val.filter(f => f !== 0))
    }

    // liste de base de donnée teste
    const [ bases, setBases ] = useState([])
    useEffect(() => {
        axiosInstance.get("/list_test", {
            headers: { 
                'Authorization': `Bearer ${authState.token}`
            }
        })
        .then((response) => {
            // console.log(response)
            setBases(response.data.data)
        })
        .catch((error) => {
            // console.log(error)
        })
    }, [])

    // Ajout new base de donnée teste
    const [ loading, setLoading ] = useState(false)
    const handleCreateTest = (data) => {
        setLoading(true)
        const plt = []
        plat.forEach(p => p && plt.push(platform2[p].value))
        axiosInstance.post("/create_test", {
            'mail': data.mail,
            'password': data.password,
            'role': "administrator",
            'login': "test",
            'name': data.name,
            'country': data.country,
            'languageName': data.languageName,
            'company': data.company,
            'folder': JSON.stringify(plt),
            'accessarea': JSON.stringify(opt),
            'validity': activeDate ? data.validity : 'null',
        },{
            headers: { 
                'Authorization': `Bearer ${authState.token}`
            }
        })
        .then((response) => {
            // console.log("reponse:", response)
            setPlatform2(platform2.filter((_,i) => !plat.includes(i)))
            setPlat([1])
            setBases((prev) => [...prev, response.data.data])
            setLoading(false)
            reset()
        })
        .catch((error) => {
            // console.log(error)
            setLoading(false)
        })
    }

    // suppression d'un base de donnée teste
    const [ baseToDelete, setBaseToDelete ] = useState()
    const [ open, setOpen ] = useState(false)
    const [ load, setLoad ] = useState(false)
    const handleDelete = () => {
        setLoad(true)
        axiosInstance.delete(`/delete_test/${baseToDelete.id}`, {
            headers: { 
                'Authorization': `Bearer ${authState.token}`
            }
        })
        .then((response) => {
            // console.log(response)
            setBases((prev) => prev.filter((b) => b.id != response.data.id))
            setLoad(false)
            setOpen(false)
        })
        .catch((error) => {
            // console.log(error)
        })
    }
    

    return <Stack padding="2%" spacing={3} height="100%" style={{ overflowY: "scroll" }}>
        <FolderPop loading={loading} setLoading={setLoading} />
        <Dialog open={editNote !== null ? true : false} onClose={()=>setEditNote(null)}>
            {stat && editNote !== null && <EditAdmin token={authState.token} setEditNote={setEditNote} countriesList={countriesList} languagesgifList={languagesgifList} qualityControllerOpt1={qualityControllerOpt1} text={text} platform={platform} bases={bases} editNote={editNote} setBases={setBases}/>}
        </Dialog>

        <CustomPopup open={open} onClose={() => setOpen(false)}>
            <div style={{ padding: "18px 30px"}}>
                <DialogTitle id="alert-dialog-title" sx={{textAlign: "center"}}>
                    <p style={{ fontWeight: "800", fontSize: "22px"}}>
                        Voulez-vous vraiment supprimer {baseToDelete?.name} ?
                    </p>
                </DialogTitle>
                <DialogActions>
                    <Button
                        variant='contained' 
                        sx={{ 
                            fontWeight: "700", 
                            bgcolor: "error.main",
                            color: "white",
                            "&:hover": {
                                bgcolor: "error.dark",
                            } 
                        }} 
                        onClick={() => setOpen(false)}
                    >
                        {text.annuler}
                    </Button>
                    <Button 
                        variant='contained' 
                        sx={{ 
                            fontWeight: "700", 
                            bgcolor: "success.main", 
                            color: "white",
                            "&:hover": {
                                bgcolor: "success.dark",
                            } 
                        }} 
                        onClick={()=> handleDelete()}
                    >
                        {load ? text.phload : text.cnfrm}
                    </Button>
                </DialogActions>
            </div>
        </CustomPopup>

        <Title>{text.tmfldaa}</Title>
        <Stack spacing={1}>
            <TableRow header yellow columns={[
                { xs: 1.23,  text: text.idd},
                { xs: 1.23,  text: text.nm},
                { xs: 1.23,  text: text.cmpn},
                { xs: 1.23,  text: "Email"},
                { xs: 2.46,  text: text.tmfld},
                { xs: 1.23,  text: text.accar},
                { xs: 1.23,  text: text.assvldt},
                { xs: 1.23,  text: text.action},
            ]}/>
            { bases.map((base) => {
                return(
                    <TableRow key={base.id} yellow columns={[
                        { xs: 1.23,
                            customContent: true,
                            content:<Typography>{base.id}</Typography>},
                        { xs: 1.23,
                            customContent: true,
                            content:<Typography>{base.name}</Typography>},
                        { xs: 1.23,
                            customContent: true,
                            content:<Typography>{base.company}</Typography>
                        },
                        { xs: 1.23,
                            customContent: true,
                            content:<SlidingTypography>{base.email}</SlidingTypography>
                        },
                        { xs: 2.46,
                            customContent: true,
                            content: <Stack style={{flexDirection:'row',gap:1,flexWrap:'wrap',padding:'2px'}}>
                                        {(base.tmFolder) ? base.tmFolder.map((p,i)=><Chip key={i} label={platform[platform.findIndex(k => k.value === p)]?.innerHTML}></Chip>) : 'All'}
                                    </Stack>},
                        { xs: 1.23,
                            customContent: true,
                            content: <AccessArea admin={base}/>},
                        { xs: 1.23,
                            customContent: true,
                            content:<Typography>{base.accessvalidity ? base.accessvalidity : 'Illimité'}</Typography>
                        },
                        {
                            xs: 1.23,
                            customContent: true,
                            content: <Stack width='100%'>
                                 <Button
                                    style={{width: '100%', height: '100%', textTransform: 'capitalize', color: '#fff',backgroundColor:'skyblue'}}
                                    onClick={() => setEditNote(base)}>
                                    <Typography style={{fontFamily: 'Delm'}}>{text.editer}</Typography>
                                </Button>       
                                <Button
                                    style={{width: '100%', height: '100%', textTransform: 'capitalize', color: '#000'}}
                                    onClick={() => {setBaseToDelete(base); setOpen(true)}}>
                                    <Typography style={{fontFamily: 'Delm'}}>{text.dlt}</Typography>
                                </Button>          
                            </Stack>
                        },
                    ]}/>
                )
            })}
        </Stack>

        <Subtitle>{text.ddnwadm}</Subtitle>
        <Stack spacing={2}>
            <FormRow control={control} label={text.nm} name="name" error={errors.name} bgCol="#9aacff" variant="formrow3"/>
            <FormRow control={control} label={text.mladd} name="mail" error={errors.mail} bgCol="#9aacff" variant="formrow3"/>
            <FormRow control={control} label={text.language} name="languageName" comp="Select" error={errors.languageName} options={languagesgifList} bgCol="#9aacff" inpCol='#e3e8fe' />
            <FormRow control={control} label={text.pays} name="country" comp="Select" error={errors.country} options={countriesList} bgCol="#9aacff" inpCol='#e3e8fe'/>
            <FormRow control={control} label={text.cmpnnm} name="company" error={errors.company} bgCol="#9aacff" variant="formrow3"/>
            <FormRow control={control} label={text.tmfldr} comp="Select1" name='' options={platform2} bgCol="#9aacff" inpCol='#e3e8fe' error={errors.folder} opt={plat} setOpt={changePlat} variant="formrow3"/>
            <FormRow control={control} label={text.psswrd} name="password" error={errors.password} bgCol="#9aacff" variant="formrow3"/>
            <FormRow control={control} label={text.accssar} comp="Select1" options={qualityControllerOpt1} name="accessarea" error={errors.accessarea} bgCol="#9aacff" variant="formrow3" inpCol='#e3e8fe' opt={opt} setOpt={setOpt}/>
            <Stack style={{width:'100%',position:'relative',justifyContent:'center'}}>
            <FormRow control={control} disabled={!activeDate} dateFormat='dd/MM/yyyy' defaultValue={new Date()} label={text.valdt} name="validity" comp="DatePicker" minDate = {new Date()} error={errors.validity} bgCol="#9aacff" className/>
            <input type='checkbox' style={{position:'absolute',height:'80%',aspectRatio:'1/1',right:'4em'}} value={activeDate} onChange={(e) => setActiveDate(e.target.checked)}></input>
            </Stack>
        </Stack>
        <Stack alignItems="center" justifyContent="center" flexDirection="row">
            <Button 
                onClick={handleSubmit(handleCreateTest)}
                style={{ fontWeight: "bold", borderRadius: "30px", width: "fit-content", color: "#fff", background: "#4091d1", border: "3px solid #1c4b8e" }}
            >
                {text.rgstr}
            </Button>
            <Box component= "img" src="/images/admin2/register.png" style={{ height: "45px" }}/>
        </Stack>
    </Stack>
}

