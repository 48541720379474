import { Stack, Typography, Button, Snackbar, Alert, Dialog, Box, Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import useLanguage from "../hooks/useLanguage";
import BigContainerAccordion from "../tools/BigContainerAccordion";
import TableRow from "../tools/TableRow";
import useData from "../hooks/useData";
import SearchFilter from "../tools/SearchFilter";
import axiosInstance from "../../axios";
import { AuthStateContext } from "../state/AuthStateContext";
import Pusher from 'pusher-js'
import PopupDelete from "./PopupDelete";
import { useForm } from "react-hook-form";
import FileInput from "../tools/FileInput";
import CustomAlert from "../Admin/CustomAlert";
import CustomPopup from "../Admin/CustomPopup";
import { PUSHER_SECRET,CLUSTER, apiURL, baseURL } from "../../helper/conf";
import { filterData } from "../../helper/filter";
import { GifsContext } from "../state/gifContext";
import { MessageContext } from "../state/MessageContext";
import Title from "../Admin2/Comps/Title";

const PhotosRequest = () =>{
    const { globalFilter } = useContext(GifsContext)
    const {text} = useLanguage()
    const {languagesList} = useData()
    const { authState } = useContext(AuthStateContext)
    const [ request, setRequest ] = useState()
    const {chan1} = useContext(MessageContext)

    const [filter, setFilter] = useState({
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        date: '',
        startingDate: '',
        closingDate: '',
        platforms: '',
        phototype: ''
    })

    const photoList = () => {
        axiosInstance.get("/photoRequest/list", {
            headers: {
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then((response) => {
            setRequest(response.data.photoRequests)
        })
        .catch((error) => {
            // console.log(error)
        })
    }

    const [sendRequest, setSendRequest] = useState()
    useEffect(()=>{
        axiosInstance.get("/photoRequest/sendList", {
            headers: {
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then((response) => {
            setSendRequest(response.data.photoSendRequests)
        })
        .catch((error) => {
            // console.log(error)
        })
    },[])

    // PUSHER RECEIVE NEW PHOTO AND RECEIVE PHOTO SEND
    useEffect(()=>{
        photoList()
        const pusher = new Pusher(PUSHER_SECRET,{cluster:CLUSTER,channelAuthorization:{endpoint:`${apiURL}/pusher/user-auth`,transport:'jsonp'}})
        const channel = pusher.subscribe(`private-${authState.userId}`)
        channel.bind('add-photo', (data) => {
            setRequest((last) => [...last, data.request])
        })
        channel.bind('add-photoSend', (data) => {
            setSendRequest((p) => [...p, data])
        })
        return()=>{
            channel.unbind()
            pusher.unsubscribe(`private-${authState.userId}`)
        }
    },[])


    //Popup delete photo
    const [pop, setPop] = useState(false);
    const [ openAlert, setOpenAlert ] = useState(false)
    const [ deleteData, setDeleteData ] = useState()
    const handleClose = () => {
        setOpenAlert(false);
    };
    const handleClickOpen = () => {
        setPop(true);
    };
    const handleCl = () => {
        setPop(false);
    };

    // uploide photo
    const [ photoToUploid, setPhotoToUploid ] = useState()
    const [ alertContenu, setAlertContenu ] = useState("")
    const [ alertOpen, setAlertOpen ] = useState(false)
    const [ popPhoto, setPopPhoto ] = useState(false)
    const [ loading, setLoading ] = useState(false)

    const [images, setImages] = useState([]);
    const [ imUrl, setImUrl ] = useState([])
    const handleImageUpload = (event) => {
        const files = Array.from(event.target.files);
        setImUrl(files.map((file) => URL.createObjectURL(file)));
        setImages((prev) => [...prev, ...event.target.files]);
    };

    const uploidPhoto = () => {
        setLoading(true)
        const formData = new FormData();
        formData.append("photoRequestId", photoToUploid?.request_id);
        images.forEach((image) => formData.append("images[]", image));

        axiosInstance.post("/photoRequest/answer", formData, {
            headers: { 
                'Authorization': `Bearer ${authState.token}`,
                'Content-Type': 'multipart/form-data'
            },
            transformRequest: null
        })
        .then((response) => {
            // console.log(response.data.message)
            setAlertContenu(text.upldd)
            setAlertOpen(true)
            photoList()
            setPopPhoto(false)
            setLoading(false)
            setImUrl([])
            setImages([])
            setPhotoToUploid()
        })
        .catch((error) => {
            // console.log(error)
        })
    }

    return(
        <Stack spacing={2} style={{ overflowY: "scroll" }}>
            <Snackbar
                open={openAlert}
                autoHideDuration={1500}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={handleClose} severity="success">
                    {text.delalert}
                </Alert>
            </Snackbar>
            <Dialog
                open={pop}
                onClose={handleCl}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <PopupDelete handleCl={handleCl} setOpenAlert={setOpenAlert} deleteData={deleteData} photoList={photoList} />
            </Dialog>

            {/* custum for delete photo */}
            <CustomAlert
                open={alertOpen}
                autoHideDuration={1500}
                onClose={() => setAlertOpen(false)}
                severity="success"
                message={alertContenu}
            />
            <CustomPopup open={popPhoto} onClose={() => setPopPhoto(false)}>
                <Box sx={{ py: "20px", px: "25px"}}>
                    <Box sx={{ mb: "10px"}}>
                        <Typography sx={{ fontWeight: "900", mb: "10px", fontSize: "20px"}}>
                            {text.addpht} :
                        </Typography>
                        <Stack sx={{ flexDirection: "row", gap: "6px"}}>
                            <Typography sx={{ fontWeight: "900", mb: "10px"}}>
                                {text.descri}:
                            </Typography>
                            <Typography sx={{ fontWeight: "700", color: "#757575"}}>
                                {photoToUploid?.image_type}
                            </Typography>
                        </Stack>
                    </Box>
                    <Button variant="contained" component="label">
                        Upload Images
                        <input type="file" hidden multiple onChange={handleImageUpload} />
                    </Button>
                    <Grid container spacing={2} sx={{ mt: 2 }}>
                        {imUrl.map((image, index) => (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                                <Box
                                component="img"
                                src={image}
                                alt={`Uploaded ${index}`}
                                sx={{ width: '100%', height: 'auto' }}
                                />
                            </Grid>
                        ))}
                    </Grid>
                    <Stack sx={{ mt: "40px", flexDirection: "row", gap: "20px"}}>
                        <Button 
                            variant='contained' 
                            sx={{ 
                                fontWeight: "700", 
                                bgcolor: "error.main",
                                color: "white",
                                "&:hover": {
                                    bgcolor: "error.dark",
                                } 
                            }}
                            onClick={() => {setPopPhoto(false)}}
                        >
                            {text.annuler}
                        </Button>
                        <Button 
                            variant='contained' 
                            sx={{ 
                                fontWeight: "700", 
                                bgcolor: "success.main", 
                                color: "white",
                                "&:hover": {
                                    bgcolor: "success.dark",
                                } 
                            }} 
                            onClick={() => uploidPhoto()}
                        >
                            {loading ? text.phload : text.snd}
                        </Button>
                    </Stack>
                </Box>
            </CustomPopup>
            {/* custum for delete photo end */}

            <Stack style={{display: 'flex', alignItems: 'center', gap: '15px', justifyContent: 'center', flexDirection: 'row'}}>
                {/* <Typography variant="h1" alignSelf='center' style={{fontFamily: 'Fascinate Regular', fontWeight: 'bold', color: '#a5e2ff', WebkitTextStroke: '2px #24617e'}}
                >{text.photosrequests}</Typography> */}
                <Title>{text.pr}</Title>
                {/* <img className="card-img-resize-2" src={"/images/supervisor/8pho.png"} alt="" height='50px' width='50px'/> */}
            </Stack>
            <BigContainerAccordion
                transparent 
                summaryBg="#bd2424"
                summary={text.lstphtrq}
                details={
                    <Stack spacing={2}>
                        <SearchFilter
                            selectedDate={filter.date}
                            onDateChange={{
                                year: y => setFilter({...filter, year: y}),
                                month: m => setFilter({...filter, month: m}),
                                date: d => setFilter({...filter, date: d}),
                            }}
                            items= {[
                                { label: text.startingdate , comp: 'DatePicker', selected: filter.startingDate, onChange: d => setFilter({...filter, startingDate: d})},
                                { label: text.closingdate , comp: 'DatePicker', selected: filter.closingDate, onChange: d => setFilter({...filter, closingDate: d})},
                                { label: text.platform , comp: 'TextInput', onChange: e => setFilter({...filter, platforms: e.target.value}) },
                                { label: text.language, comp: 'Select', choices: languagesList, onChange: e => setFilter({ ...filter, language: e.target.value }) },
                                { label: text.phttp, comp: 'TextInput', onChange: e => setFilter({...filter, phototype: e.target.value}) }
                            ]}
                        />
                        <Stack spacing={'10px'}>
                            <TableRow header columns={[
                                { xs: 1.2,  text: text.date},
                                { xs: 1.2,  text: text.platform},
                                { xs: 1.2,  text: text.idrq},
                                { xs: 1.2,  text: text.phttp},
                                { xs: 1.2,  text: text.fp},
                                { xs: 1.2,  text: text.rp},
                                { xs: 1.2,  text: text.agent},
                                { xs: 1.2,  text: text.pht},
                                { xs: 1.2,  text: text.actions},
                            ]}/>
                            {
                                request?.map((pr, i) =>
                                <TableRow key={`pr${i}`} columns={[
                                    { xs: 1.2,  text: pr.created_at},
                                    { xs: 1.2,  text: pr.platform.name},
                                    { xs: 1.2,  text: pr.request_id},
                                    { xs: 1.2,  text: pr.image_type},
                                    { xs: 1.2,  text: pr.fake_profil},
                                    { xs: 1.2,  text: pr.real_profil},
                                    { xs: 1.2,  text: pr.agent},
                                    {
                                        xs: 1.2,
                                        customContent: true,
                                        content: <Stack style={{width: '100%', height: '100%', textTransform: 'capitalize', color: '#000'}}>
                                            <Button onClick={() => { setPhotoToUploid(pr); setPopPhoto(true); }}>
                                                <Typography style={{fontFamily: 'Delm'}}>{text.upld}</Typography>
                                            </Button>
                                        </Stack>
                                    },
                                    {
                                        xs: 1.2,
                                        customContent: true,
                                        content: <Stack style={{width: '100%'}}>
                                            <Button style={{width: '100%', height: '100%', textTransform: 'capitalize', color: '#000'}}><Typography style={{fontFamily: 'Delm'}}>{text.edit}</Typography></Button>
                                            <Button onClick={() => { setDeleteData(pr); handleClickOpen() }} style={{width: '100%', height: '100%', textTransform: 'capitalize', color: '#000'}}><Typography style={{fontFamily: 'Delm'}}>{text.dlt}</Typography></Button>
                                            {/* <Button style={{width: '100%', height: '100%', textTransform: 'capitalize', color: '#000'}}><Typography style={{fontFamily: 'Delm'}}>{text.snd}</Typography></Button> */}
                                        </Stack>
                                    },
                                ]}/>
                                )
                            }
                        </Stack>
                    </Stack>
                }
            />
            <BigContainerAccordion 
                transparent
                summaryBg="#bd2424"
                summary={text.phtsnttofp}
                details={
                    <Stack spacing={2}>
                        <SearchFilter
                            selectedDate={filter.date}
                            onDateChange={{
                                year: y => setFilter({...filter, year: y}),
                                month: m => setFilter({...filter, month: m}),
                                date: d => setFilter({...filter, date: d}),
                            }}
                            items= {[
                                { label: text.startingdate , comp: 'DatePicker', selected: filter.startingDate, onChange: d => setFilter({...filter, startingDate: d})},
                                { label: text.closingdate , comp: 'DatePicker', selected: filter.closingDate, onChange: d => setFilter({...filter, closingDate: d})},
                                { label: text.platform , comp: 'TextInput', onChange: e => setFilter({...filter, platforms: e.target.value}) },
                                { label: text.phttp, comp: 'TextInput', onChange: e => setFilter({...filter, phototype: e.target.value}) }
                            ]}
                        />
                        <Stack spacing={'10px'}>
                            <TableRow header columns={[
                                { xs: 1.1,  text: text.date},
                                { xs: 1.1,  text: text.platform},
                                { xs: 1.1,  text: text.language},
                                { xs: 1.1,  text: text.idrq},
                                { xs: 1.1,  text: text.phttp},
                                { xs: 1.1,  text: text.fp},
                                { xs: 1.1,  text: text.rp},
                                { xs: 1.1,  text: text.agent},
                                { xs: 1.1,  text: text.pht},
                                { xs: 1.1,  text: text.actions},
                            ]}/>
                            {
                                sendRequest?.map((pr, i) =>
                                <TableRow key={`pr${i}`} columns={[
                                    { xs: 1.1,  text: pr.created_at},
                                    { xs: 1.1,  text: pr.platform.name},
                                    { xs: 1.1,  text: pr.language},
                                    { xs: 1.1,  text: pr.request_id},
                                    { xs: 1.1,  text: pr.image_type},
                                    { xs: 1.1,  text: pr.fake_profil},
                                    { xs: 1.1,  text: pr.real_profil},
                                    { xs: 1.1,  text: pr.agent},
                                    {
                                        xs: 1.1,
                                        customContent: true,
                                        content: <Stack style={{width: '100%', height: '100%', textTransform: 'capitalize', color: '#000'}}>
                                            <div>
                                                <div style={{ padding: '4px'}} >
                                                    <img src={`${baseURL}/photorequests/${pr.image_name}`} style={{ width: '100px', height: '100px' }} alt="photo" />
                                                </div>
                                            </div>
                                        </Stack>
                                    },
                                    {
                                        xs: 1.1,
                                        customContent: true,
                                        content: <Stack style={{width: '100%'}}>
                                            <Button style={{width: '100%', height: '100%', textTransform: 'capitalize', color: '#000'}}><Typography style={{fontFamily: 'Delm'}}>{text.edit}</Typography></Button>
                                            <Button style={{width: '100%', height: '100%', textTransform: 'capitalize', color: '#000'}}>
                                                <Typography style={{fontFamily: 'Delm'}}>{text.dlt}</Typography>
                                            </Button>
                                        </Stack>
                                    },
                                ]}/>
                                )
                            }
                        </Stack>
                    </Stack>
                }
            />
        </Stack>
    )
}

export default PhotosRequest