import React, {useContext, useEffect, useState} from "react";
import { Stack, Button, Typography } from "@mui/material";
import useLanguage from "../../hooks/useLanguage";
import Title from "./Title";
import TableRow from "../../tools/TableRow";
import { StatContext } from "../../state/StatContext";
import { AuthStateContext } from "../../state/AuthStateContext";
import axiosInstance from "../../../axios";
import Pusher from "pusher-js";
import { apiURL, CLUSTER, PUSHER_SECRET } from "../../../helper/conf";

export default ({onDetails}) => {
    const { text } = useLanguage()

    const [ photoR, setPhotoR ] = useState();
    const { authState } = useContext(AuthStateContext)

    // GET STATISTIQUE PHOTO_REQUEST
    useEffect(() => {
        axiosInstance.get(`/statistic/photorequest`, {
            headers: { 
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then((resp) => {
            setPhotoR(resp.data.data.photorequests)
        })
        .catch((error) => {
            console.log(error)
        })
    }, [])

    //PUSHER STATISTIC PHOTO_REQUEST
    const [ data, setData ] = useState()
    useEffect(() => {
        const pusher = new Pusher(PUSHER_SECRET, {
            cluster: CLUSTER,
            channelAuthorization: {
                endpoint: `${apiURL}/pusher/user-auth`,
                transport:'jsonp'
            },
        });
    
        const channel = pusher.subscribe("private-statistic2");
        channel.bind(`${authState?.userId}`, (data) => {
            if (data.name === "photorequests") {
                setData(data);
            }
        });
    
        return () => {
            channel.unbind_all();
            pusher.unsubscribe("private-statistic2");
        };
    }, []);
    

    useEffect(()=>{
        if (data) {
            setPhotoR((prev) => {
                const updatedStat = JSON.parse(JSON.stringify(prev));
                updatedStat[data.platform][data.field] += data.value;
                return updatedStat;
            });
        }
    }, [data])

    const platforms = Object.keys(photoR ?? {});

    const StatPhotoTab = (platform) => {
        return (
            <TableRow columns={[
                
                { xs: 3.8,
                    customContent: true,
                    content:
                        <Typography>
                            {platform.charAt(0).toUpperCase() + platform.slice(1)}
                        </Typography>

                    
                },
                { xs: 3.8,
                    customContent: true,
                    content: 
                        <Typography  key={platform}>
                            {photoR[platform]?.requested}
                        </Typography>
                },
                { xs: 3.8,
                    customContent: true,
                    content: 
                        <Typography key={platform}>
                            {photoR[platform]?.sent}
                        </Typography>
                },
            ]}/>
        );
    };
    return <Stack padding="2%" spacing={3} height="100%" style={{ overflowY: "scroll" }}>
        <Title>{text.pr}</Title>
        <Stack spacing={1}>
            <TableRow header columns={[
                { xs: 3.8,  text: text.platform},
                { xs: 3.8,  text: text.dmndees},
                { xs: 3.8,  text: text.envoyees},
            ]}/>
            {platforms.map((platform) => (<Stack key={platform}>{StatPhotoTab(platform)}</Stack>))}
        </Stack>
        <Button 
            onClick={onDetails} 
            style={{ backgroundColor: '#feba3b', textTransform: 'capitalize', color: '#000', borderRadius: '15px', border: '2px solid #fff', width: 'fit-content', alignSelf: 'center'}}
        >
            <Typography style={{fontFamily: 'Chewy Pro'}}>
                {text.details}
            </Typography>
        </Button>
    </Stack>
}

