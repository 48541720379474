import React, { useRef, useEffect, useContext, useState } from "react";
import useLanguage from "../hooks/useLanguage";
import { Stack, Typography, Button, Grid, DialogActions, Box } from "@mui/material";
import { MessageContext } from "../state/MessageContext";
import { baseURL } from "../../helper/conf";
import { AuthStateContext } from "../state/AuthStateContext";
import axiosInstance from "../../axios";
import CustomAlert from "../Admin/CustomAlert";
import CustomPopup from "../Admin/CustomPopup";
import useMessage from "../hooks/useMessage";

const BodyMessage = ({ setOpen }) => {
    const { authState } = useContext(AuthStateContext)
    const { text } = useLanguage()
    const { messageState, scrollT, setScrollTs, setMessages } = useContext(MessageContext)
    const { reportMessage, etatMessage,getData } = useMessage()
    
    const scrollRef = useRef(null)
    useEffect(() => {
        if (scrollRef.current)
            if (scrollT) scrollRef.current.scrollTop = scrollT
    }, [messageState])


    // ADD MESSAGE TO PANIC ROOM
    const [loadingPanic, setLoadingPanic] = useState(false)
    const [alerto, setAlerto] = useState(false)
    const [alertContenu, setAlertContenu] = useState("")
    const [popPanic, setPopPanic] = useState({ etat: null, ind: null })
    const [messageToAdd, setMessageToAdd] = useState()
    const [raison, setRaison] = useState("")

    const reportMsg = async (ind) => {
        setMessageToAdd(messageState.message_data[ind])
        setPopPanic({ etat: 2, ind: ind })
    }
    const handleAddPanic = async () => {
        if (popPanic.etat === 1) {
            const bool = messageToAdd.message_id ? true : false
            // console.log('bool : ',bool)
            setLoadingPanic(true)
            axiosInstance.post('/panic_room/add', {
                "messageId": bool ? messageToAdd.message_id : messageState.wait_id[popPanic.ind],
                "reason": raison,
                'type':bool
            }, {
                headers: {
                    'Authorization': `Bearer ${authState.token}`,
                },
            })
                .then((response) => {
                    // console.log(response.data.message)
                    setAlertContenu(response.data.message)
                    setLoadingPanic(false)
                    setAlerto(true)
                    etatMessage(popPanic.ind,bool)
                    setRaison("")
                    setScrollTs(scrollRef.current?.scrollTop)
                    // setOpen(1)
                    setPopPanic({ ...popPanic, etat: null })
                    setMessages(null)
                    getData()
                })
                .catch((error) => {
                    // console.log(error)
                    setLoadingPanic(false)
                })


            // console.log(messageToAdd,messageState.wait_id)
        } else {
            await reportMessage(popPanic.ind,raison, setLoadingPanic).then(res => {
                if (res) {
                    setScrollTs(scrollRef.current?.scrollTop)
                    setAlertContenu(text.messreport)
                    setAlerto(true)
                    setRaison('')
                }
                setPopPanic({ ...popPanic, etat: null })
            })
        }

    }
    const panicMsg = (ind) => {
        setMessageToAdd({ ...messageState.message_data[ind], operator: messageState.real_profil.user_name })
        setPopPanic({ etat: 1, ind: ind })
    }
    return (
        <Stack style={{ padding: '20px 0' }}>
            <CustomAlert
                open={alerto}
                autoHideDuration={1500}
                onClose={() => setAlerto(false)}
                severity="success"
                message={alertContenu}
            />
            <CustomPopup open={popPanic.etat ? true : false} onClose={() => setPopPanic({ ...popPanic, etat: null })}>
                <Stack style={{ padding: "20px", minWidth: '350px', maxWidth: '500px' }}>
                    <Box>
                        <Stack sx={{ mb: "15px", fontWeight: "700", fontSize: "25px" }}>
                            {popPanic.etat === 1 ? text.panicmess : text.reportmess}
                        </Stack>
                        <Box sx={{ mb: "15px" }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", mr: "6px" }}>
                                    {text.delauteur} :
                                </Stack>
                                <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", color: "#757575" }}>
                                    {messageToAdd?.operator}
                                </Stack>
                            </div>
                            <Stack style={{ gap: 4, flexDirection: messageToAdd?.content.length < 20 ? 'row' : 'column', fontWeight: "700", fontSize: "20px" }} >
                                <span>{text.contenu} :</span>
                                <span style={{ color: "#8f8f8f" }}>{messageToAdd?.content}</span>
                            </Stack>
                        </Box>
                        <Stack>
                            <textarea
                                value={raison}
                                onChange={(e) => setRaison(e.target.value)}
                                type="text"
                                style={{ padding: "8px", fontSize: "20px", borderRadius: "10px" }}
                                placeholder={`${text.raison}...`}
                            />

                        </Stack>
                        {/* {popPanic.etat === 1 && <Box> */}
                    </Box>
                    <DialogActions>
                        <Button
                            variant='contained'
                            sx={{
                                fontWeight: "700",
                                bgcolor: "error.main",
                                color: "white",
                                "&:hover": {
                                    bgcolor: "error.dark",
                                }
                            }}
                            onClick={() => { setPopPanic({ ...popPanic, etat: null }); setRaison("") }}
                        >
                            {text.delrefuse}
                        </Button>
                        <Button
                            variant='contained'
                            sx={{
                                fontWeight: "700",
                                bgcolor: "success.main",
                                color: "white",
                                "&:hover": {
                                    bgcolor: "success.dark",
                                }
                            }}
                            onClick={() => handleAddPanic()}
                        >
                            {loadingPanic ? text.phload : text.delaccept}
                        </Button>
                    </DialogActions>
                </Stack>
            </CustomPopup>
            <Stack style={{ width: "100%", height: '30px', backgroundColor: "#d48b70", display: "flex", justifyContent: "center" }}>
                <Typography variant="white" padding="0 0 0 10px">{text.conv}: {messageState?.convId}</Typography>
            </Stack>
            <Stack ref={scrollRef} className='scroll2' style={{ maxHeight:"800px", overflowY: "auto",border:'2px solid #d48b70' }}>
                {
                    messageState?.message_data
                        // .sort((a, b) => a.message_id - b.message_id)
                        .map(
                            (mess, i) => {
                                return(
                                    <div key={i}>
                                    { mess?.type_mess !== 'stop' && 
                                        <Stack style={{ borderBottom:i !== messageState.message_data.length-1 ? '2px solid #d48b70':'',padding:'4px', textAlign: mess.type_profil == 2 ? 'right' : 'left', backgroundColor: !mess.message_id ? '#c5e6f5' : '' }} spacing={3}>
                                            <Stack>
                                                {mess?.type_profil == 1 ?
                                                    <Typography variant="pfsbold"> {messageState?.real_profil.user_name} {text.say}:</Typography>
                                                    :
                                                    <Typography variant="pfsbold"> {messageState?.fake_profil.user_name} {text.say}:</Typography>
                                                }
                                                {mess?.type_profil == 2 ? <Typography variant="pfsbold"> {text.operatorid} : {mess.operator}</Typography> : <></>}
                                                <Typography>{text.dh}: {mess.message_at}</Typography>
                                                {mess?.message_id && <Typography>{text.mid}: {mess.message_id}</Typography>}
                                                {mess?.content &&
                                                    <Typography>
                                                        [{mess.content?.length} {(mess.content.length > 1) ? text.chars : text.char}]
                                                    </Typography>
                                                }
                                            </Stack>
                                            <Stack sx={{ flexDirection: mess.type_profil == 2 ? "row-reverse" : "row" }}>
                                                <Stack sx={{ width: "60%" }}>
                                                    {mess?.content &&
                                                        <Typography style={{ fontWeight: "bold", textAlign: 'left' }}>
                                                            {mess.content}
                                                        </Typography>
                                                    }
                                                    {mess.photos?.length > 0 &&
                                                        <Stack flexDirection={"row-reverse"}>
                                                            {mess.photos?.map((image) => {
                                                                return (
                                                                    <div key={image.id}>
                                                                        <div style={{ padding: '4px' }} >
                                                                            <img src={`${baseURL}/photorequests/${image.photoName}`} style={{ width: '100px', height: '100px' }} alt="photo" />
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </Stack>
                                                    }
                                                    {mess.gifs?.length > 0 &&
                                                        <Stack flexDirection={"row-reverse"}>
                                                            {mess.gifs?.map((gif) => {
                                                                return (
                                                                    <div key={gif.id}>
                                                                        <div style={{ padding: '4px' }} >
                                                                            <img src={`${baseURL}/gifs/${gif.photoName}`} style={{ width: '100px', height: '100px' }} alt="gif" />
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </Stack>
                                                    }
                                                    {mess?.fileUrl &&
                                                        <div style={{ padding: '4px' }} >
                                                            <img src={mess?.fileUrl} style={{ width: '100px' }} alt="url_file" />
                                                        </div>           
                                                    }
                                                </Stack>
                                            </Stack>
                                            <Stack direction="row">
                                                {mess?.type_profil == 2 ?
                                                    <Button disabled={mess?.etat} variant="hover" style={{ backgroundColor: `${!mess?.etat ? '#f61b01' : '#c7c7c7'}`, width: "fit-content", marginLeft: "auto" }} onClick={() => reportMsg(i)}>
                                                        <Typography variant="pfsboldwhite" style={{ textTransform: "capitalize" }}>{text.repmess}</Typography>
                                                    </Button>
                                                    :
                                                    <Button disabled={mess?.etat} variant="hover" style={{ backgroundColor: `${!mess?.etat ? '#f61b01' : '#c7c7c7'}`, width: "fit-content", marginRight: "auto" }} onClick={() => { panicMsg(i) }}>
                                                        <Typography variant="pfsboldwhite" style={{ textTransform: "capitalize" }}>{text.panicroom}</Typography>
                                                    </Button>
                                                }
                                            </Stack>
                                        </Stack>
                                    }
                                    </div>
                                )
                            }
                        )
                }
            </Stack>



        </Stack>
    )
}
export default BodyMessage