import React, { useContext, useEffect, useState } from "react";
import { Stack, Typography, Box } from "@mui/material";
import useLanguage from "../hooks/useLanguage";
import SearchFilter from "../tools/SearchFilter";
import AdminCard from "../tools/AdminCard";
import axiosInstance from "../../axios";
import { AuthStateContext } from "../state/AuthStateContext";
import useData from "../hooks/useData";
import Pusher from 'pusher-js'
import { CLUSTER, PUSHER_SECRET, apiURL } from "../../helper/conf";
import { GifsContext } from "../state/gifContext";
import { formatDate } from "../../helper/formatDate";
import { MessageContext } from "../state/MessageContext";

const ChattoolOverview = ({setStat,stat,seeDetails}) => {
    const { globalFilter, setGlobalFilter } = useContext(GifsContext)
    const {languagesList} = useData()
    const {text} = useLanguage()
    const { authState } = useContext(AuthStateContext)
    const {setChan1} = useContext(MessageContext)
    const supUrl = '/images/supervisor/'

    const [filter, setFilter] = useState({
        year: new Date().getFullYear() + 1,
        month: 1,
        date: '',
        startingDate: '',
        closingDate: '',
        platforms: '',
        agents: '',
        language: '',
    })

    useEffect(()=>{
        axiosInstance.get(`/statistic?year=${globalFilter?.year}&month=${globalFilter?.month}&date=${formatDate(globalFilter?.date).split('-')[0]}&startingDate=${formatDate(globalFilter?.startingDate)}&closingDate=${formatDate(globalFilter?.closingDate)}&platforms=${globalFilter?.platforms}&language=${globalFilter?.language}&agents=${globalFilter?.agents}&week=${globalFilter.semaine}`, {
            headers: {
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then((response) => {
            // console.log(response.data)
            setStat(response.data)
        })
        .catch((error) => {
            // console.log(error)
        })
    },[globalFilter])

    //PUSHER STATISTIC
    const [ stat1, setStat1 ] = useState()
    useEffect(()=>{
        const pusher = new Pusher(PUSHER_SECRET,{cluster:CLUSTER,channelAuthorization:{endpoint:`${apiURL}/pusher/user-auth`,transport:'jsonp'}})
        const channel = pusher.subscribe("private-statistic")
        const chan1 = pusher.subscribe(`private-${authState.userId}`)
        setChan1(chan1)
        channel.bind(`${authState?.userId}`, (data)=> {
            // console.log(data)
            setStat1({name: data.name, field: data.field, value: data.value})
        })
        return()=>{
            channel.unbind()
            pusher.unsubscribe("private-statistic")
        }
    },[])

    useEffect(()=>{
        if(stat1){
            const number = stat[stat1.name][stat1.field] + stat1.value
            setStat({...stat, [stat1.name]:{...stat[stat1.name], [stat1.field]: number}})
            setStat1(null)
        }
    },[stat1])

    return(
        <Stack spacing={6}>
            <Typography style={{fontFamily: 'Fascinate Regular', fontWeight: 'bold', color: '#de5b6d', WebkitTextStroke: '2px #700712'}} variant="h1" alignSelf='center'>{text.chttlov}</Typography>
            <SearchFilter
                onSemaineClick={(semaine)=>setGlobalFilter({...globalFilter, semaine: semaine})}
                selectedDate={globalFilter.date}
                onDateChange={{
                    year: y => setGlobalFilter({...globalFilter, year: y}),
                    month: m => setGlobalFilter({...globalFilter, month: m}),
                    date: d => setGlobalFilter({...globalFilter, date: d}),
                }}
                items= {[
                    { label: text.startingdate , comp: 'DatePicker', selected: globalFilter.startingDate, onChange: d => setGlobalFilter({...globalFilter, startingDate: d})},
                    { label: text.closingdate , comp: 'DatePicker', selected: globalFilter.closingDate, onChange: d => setGlobalFilter({...globalFilter, closingDate: d})},
                    { label: text.platform , comp: 'TextInput', onChange: e => setGlobalFilter({...globalFilter, platforms: e.target.value}) },
                    { label: text.agents , comp: 'TextInput', onChange: e => setGlobalFilter({...globalFilter, agents: e.target.value}) },
                    { label: text.language1, comp: 'Select', choices: languagesList, onChange: e => setGlobalFilter({ ...globalFilter, language: e.target.value }) },
                ]}
            />
            <Stack spacing={2}>
                <Box className="wrap-content" justifyContent='center' gap='50px'>
                    <AdminCard 
                        imgSrc={ supUrl + "1conv.png"} 
                        borderFile='conv.png' 
                        label={text.convs} 
                        fields={[{label: text.treated , value: stat?.conversation.treated}, {label: text.onhld , value: stat?.conversation.onHold}]}  
                        onDetails={() => seeDetails(1)}
                    />
                    <AdminCard 
                        imgSrc={ supUrl +"2age.png"} 
                        borderFile="ag.png" label={text.agents} 
                        fields={[{label: text.onl , value: stat?.operator.online}, {label: text.total , value: stat?.operator.total}]} 
                        onDetails={() => seeDetails(2)}
                    />
                    <AdminCard 
                        imgSrc={ supUrl +"3pan.png"} 
                        borderFile="panicroom.png" 
                        label={text.panicroom} 
                        fields={[{label: text.unNouv , value: stat?.totalPanic}, {label: text.total , value: stat?.totalPanic}]} 
                        onDetails={() => seeDetails(3)}
                    />
                </Box>
                <Box className="wrap-content" justifyContent='center' gap='50px'>
                    <AdminCard 
                        imgSrc={ supUrl + "4no.png"} 
                        borderFile="notes.png" 
                        label={text.notes} 
                        fields={[{label: text.unReç , value: stat?.Note.receive} , {label: text.sent , value: stat?.Note.send}]} 
                        onDetails={() => seeDetails(4)}
                    />
                    <AdminCard 
                        imgSrc={ supUrl + "5tea.png"} 
                        borderFile="teasermess.png" 
                        label={text.teasermessages} 
                        fields={[{label: text.out , value: stat?.teaser.sortir}, {label: text.in , value: stat?.teaser.entrer}]} 
                        onDetails={() => seeDetails(5)}
                    />
                    <AdminCard 
                        imgSrc={ supUrl + "6pok.png"} 
                        borderFile="pokemess.png" 
                        label={text.pokemessages} 
                        fields={[{label: text.total , value: stat?.Poke.total}]} 
                        onDetails={() => seeDetails(6)}
                    />
                </Box>
                <Box className="wrap-content" justifyContent='center' gap='50px'>
                    <AdminCard 
                        imgSrc={supUrl + "7sto.png"} 
                        borderFile="stopmess.png" 
                        label={text.stopmessages} 
                        fields={[{label: text.total , value: stat?.stop.total}]} 
                        onDetails={() => seeDetails(7)}
                    />
                    <AdminCard 
                        imgSrc={supUrl + "8pho.png"} 
                        borderFile="photosreq.png" 
                        label={text.photosrequests} 
                        fields={[{label: text.requested , value: stat?.photo.demander}, {label: text.sent , value: stat?.photo.envoyer}]}
                        onDetails={() => seeDetails(8)}
                    />
                    <AdminCard 
                        imgSrc={supUrl + "9re.png"} 
                        borderFile="report.png" label={text.report} 
                        fields={[{label: text.unNouv1 , value: stat?.Report.nouveau}, {label: text.total , value: stat?.Report.total}]} 
                        onDetails={() => seeDetails(9)}
                    />
                </Box>
                <Box className="wrap-content" justifyContent='center' gap='50px'>
                    <AdminCard 
                        isKid = "true"
                        type = "logbook"
                        imgSrc={supUrl + "10lo.png"} 
                        borderFile="logbook.png"  
                        label={text.logbook} 
                        fields={[
                            {label: text.rvr , value: stat?.LogBook.meeting}, 
                            {label: text.prmnnt , value: stat?.LogBook.permanent},
                            {label: text.ntprmnnt , value: stat?.LogBook.not_permanent}, 
                            {label: text.esqv , value: stat?.LogBook.esquive},
                        ]}
                        onDetails={() => seeDetails(10)}
                    />
                    <AdminCard 
                        imgSrc={supUrl + "11du.png"} 
                        borderFile="duplmess.png" 
                        label={text.duplicatedmessages} 
                        fields={[{label: text.total , value: ''}]} 
                        onDetails={() => seeDetails(11)}
                    />
                    <AdminCard 
                        imgSrc={supUrl + "12gi.png"} 
                        borderFile="gifs.png" 
                        label={'Gifts et Gifs'}
                        fields={[{label: 'Gifts' , value: stat?.Gif.totalGifts}, {label: 'Gifs' , value: stat?.Gif.total}]}
                        onDetails={() => seeDetails(12)}
                    />
                </Box>
                <Box className="wrap-content" justifyContent='center' gap='50px'>
                    <AdminCard 
                        type = "controle"
                        imgSrc="/images/admin/supimg.png" 
                        label={text.suptraffman} 
                        fields={[{label: text.allou , value: '0'}, {label: text.Nallou , value: '0'}]}
                        borderFile="sup.png" view 
                        onDetails={() => seeDetails(13)}
                    />
                    <AdminCard 
                        imgSrc="/images/qc/4.png" 
                        type = "controle"
                        label={text.qctrl} 
                        fields={[
                            {label: text.totSoum , value: stat?.qc.total}, 
                            {label: text.Soum , value: stat?.qc.treated},
                            {label: text.nonSoum , value: stat?.qc.total - stat?.qc.treated}
                        ]}
                        borderFile="qc.png" 
                        view onDetails={() => seeDetails(14)}
                    />
                    <AdminCard 
                        isKid = "true"
                        type = "compte"
                        imgSrc="/images/accountant/4.png" 
                        label={text.acc} 
                        fields={[
                            {label: text.acc2 , value: '0'}, 
                            {label: text.acc3 , value: '0'},
                            {label: text.acc4 , value: '0'},
                            {label: text.acc5 , value: '0'},
                            {label: text.payTot , value: '0'}
                        ]}
                        borderFile="acc.png" view 
                        onDetails={() => seeDetails(15)}
                    />
                </Box>
            </Stack>
        </Stack>
    )
}

export default ChattoolOverview