import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import useLanguage from './hooks/useLanguage';

const slideAnimation = keyframes`
  0% { transform: translateX(0); }
  50% { transform: translateX(10px); }
  100% { transform: translateX(0); }
`;

const dotsAnimation = keyframes`
  0% { content: ''; }
  33% { content: '.'; }
  66% { content: '..'; }
  100% { content: '...'; }
`;

const Emoji = styled('span')({
  fontSize: '32px',
  animation: `${slideAnimation} 1s ease-in-out infinite`,
});

const Dots = styled('span')(({ theme }) => ({
  '&::after': {
    content: "''",
    animation: `${dotsAnimation} 1.0s steps(3, end) infinite`,
  },
}));

const Loading = () => {
  const { text } = useLanguage()

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f5f5f5',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Emoji>
          <img 
            src="/images/HeaderImage/logo.png" 
            alt="logo" 
            style={{
              width: "70px"
            }}
          />
        </Emoji>
        <Box>
          <Typography
            variant="h3"
            sx={{
              fontFamily: 'Arial, sans-serif',
              color: '#6A0DAD',
              fontWeight: 800,
            }}
          >
            TextModing
          </Typography>
          <Typography
            variant="h3"
            sx={{
              fontFamily: 'Arial, sans-serif',
              color: '#333',
              fontWeight: 700,
            }}
          >
            {text.phload.slice(0, -3)} <Dots />
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Loading;