import { Box, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import Language from '../Language/Language';
import useLanguage from '../hooks/useLanguage';
import axiosInstance from '../../axios';
import BallotIcon from '@mui/icons-material/Ballot';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { Link, useNavigate } from 'react-router-dom';
import CardProfil from './CardProfil';
import CustomAlert from '../Admin/CustomAlert';
import PokeDisplayMessage from './PokeDisplayMessage';
import Pusher from 'pusher-js'
import { CLUSTER, PUSHER_SECRET, apiURL, baseURL } from "../../helper/conf";
import SwitchFeature from './SwitchFeature';
import Logout from './Logout';
import { AuthStateContext } from '../state/AuthStateContext';

const Poke = () => {
    const { text } = useLanguage();
    const [ profils, setProfils ] = useState()
    const [ border, setBorder ] = useState()
    const [ vraiId, setVraiId ] = useState()
    const [ fauxId, setFauxId ] = useState()
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const endDate = localStorage.getItem('endDate');
    const userId = localStorage.getItem('userId');
    const [ok, setOk] = useState()
    const [popContenu, setPopContenu] = useState()
    const [ loading, setLoading ] = useState(false)
    const [ alerto, setAlerto ] = useState(false)
    const [ error, setError ] = useState()
    const [ displayError, setDisplayError ] = useState()

    useEffect(()=>{
        if(!token){
            navigate('/login');
        }else{
            axiosInstance.post('/interface/token',{
                "token" : token,
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            })
            .then((response) => {
                // console.log(response)
                if(response.data.success != true){
                    navigate('/login');
                }
                setOk(response.data.success)
            })
            .catch((error) => {
                // console.log(error)
                navigate('/login');
            })
        }
      }, [])
  
    // GET PROFILS
    useEffect(() => {
      axiosInstance.get('/profil', {
        headers: {
            'Authorization': `Bearer ${token}`,
        },
      })
      .then((response) => {
        // console.log(response.data)
        setProfils(response.data)
      })
      .catch((error) => {
        //   console.log(error)
      })
    }, [])

    const [activeIds, setActiveIds] = useState([]);
    const toggleActive = (id) => {
        setActiveIds((prevActiveIds) => {
            if (prevActiveIds.includes(id)) {
                return prevActiveIds.filter((activeId) => activeId !== id);
            } else {
                return [...prevActiveIds, id];
            }
        });
    };


    // GET ALL POKE
    const [ poke, setPoke ] = useState([])
    useEffect(() => {
        axiosInstance.get('/poke', {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
        .then((response) => {
        //   console.log(response.data.data)
          setPoke(response.data.data)
        })
        .catch((error) => {
            // console.log(error)
        })
    }, [])


    // SEND POKE
    const handlePoke = () => {
        setError()
        if(activeIds.length == 0){
            return setError(text.pokeErr)
        }else{
            setLoading(true)
            axiosInstance.post('/pokemessage/send', {
                "vpIds" : activeIds,
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            })
            .then((response) => {
                // console.log(response)
                setPoke((prev) => [...prev, ...response.data.data])
                setActiveIds([])
                setPopContenu(text.notifPoke)
                setAlerto(true)
                setLoading(false)
            })
            .catch((error) => {
                // console.log(error)
                setError(error?.response?.data?.message || 'An error occurred')
                setLoading(false)
            })
        }
    }

    // PUSHER RECEIVE REPONSE POKE
    useEffect(()=>{
        const pusher = new Pusher(PUSHER_SECRET,{cluster:CLUSTER,channelAuthorization:{endpoint:`${apiURL}/pusher/user-auth`,transport:'jsonp'}})
        const channel = pusher.subscribe("private-poke")
        channel.bind(`add-${userId}`, (data) => {
            // console.log(data)
            setPoke((prev) => prev.some((d) => d.id === data.id) ? 
                prev.map((d) => (d.id === data.id ? data : d))
                : 
                [...prev, data]
            );
        })
        return()=>{
            channel.unbind()
            pusher.unsubscribe("private-poke")
        }
    },[])


if(ok){
  return (
    <div style={{ maxWidth: "100%", minHeight: "100vh", background: "black", color: "white", paddingBottom: "60px"}} >
        <CustomAlert
            open={alerto}
            autoHideDuration={1500}
            onClose={() => setAlerto(false)}
            severity="success"
            message={popContenu}
        />
        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid gray"}}>
            <Stack maxWidth={50} sx={{ padding: "20px"}}>
                <Link to="/home" >
                  <img src="/images/HeaderImage/logo.png" alt="" height='50px'/>
                </Link>
            </Stack>
            <Typography variant='h4' style={{fontWeight: 'bold', color: "white"}}>
                {text.inter}
            </Typography>
            <Stack maxWidth={50} sx={{ padding: "20px", flexDirection: "row", alignItems: "center", gap: "10px"}}>
                <SwitchFeature />
                <Logout />
            </Stack>
            <Stack maxWidth={50} sx={{ padding: "20px"}}>
                <Language redirectRoute={'/pokemessage'} />
            </Stack>
        </div>
        <div style={{ width: "100%", minHeight: "89vh", display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "40px"}}>
            <div style={{width: "90%"}}>
                <div style={{ marginBottom: "20px", color: "white"}}>
                    <div style={{ marginBottom: "40px"}}>
                        <Typography variant='h2' style={{fontWeight: 'bold', marginBottom: '2px', color: 'orange'}}>
                            {text.biev},
                        </Typography>
                        <Typography variant='h3' style={{marginBottom: '2px', color: '#dddddd'}}>
                            {text.sendInt} {text.tit3}
                        </Typography>
                    </div>
                    <div style={{ marginBottom: "30px"}}>
                        <Stack sx={{marginBottom: '2px', flexDirection: "row", alignItems: "center", gap: "4px"}}>
                            <BallotIcon sx={{ color: 'orange', fontSize: '32px' }} />
                            <Typography variant='h4' style={{fontWeight: 'bold'}}>
                                {text.descri} :
                            </Typography>
                        </Stack>
                        <Typography variant='p' style={{marginBottom: '2px', color: '#dddddd', textAlign: 'justify'}}>
                            {text.pokedes}
                        </Typography>
                    </div>
                    <div style={{ marginBottom: "30px"}}>
                        <Stack sx={{flexDirection: "row", alignItems: "center", gap: "4px"}}>
                            <ManageAccountsIcon sx={{ color: 'orange', fontSize: '32px' }} />
                            <Typography variant='h4' style={{fontWeight: 'bold'}}>
                                {text.instTest} :
                            </Typography>
                        </Stack>
                        <Stack sx={{ color: '#dddddd' }}>
                            <ul style={{ fontSize: "18px"}}>
                                <li>
                                    {text.pokedes1}
                                </li>
                                <li>
                                    {text.pokedes2}
                                </li>
                                <li>
                                    {text.pokedes3}
                                </li>
                            </ul>
                        </Stack>
                    </div>
                </div>
                <div>
                    <Typography variant='h4' style={{fontWeight: 'bold', marginBottom: "20px"}}>
                        {text.unPlP} :
                    </Typography>
                    <div style={{marginBottom: '4px', width: "100%"}}>
                        {/* liste de realProfil */}
                        <div style={{width: "100%", marginBottom: '40px'}}>
                            <Stack sx={{flexDirection: "row", alignItems: "center", gap: "4px", marginBottom: "8px"}}>
                                <SupervisedUserCircleIcon sx={{ color: 'orange', fontSize: '27px' }} />
                                <Typography variant='h5' sx={{color: "orange"}}>
                                    {text.chxF}:
                                </Typography>
                            </Stack>
                            <div className='scroll' style={{ height: '500px', border: '2px solid #ccc', borderRadius: '8px', padding: '10px' }}>
                                <Grid container spacing={1}>
                                {profils?.realProfil.map((p) => {
                                    const isActive = activeIds.includes(p.id);
                                    return (
                                        <Grid
                                            key={p.id}
                                            item
                                            sm={6}
                                            lg={3}
                                            sx={{ cursor: 'pointer' }}
                                            onClick={p.bani ? ()=>{} : p.suspendu ? ()=>{} : () => toggleActive(p.id)}
                                        >
                                            <CardProfil profil={p} active={isActive} isVrai={true} />
                                        </Grid>
                                    );
                                })}
                                </Grid>
                            </div>
                        </div>
                    </div>

                    {/* affichage de poke */}
                    <PokeDisplayMessage data={poke} type="poke" />

                    { error && 
                        <Typography variant='p' sx={{ fontWeight: "500", color: "red", marginTop: "2px"}}>
                            {error}
                        </Typography>
                    }
                    <div style={{ marginTop: "2px", display: "flex", justifyContent: "end"}}>
                        <button 
                            onClick={handlePoke}
                            style={{ border: 'none', outline: 'none', padding: '20px 20px', fontSize: '20px', fontWeight: 'bold', cursor: 'pointer', borderRadius: '8px', background: "orange"}}
                        >
                            {loading? text.phload : text.phsend}
                        </button>
                    </div>
                </div> 
            </div>
        </div>
    </div>
  )}else{
    return(
        <div style={{ maxWidth: "100%", height: "100vh", background: "black" }} >
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
            >
                <CircularProgress />
            </Box>
        </div>
    )
  }
}


export default Poke