import React, { createContext, useEffect, useState } from 'react';
import axiosInstance from '../../axios';

const AuthStateContext = createContext(false);

const AuthStateProvider = ({children}) => {
    const [state, setState] = useState(() => {
        const userData = localStorage.getItem('userData');
        return userData ? JSON.parse(userData) : {};
    });
    
    const setAuthenticated = (login, userId, userName, userRole, token, languageName, accessareas) => {
        if (login) {
            const userData = { login, userId, userName, userRole, token, languageName, accessareas }
            setState(userData)
            localStorage.setItem('userData', JSON.stringify(userData));
        } else {
            setState({});
            localStorage.removeItem('userData');
        }
    };

    useEffect(() => {
        const checkTokenValidity = async () => {
            const token = state?.token;
            if (token) {
                try {
                    await axiosInstance.get('/interface/token', {
                        headers: { Authorization: `Bearer ${token}` }
                    });
                } catch (error) {
                    setAuthenticated(false);
                }
            }
        };
        checkTokenValidity();
    }, [state]);

    return (
        <AuthStateContext.Provider value={{
            authState: state,
            setAuthenticated,
        }}>
            {children}
        </AuthStateContext.Provider>
    )
}
export {AuthStateContext, AuthStateProvider}