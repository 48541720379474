import React, { useCallback, useContext, useEffect, useState } from 'react'
import { AuthStateContext } from '../state/AuthStateContext';
import { MessageContext } from '../state/MessageContext';
import useLanguage from '../hooks/useLanguage';
import axiosInstance from '../../axios';
import { Badge, Menu, Stack, Typography } from '@mui/material';
import incoming from '../../assets/audio/notification1.mp3'
import { NotifBox } from '../tools/Snackbars';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Pusher from 'pusher-js';
import { apiURL, CLUSTER, PUSHER_SECRET } from '../../helper/conf';

const NotificationHeader = ({setValue=null}) => {
    const [notif, setNotifs] = useState({ data: [], nbrChecked: 0 })
    const [notifEl, setNotifEl] = useState(null)
    const { text } = useLanguage();
    const { authState: { token, userId } } = useContext(AuthStateContext)
    const { CustomScrollList, chan1 } = useContext(MessageContext)
    const notifOpen = Boolean(notifEl)

    useEffect(() => {
        axiosInstance.get('/Notifications/get', { 
            headers: { 'Authorization': `Bearer ${token}` } 
        }).then(res => {
            if (res.data.data.length) {
                setNotifs(res.data)
            }
        }).catch(e => {

        })
    },[])

    useEffect(() => {
        const pusher = new Pusher(PUSHER_SECRET,{
            cluster: CLUSTER,
            channelAuthorization: {
                endpoint: `${apiURL}/pusher/user-auth`,
                transport: 'jsonp'
            }
        })
        const channel = pusher.subscribe(`private-${userId}`)
        if(!notif.data.length){
            channel.bind('notification', data => {
                const audio = new Audio(incoming)
                audio.play().catch(error => {
                    console.error('Playback failed');
                })
                setNotifs(p => ({data:[...p.data,data],nbrChecked:p.nbrChecked+1}))
            })
            return ()=> {
                channel.unbind()
                pusher.unsubscribe(`private-${userId}`)
            }
        }
    }, [])

    const notifSuppr = useCallback((p, i) => {
        axiosInstance.put(`/Notifications/Delete/${p.id}`, {}, {
            headers: { 'Authorization': `Bearer ${token}` } 
        }).then(() =>
            setNotifs(k => ({ data: k.data.filter((_, j) => i !== j), nbrChecked: !p.checked ? k.nbrChecked - 1 : k.nbrChecked } ))
        ).catch(e => {})
    }, [])

    const notifClick = useCallback((n, d) => {
        setNotifs(p => ({ data: p.data.map((k, i) => i === n ? { ...k, checked: true } : k), nbrChecked: p.nbrChecked - 1 }))
        axiosInstance.put(`/Notifications/check/${d.id}`,{
            "test": "teste"
        }, {
            headers: { 'Authorization': `Bearer ${token}` } 
        })
        .then((response) => {
            // console.log(response)
        })
        .catch((e) => {
            // console.log(e)
        })
    }, [])

    return (
        <>
            <Stack className="usericon" style={{ cursor: 'pointer' }} onClick={(e) => setNotifEl(notifEl ? null : e.currentTarget)}>
                <Badge color='info' badgeContent={notif.data.length ? notif.nbrChecked : 0}>
                    <NotificationsIcon style={{ color: '#ffbf4a', fontSize: '2.5rem' }} />
                </Badge>
            </Stack>
            <Menu
                anchorEl={notifEl}
                open={notifOpen}
                onClose={() => setNotifEl(null)}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                slotProps={{ paper: { style: { backgroundColor: "#f7ead2", boxShadow: "none", color: '#616161', zIndex: 20 } } }}>
                <Typography sx={{ fontWeight: 'bold', fontSize: '1.3rem', paddingX: '5px' }}>{text.notifs}</Typography>
                {!notif.data.length ? <Typography sx={{ paddingX: '1.5em' }}>{text.nonotif}</Typography> :
                    <CustomScrollList sx={{ minWidth: 450, maxWidth: 500, maxHeight: 180, width: '100%' }}>
                        {notif.data.sort((a, b) => b.id - a.id).map((p, i) => <NotifBox key={i} i={i} p={p} notifSuppr={notifSuppr} notifClick={notifClick} openComp={setValue ? setValue : null} />)}
                    </CustomScrollList>
                }
            </Menu>
        </>
    )
}

export default NotificationHeader