import React, { useContext, useRef, useState } from "react";
import { Stack, Dialog, DialogContent, Typography, Box, RadioGroup, Radio, OutlinedInput as MuiInput, Input, Button, Icon, IconButton, CircularProgress, Select, MenuItem } from "@mui/material";
import useLanguage from "../hooks/useLanguage";
import { Send, AttachFile } from "@mui/icons-material";
import axiosInstance from "../../axios";
import countriesList from "../../helper/countries";
import { ContactContext } from "./Main";
import { Link } from "react-router-dom";
import RoutesList from "../../helper/routes";

const stackInputSx = { backgroundColor: "#fff", borderRadius: "25px", width: {xs: "100%", md: "48%"}, padding: "0 10px", flexDirection: "row", alignItems: "center" }
const inputSx = { backgroundColor: "#fff", borderRadius: "25px", width: "100%", padding: "0 10px", height: "fit-content", "& .MuiOutlinedInput-notchedOutline": { display: "none" }, "& .MuiOutlinedInput-input": { padding: "10px 0" } }
const containerSx = { flexDirection: { xs: "column", md: "row" }, justifyContent: "space-between" }
const innerSx = { width: { xs: "100%", md: "49%" } }

const Contacts = () => {
    const { contactShow, setContactShow } = useContext(ContactContext)
    const { text, currentLanguage } = useLanguage()
    const [ feedback, setFeedback ] = useState(null)
    const defaultData = {title: "", society: "", website: "", address: "", provincedepart: "", postalcode: "", country: "", firstname: "", lastname: "", phone: "", mail: "", object: null, message: "", attachment: null }
    const [ data, setData ] = useState(defaultData)
    const [ otherObjects, setOtherObjects ] = useState("")
    const fileInput = useRef(null)

    const getTitle = () => {
        if (["nl", "de"].includes(currentLanguage)) return [ text.mr, text.mme ]
        return [ text.mr, text.mme, text.mlle ]
    }

    const objects = [ text.dmdpht, text.prtnrt, text.affltn, text.prsrdv, text.dmdbrchr ]

    const set = (name, value) => setData(d => ({...d, [name]: value}))
    
    const Label = ({children}) => {
        return <Typography fontWeight="bold" whiteSpace="nowrap" marginRight="5px">{children} :</Typography>
    }

    const formData = data => {
        const formData = new FormData()
        for (const key in data) if (data[key]){
            const value = data[key]
            if (Array.isArray(value))
                value.forEach(item => {
                    if (typeof item === 'object') Object.keys(item).forEach(subKey => formData.append(subKey, item[subKey]))
                    else formData.append(key, item)      
                })
            else formData.append(key, value)
        }
        return formData
    }

    const getDataObject = () => {
        if (data.object === "other") return otherObjects
        if (!data.object) return ""
        return data.object
    }

    const handleSubmit = async () => {
        const d = {...data, object: getDataObject() }
        setFeedback("loading")
        try {
            const { status } = await axiosInstance.post("/contact", formData(d),{
                headers: { 'Content-Type': 'multipart/form-data'},
                transformRequest: null
            })

            if (status === 200) {
                setFeedback("success")
                setData(defaultData)
            }
        } catch (error) {
            setFeedback("error")
        }
    }

    return <Dialog
        maxWidth="md"
        fullWidth
        open={contactShow}
        onClose={() => setContactShow(false)}
    >
        <DialogContent
            sx={{ backgroundColor: "#f6e7de" }}
        >
            <Stack padding="10px" spacing={4}>
                <Stack flexDirection="row" alignItems="center" justifyContent="space-evenly">
                    <Typography variant="h1" textAlign="center" fontWeight="bold" color="#fff" sx={{ WebkitTextStroke: "2px #d48b70", fontFamily: "PlayFairDisplay", zIndex: 2, paddingRight: "25px", textTransform: "uppercase" }}>{text.frmlrdcntct}</Typography>
                    <Stack flexDirection="row" gap="10px">
                        <Box component="img" src="/images/landing/logo.png" sx={{ height: "50px", width: "50px" }}/>
                        <Stack width="max-content">
                            <Typography color="#d58f78" textTransform="none" fontWeight="bold">TextModing</Typography>
                            <Typography color="#fff" variant="h4" sx={{ textShadow: "0px 0px 4px #ff4400", fontFamily: "Gladiola", fontStyle: "italic", fontWeight: "bold" }}>{text.intrcteffcctprfrmnc}</Typography>
                        </Stack>
                    </Stack>
                </Stack>

                <Stack spacing={2}>
                    <Stack sx={stackInputSx}>
                        <Label>{text.ttr}</Label>
                        <RadioGroup row name="title" sx={{ gap: "25px" }}>
                            {
                                getTitle()
                                .map(t => <Stack key={t} flexDirection="row" alignItems="center">
                                    <Radio value={t} onClick={() => set("title", t)}/>
                                    <Typography>{t}</Typography>
                                </Stack>)
                            }
                        </RadioGroup>
                    </Stack>

                    <Stack sx={containerSx}>
                        <Stack sx={innerSx}>
                            <MuiInput
                                name="society" sx={inputSx} value={data.society}
                                startAdornment={<Label>{text.sct}</Label>}
                                onChange={({target: { value }}) => set("society", value)}
                                autoComplete="off"
                            />
                        </Stack>
                        <Stack sx={innerSx}>
                            <MuiInput
                                name="website" sx={inputSx} value={data.website}
                                startAdornment={<Label>{text.stwb}</Label>}
                                onChange={({target: { value }}) => set("website", value)}
                                autoComplete="off"
                            />
                        </Stack>
                    </Stack>
                    <MuiInput
                        name="address" sx={inputSx} value={data.address}
                        startAdornment={<Label>{text.addrss}</Label>}
                        multiline
                        minRows={2}
                        onChange={({target: { value }}) => set("address", value)}
                                autoComplete="off"
                    />
                    <Stack sx={containerSx}>
                        <Stack sx={innerSx}>
                            <MuiInput
                                name="provincedepart" sx={inputSx} value={data.provincedepart}
                                startAdornment={<Label>{text.prvncdprtm}</Label>}
                                onChange={({target: { value }}) => set("provincedepart", value)}
                                autoComplete="off"
                            />
                        </Stack>
                        <Stack sx={innerSx}>
                            <MuiInput
                                name="postalcode" sx={inputSx} value={data.postalcode}
                                startAdornment={<Label>{text.cdpstl}</Label>}
                                onChange={({target: { value }}) => set("postalcode", value)}
                                autoComplete="off"
                            />
                        </Stack>
                    </Stack>
                    <Stack sx={innerSx}>
                        <Select
                            sx={inputSx}
                            name="country"
                            value={data.country}
                            startAdornment={<Label>{text.cntr}</Label>}
                            onChange={({target: { value }}) => set("country", value)}
                        >
                            {
                                countriesList
                                .map(country => <MenuItem key={country.value} value={country.innerHTML}>{country.innerHTML}</MenuItem>)
                            }
                        </Select>
                    </Stack>
                    <Stack sx={containerSx}>
                        <Stack sx={innerSx}>
                            <MuiInput
                                name="firstname" sx={inputSx} value={data.firstname}
                                startAdornment={<Label>{text.prnmcntct}</Label>}
                                onChange={({target: { value }}) => set("firstname", value)}
                                autoComplete="off"
                            />
                        </Stack>
                        <Stack sx={innerSx}>
                            <MuiInput
                                name="lastname" sx={inputSx} value={data.lastname}
                                startAdornment={<Label>{text.nmcntct}</Label>}
                                onChange={({target: { value }}) => set("lastname", value)}
                                autoComplete="off"
                            />
                        </Stack>
                    </Stack>
                    <Stack sx={containerSx}>
                        <Stack sx={innerSx}>
                            <MuiInput
                                name="phone" sx={inputSx} value={data.phone}
                                startAdornment={<Label>{text.tlphn}</Label>}
                                onChange={({target: { value }}) => set("phone", value)}
                                autoComplete="off"
                            />
                        </Stack>
                        <Stack sx={innerSx}>
                            <MuiInput
                                name="mail" sx={inputSx} value={data.mail}
                                startAdornment={<Label>{text.eml}</Label>}
                                onChange={({target: { value }}) => set("mail", value)}
                                autoComplete="off"
                            />
                        </Stack>
                    </Stack>

                    <Stack sx={{...stackInputSx, flexDirection: "column", alignItems: "flex-start", padding: "10px"}}>
                        <Label>{text.objdvtrcntct}</Label>
                        <RadioGroup
                            name="object"
                            value={data.object}
                            onChange={e => set("object", e.target.value)}
                        >
                            {
                                objects
                                .map(t => <Stack key={t} flexDirection="row" alignItems="center">
                                    <Radio value={t}/>
                                    <Typography>{t}</Typography>
                                </Stack>)
                            }
                            <Stack flexDirection="row" alignItems="center">
                                <Radio value="other" onClick={() => set("object", "other")}/>
                                <Typography marginRight="5px">{text.othr}</Typography>
                                <Input onChange={e => setOtherObjects(e.target.value)}/>
                            </Stack>
                        </RadioGroup>
                    </Stack>

                    <Stack sx={{...innerSx, width: "100%"}}>
                        <MuiInput
                            name="message" sx={inputSx} value={data.message}
                            multiline minRows={3}
                            startAdornment={<Label>{text.vtrmsg}</Label>}
                            onChange={({target: { value }}) => set("message", value)}
                            autoComplete="off"
                        />
                    </Stack>

                    <Stack sx={{...innerSx}}>
                        <MuiInput
                            name="attachment" sx={inputSx} value={data.attachment?.name}
                            startAdornment={<Label>{text.attchmt}</Label>}
                            autoComplete="off"
                            endAdornment={<IconButton onClick={()=> fileInput.current.click()} sx={{ transform: "rotate(40deg)" }}><AttachFile/></IconButton>}
                        />
                        <input style={{display: 'none'}} type="file" 
                            onChange={e =>{
                                if (e.target.files.length) set("attachment", e.target.files[0])  
                                else set("attachment", null)   
                            }}
                            ref={fileInput}
                        />        
                    </Stack>

                    <Stack flexDirection="row" alignItems="center">
                        <Radio/>
                        <Typography fontWeight="bold">{text.pltqcnfdtxt} <Link to={RoutesList.pplanding} className="link1" target="blank">{text.pltqcnfdtxt2}</Link></Typography>
                    </Stack>

                    <Stack alignItems="center" spacing={1}>
                        {
                            (feedback === "success") ? <Typography color="#0b0" fontWeight="bold">{text.sccss}</Typography>
                            : ((feedback === "error") ? <Typography color="#b00" fontWeight="bold">{text.smthngwntwrng}</Typography> : <></>)
                        }
                        <Button onClick={handleSubmit} sx={{ background: "#d48b70", color: "#fff", borderRadius: "20px", display: "flex", alignItems: "center", justifyContent: "space-between", gap: "15px", "&:hover": { background: "#d48b70" } }}>
                            {
                                (feedback === "loading") ? <CircularProgress sx={{ color: "#fff" }} size="30px"/>
                                : <>
                                    <Icon sx={{ background: "#fff", display: "flex", alignItems: "center", aspectRatio: "1/1", justifyContent: "center", borderRadius: "50%", color: "#d6801c", height: "fit-content", transform: "rotate(-40deg)" }}><Send sx={{ height: "20px" }}/></Icon>
                                    <Typography sx={{fontWeight: "bold", textTransform: "none"}}>{text.snd}</Typography>
                                    <Stack/>
                                </> 
                            }
                        </Button>
                    </Stack>
                </Stack>

            </Stack>
        </DialogContent>
    </Dialog>
}

export default Contacts