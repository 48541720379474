import React, { useContext, useEffect, useState } from "react";
import { Stack, Typography, Grid, TextField, Modal, Button, Box} from "@mui/material";
import useLanguage from '../hooks/useLanguage';
import OtherInfo from "./OtherInfo/OtherInfo";
import CustomAlert from "../Admin/CustomAlert";
import { AuthStateContext } from "../state/AuthStateContext";
import axiosInstance from "../../axios";
import { MessageContext } from "../state/MessageContext";
import LockIcon from '@mui/icons-material/Lock';
import CloseIcon from '@mui/icons-material/Close';

const Profile = ({user, type, info, meet}) => {
    const { text } = useLanguage()
    const { authState } = useContext(AuthStateContext)
    const {messageState} = useContext(MessageContext)
    const [isEditing, setIsEditing] = useState();
    const [ loading, setLoading ] = useState()
    const [ alert, setAlert ] = useState()
    const [ alertContenu, setAlertContenu ] = useState()
    const [ newValue, setNewValue ] = useState("")
    const [ champ, setChamp ] = useState()
    const [isVp, setIsVp] = useState()
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        if (type === "fake_profil") {
            setIsVp(0)
        } else {
            setIsVp(1)
        }
    }, [type]);

    const handleDoubleClick = (champ) => {
        setIsEditing(champ);
        setChamp(champ);
    };

    const handleBlur = () => {
        setIsEditing(false);
        setNewValue("")
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            setLoading(true)
            axiosInstance.post("/main_information/update", {
                "field": champ,
                "value": newValue,
                "convId": messageState?.convId,
                "isVpInformation": isVp,
            },{
                headers: {
                    'Authorization': `Bearer ${authState.token}`
                }
            })
            .then((response)=>{
                // console.log(response.data)
                user[champ] = newValue
                setAlertContenu(text.updtdsucc)
                setAlert(true)
                setLoading(false)
                setIsEditing(false);
                setNewValue("");
            })
            .catch((error)=>{
                // console.log(error)
                setLoading(false)
            })
        }
    };
    
    return (
        <Stack variant="profil" spacing={2} padding="20px 0" width="100%" >
            <CustomAlert
                open={alert}
                autoHideDuration={1500}
                onClose={() => setAlert(false)}
                severity="success"
                message={alertContenu}
            />
            <Stack width="100%" height="50px" justifyContent="center" alignItems="center" style={{backgroundColor: user ? "#d48b70" : "#ffa781"}}>
                <Typography variant="whitetitle" >{ type === 'real_profil' ? text.userprofil : text.botprofil }</Typography>
            </Stack>
            <Grid container spacing={1}>
                <Grid item xs={4} display="flex" justifyContent="center">
                    <Stack className="profilebox" >
                        <img src={user.pdp} alt="" style={{width: "100%", height: "100%", borderRadius: "50px"}}/>
                        <div style={{ marginTop: "5px", alignSelf: "center"}}>
                            <Button
                                variant="contained" 
                                color="primary" 
                                onClick={handleOpen}
                                sx={{
                                    color: "#ffffff",       
                                    fontWeight: "bold",    
                                    textTransform: "none", 
                                    padding: { xs: "5px 10px", sm: "8px 16px" }, 
                                    fontSize: { xs: "0.8rem", sm: "1rem" },       
                                    minWidth: { xs: "80px", sm: "100px" }        
                                }}
                            >
                                {text.photogallerie}
                            </Button>
                        </div>
                    </Stack>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-gallery-title"
                        aria-describedby="modal-gallery-description"
                    >
                        <Box 
                            sx={{
                                position: 'absolute', top: '50%', left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: { xs: "90%", md: "80%" },
                                maxHeight: "80vh",
                                bgcolor: 'background.paper',
                                boxShadow: 24, p: 4, overflow: 'auto'
                            }}
                        >
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
                                <CloseIcon
                                    onClick={handleClose} 
                                    style={{
                                        cursor: 'pointer',
                                        color: '#000', 
                                        fontSize: '1.5rem',
                                    }} 
                                />
                            </div>
                            <Grid container spacing={2}>
                                {user?.gallerie.length == 0 ? 
                                    <Stack width="100%" height="50px" justifyContent="center" alignItems="center">
                                        <Typography variant="blacktitle">{text.nophoto}</Typography>
                                    </Stack>
                                    :
                                    user?.gallerie.map((image) => (
                                        <Grid item xs={6} sm={4} md={3} key={image.id}>
                                            <div style={{ position: "relative", paddingBottom: "100%", overflow: "hidden" }}>
                                                <img 
                                                    src={image.photo_url} 
                                                    alt={`photo-${image.id}`} 
                                                    style={{
                                                        position: "absolute",
                                                        top: 0,
                                                        left: 0,
                                                        width: "100%", 
                                                        height: "100%", 
                                                        objectFit: "cover",
                                                        borderRadius: "8px",
                                                        filter: image.private === 1 ? "blur(8px)" : "none"
                                                    }}
                                                />

                                                {image.private === 1 && (
                                                    <LockIcon 
                                                        style={{
                                                            position: "absolute", 
                                                            top: "50%", 
                                                            left: "50%", 
                                                            transform: "translate(-50%, -50%)", 
                                                            color: "#ffffff", 
                                                            fontSize: "2rem"
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </Box>
                    </Modal>
                </Grid>
                <Grid item xs={8}>
                    <Stack display="flex" flexDirection="row" gap={1}>
                        <Stack><Typography variant="blacktitle">{text.usrnm}: {user?.user_name} </Typography></Stack>
                        {/* {user?.status === "connecter" ? 
                            <Stack display="flex" alignSelf="flex-end" style={{width: "10px", height: "10px", borderRadius: "10px" , backgroundColor: "#1cb743"}}></Stack> 
                            : 
                            <Stack display="flex" alignSelf="flex-end" style={{width: "10px", height: "10px", borderRadius: "10px" , backgroundColor: "#666666"}}></Stack> 
                        } */}
                    </Stack>
                    <Stack display="flex" flexDirection="column">
                        { user?.abonnement ? <Typography variant="pfsbold">{text.suty}: {user?.abonnement}</Typography> : <></>}
                        <Typography variant="pfsbold">{text.ag}: {user?.age}</Typography>
                        <Typography variant="pfsbold">{text.gend}: {user?.sex}</Typography>
                        <Typography variant="pfsbold">{text.sexor}: {user?.sex_orientation}</Typography>
                        <Typography variant="pfsbold">{text.situation}: {user?.marital_status}</Typography>
                        <Typography variant="pfsbold">{text.pays}: {user?.city}</Typography>
                        <Typography variant="pfsbold">{text.tow}: {user?.country}</Typography>
                        { user ? <></> : <Typography variant="white">{user?.subscription}</Typography>}
                    </Stack>
                    
                </Grid>
            </Grid>

            {/* Other info */}
            <OtherInfo type={type} info={info} meet={meet} />

            <Stack spacing={1}>
                <Grid container>
                    <Grid item xs={4} variant="profcontitem1"><Typography variant="pfsbold" textAlign= 'center'>{text.real}</Typography></Grid>
                    <Grid item xs={8} variant="profcontitem2" pl={1} onDoubleClick={() => handleDoubleClick("real_name")} >
                        {isEditing == "real_name" ? (
                            <TextField
                                value={newValue}
                                onChange={(e)=>setNewValue(e.target.value)}
                                onBlur={handleBlur}
                                onKeyPress={handleKeyPress}
                                autoFocus
                            />
                        ) : (
                            <Grid item xs={8} variant="profcontitem2" pl={1}>
                                {user?.real_name}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={4} variant="profcontitem1"><Typography variant="pfsbold" textAlign= 'center'>{text.codepost}</Typography></Grid>
                    <Grid item xs={8} variant="profcontitem2" pl={1} onDoubleClick={() => handleDoubleClick("postal_code")} >
                        {isEditing == "postal_code" ? (
                            <TextField
                                value={newValue}
                                onChange={(e)=>setNewValue(e.target.value)}
                                onBlur={handleBlur}
                                onKeyPress={handleKeyPress}
                                autoFocus
                            />
                        ) : (
                            <Grid item xs={8} variant="profcontitem2" pl={1}>
                                {user?.postal_code}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={4} variant="profcontitem1"><Typography variant="pfsbold" textAlign= 'center'>{text.ville}</Typography></Grid>
                    <Grid item xs={8} variant="profcontitem2" pl={1} onDoubleClick={() => handleDoubleClick("country")} >
                        {isEditing == "country" ? (
                            <TextField
                                value={newValue}
                                onChange={(e)=>setNewValue(e.target.value)}
                                onBlur={handleBlur}
                                onKeyPress={handleKeyPress}
                                autoFocus
                            />
                        ) : (
                            <Grid item xs={8} variant="profcontitem2" pl={1}>
                                {user?.country}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={4} variant="profcontitem1"><Typography variant="pfsbold" textAlign= 'center'>{text.prov}</Typography></Grid>
                    <Grid item xs={8} variant="profcontitem2" pl={1} onDoubleClick={() => handleDoubleClick("province")} >
                        {isEditing == "province" ? (
                            <TextField
                                value={newValue}
                                onChange={(e)=>setNewValue(e.target.value)}
                                onBlur={handleBlur}
                                onKeyPress={handleKeyPress}
                                autoFocus
                            />
                        ) : (
                            <Grid item xs={8} variant="profcontitem2" pl={1}>
                                {user?.province}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={4} variant="profcontitem1"><Typography variant="pfsbold" textAlign= 'center'>{text.pays}</Typography></Grid>
                    <Grid item xs={8} variant="profcontitem2" pl={1} onDoubleClick={() => handleDoubleClick("city")} >
                        {isEditing == "city" ? (
                            <TextField
                                value={newValue}
                                onChange={(e)=>setNewValue(e.target.value)}
                                onBlur={handleBlur}
                                onKeyPress={handleKeyPress}
                                autoFocus
                            />
                        ) : (
                            <Grid item xs={8} variant="profcontitem2" pl={1}>
                                {user?.city}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={4} variant="profcontitem1"><Typography variant="pfsbold" textAlign= 'center'>{text.taille}</Typography></Grid>
                    <Grid item xs={8} variant="profcontitem2" pl={1} onDoubleClick={() => handleDoubleClick("size")} >
                        {isEditing == "size" ? (
                            <TextField
                                value={newValue}
                                onChange={(e)=>setNewValue(e.target.value)}
                                onBlur={handleBlur}
                                onKeyPress={handleKeyPress}
                                autoFocus
                            />
                        ) : (
                            <Grid item xs={8} variant="profcontitem2" pl={1}>
                                {user?.size}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={4} variant="profcontitem1"><Typography variant="pfsbold" textAlign= 'center'>{text.weight}</Typography></Grid>
                    <Grid item xs={8} variant="profcontitem2" pl={1} onDoubleClick={() => handleDoubleClick("weight")} >
                        {isEditing == "weight" ? (
                            <TextField
                                value={newValue}
                                onChange={(e)=>setNewValue(e.target.value)}
                                onBlur={handleBlur}
                                onKeyPress={handleKeyPress}
                                autoFocus
                            />
                        ) : (
                            <Grid item xs={8} variant="profcontitem2" pl={1}>
                                {user?.weight}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={4} variant="profcontitem1"><Typography variant="pfsbold" textAlign= 'center'>{text.search}</Typography></Grid>
                    <Grid item xs={8} variant="profcontitem2" pl={1} onDoubleClick={() => handleDoubleClick("research")} >
                        {isEditing == "research" ? (
                            <TextField
                                value={newValue}
                                onChange={(e)=>setNewValue(e.target.value)}
                                onBlur={handleBlur}
                                onKeyPress={handleKeyPress}
                                autoFocus
                            />
                        ) : (
                            <Grid item xs={8} variant="profcontitem2" pl={1}>
                                {user?.research}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={4} variant="profcontitem1"><Typography variant="pfsbold" textAlign= 'center'>{text.job}</Typography></Grid>
                    <Grid item xs={8} variant="profcontitem2" pl={1} onDoubleClick={() => handleDoubleClick("profession")} >
                        {isEditing == "profession" ? (
                            <TextField
                                value={newValue}
                                onChange={(e)=>setNewValue(e.target.value)}
                                onBlur={handleBlur}
                                onKeyPress={handleKeyPress}
                                autoFocus
                            />
                        ) : (
                            <Grid item xs={8} variant="profcontitem2" pl={1}>
                                {user?.profession}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={4} variant="profcontitem1"><Typography variant="pfsbold" textAlign= 'center'>{text.child}</Typography></Grid>
                    <Grid item xs={8} variant="profcontitem2" pl={1} onDoubleClick={() => handleDoubleClick("child")} >
                        {isEditing == "child" ? (
                            <TextField
                                value={newValue}
                                onChange={(e)=>setNewValue(e.target.value)}
                                onBlur={handleBlur}
                                onKeyPress={handleKeyPress}
                                autoFocus
                            />
                        ) : (
                            <Grid item xs={8} variant="profcontitem2" pl={1}>
                                {user?.child}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={4} variant="profcontitem1"><Typography variant="pfsbold" textAlign= 'center'>{text.eyes}</Typography></Grid>
                    <Grid item xs={8} variant="profcontitem2" pl={1} onDoubleClick={() => handleDoubleClick("eye_color")} >
                        {isEditing == "eye_color" ? (
                            <TextField
                                value={newValue}
                                onChange={(e)=>setNewValue(e.target.value)}
                                onBlur={handleBlur}
                                onKeyPress={handleKeyPress}
                                autoFocus
                            />
                        ) : (
                            <Grid item xs={8} variant="profcontitem2" pl={1}>
                                {user?.eye_color}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={4} variant="profcontitem1"><Typography variant="pfsbold" textAlign= 'center'>{text.hair}</Typography></Grid>
                    <Grid item xs={8} variant="profcontitem2" pl={1} onDoubleClick={() => handleDoubleClick("hair_color")} >
                        {isEditing == "hair_color" ? (
                            <TextField
                                value={newValue}
                                onChange={(e)=>setNewValue(e.target.value)}
                                onBlur={handleBlur}
                                onKeyPress={handleKeyPress}
                                autoFocus
                            />
                        ) : (
                            <Grid item xs={8} variant="profcontitem2" pl={1}>
                                {user?.hair_color}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid container style={{height: '150px'}}>
                    <Grid item xs={4} variant="profcontitem1"><Typography variant="pfsbold" textAlign= 'center'>{text.descri}</Typography></Grid>
                    <Grid item xs={8} variant="profcontitem2" pl={1} onDoubleClick={() => handleDoubleClick("desc")} >
                        {isEditing == "desc" ? (
                            <TextField
                                value={newValue}
                                onChange={(e)=>setNewValue(e.target.value)}
                                onBlur={handleBlur}
                                onKeyPress={handleKeyPress}
                                autoFocus
                            />
                        ) : (
                            <Grid item xs={8} variant="profcontitem2" pl={1}>
                                {user?.desc}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Stack>
        </Stack>
    )
} 

export default Profile;