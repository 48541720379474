import React, { useContext, useEffect, useState } from "react";
import { Stack, Box, Typography } from "@mui/material";
import useLanguage from "../hooks/useLanguage";
import SearchFilter from "../tools/SearchFilter"
import SupervisorCard from "../tools/SupervisorCard";
import axiosInstance from "../../axios";
import { AuthStateContext } from "../state/AuthStateContext";
import useData from "../hooks/useData";
import Pusher from 'pusher-js'
import { CLUSTER, PUSHER_SECRET, apiURL } from "../../helper/conf";
import { GifsContext } from "../state/gifContext";
import { formatDate } from "../../helper/formatDate";

const ChatOverview = ({stat,setStat,seeDetails}) => {
    const { globalFilter, setGlobalFilter } = useContext(GifsContext)
    const { text } = useLanguage()
    const { authState } = useContext(AuthStateContext)
    const {languagesList} = useData()

    useEffect(()=>{
        // console.log(globalFilter)
        axiosInstance.get(`/statistic?year=${globalFilter?.year}&month=${globalFilter?.month}&date=${formatDate(globalFilter?.date).split('-')[0]}&startingDate=${formatDate(globalFilter?.startingDate)}&closingDate=${formatDate(globalFilter?.closingDate)}&platforms=${globalFilter?.platforms}&language=${globalFilter?.language}&agents=${globalFilter?.agents}&week=${globalFilter.semaine}`, {
            headers: {
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then((response) => {
            // console.log(response.data)
            setStat(response.data)
        })
        .catch((error) => {
            // console.log(error)
        })
    },[globalFilter])

    //PUSHER STATISTIC
    const [ stat1, setStat1 ] = useState()
    useEffect(()=>{
        const pusher = new Pusher(PUSHER_SECRET,{cluster:CLUSTER,channelAuthorization:{endpoint:`${apiURL}/pusher/user-auth`,transport:'jsonp'}})
        const channel = pusher.subscribe("private-statistic")
        channel.bind(`${authState?.userId}`, (data)=> {
            // console.log(data)
            setStat1({name: data.name, field: data.field, value: data.value})
        })
        return()=>{
            channel.unbind()
            pusher.unsubscribe("private-statistic")
        }
    },[])

    useEffect(()=>{
        if(stat1){
            const number = stat[stat1.name][stat1.field] + stat1.value
            setStat({...stat, [stat1.name]:{...stat[stat1.name], [stat1.field]: number}})
            setStat1(null)
        }
    },[stat1])

    return (
        <Stack spacing={6} style={{padding: '0 10px', boxSizing: 'border-box'}}>
            <Typography style={{fontFamily: 'Fascinate Regular', fontWeight: 'bold', color: '#de5b6d', WebkitTextStroke: '2px #700712'}} variant="h1" alignSelf='center'>
                {text.choverv}
            </Typography>              
            <SearchFilter
                onSemaineClick={(semaine)=>setGlobalFilter({...globalFilter, semaine: semaine})}
                selectedDate={globalFilter.date}
                onDateChange={{
                    year: y => setGlobalFilter({...globalFilter, year: y}),
                    month: m => setGlobalFilter({...globalFilter, month: m}),
                    date: d => setGlobalFilter({...globalFilter, date: d}),
                }}
                items= {[
                    { label: text.startingdate , comp: 'DatePicker', selected: globalFilter.startingDate, onChange: d => setGlobalFilter({...globalFilter, startingDate: d})},
                    { label: text.closingdate , comp: 'DatePicker', selected: globalFilter.closingDate, onChange: d => setGlobalFilter({...globalFilter, closingDate: d})},
                    { label: text.platform , comp: 'TextInput', onChange: e => setGlobalFilter({...globalFilter, platforms: e.target.value}) },
                    { label: text.agents , comp: 'TextInput', onChange: e => setGlobalFilter({...globalFilter, agents: e.target.value}) },
                    { label: text.language, comp: 'Select', choices: languagesList, onChange: e => setGlobalFilter({ ...globalFilter, language: e.target.value }) },
                ]}
            />
            <Stack spacing={2}>
                <Box className="wrap-content" justifyContent='center' gap='50px'>
                    <SupervisorCard 
                        imgFile="1conv.png" 
                        borderColor="#fef161" 
                        shadowColor="#feb347" 
                        label={text.convs} 
                        fields={[{label: text.treated , value: stat?.conversation.treated}, {label: text.onhld , value: stat?.conversation.onHold}]} 
                        onDetails={() => seeDetails(1)}
                    />
                    <SupervisorCard 
                        imgFile="2age.png" 
                        borderColor="#fcf4fe" 
                        shadowColor="#e79efe" 
                        label={text.agents} 
                        fields={[{label: text.onl , value: stat?.operator.online}, {label: text.total , value: stat?.operator.total}]} 
                        onDetails={() => seeDetails(2)}
                    />
                    <SupervisorCard 
                        imgFile="3pan.png" 
                        borderColor="#effeda" 
                        shadowColor="#c9fe86" 
                        label={text.panicroom} 
                        fields={[{label: text.unNouv , value: stat?.totalPanic}, {label: text.total , value: stat?.totalPanic}]}
                        onDetails={() => seeDetails(3)}
                    />
                </Box>
                <Box className="wrap-content" justifyContent='center' gap='50px'>
                    <SupervisorCard 
                        imgFile="4no.png" 
                        borderColor="#f8fcfd" 
                        shadowColor="#5dd8fe" 
                        label={text.notes} 
                        fields={[{label: text.unReç , value: stat?.Note.receive} , {label: text.sent , value: stat?.Note.send}]}
                        onDetails={() => seeDetails(4)}
                    />
                    <SupervisorCard 
                        imgFile="5tea.png" 
                        borderColor="#fef7f8" 
                        shadowColor="#f05a5e" 
                        label={text.teasermessages} 
                        fields={[{label: text.out , value: stat?.teaser.sortir}, {label: text.in , value: stat?.teaser.entrer}]} 
                        onDetails={() => seeDetails(5)}
                    />
                    <SupervisorCard 
                        imgFile="6pok.png" 
                        borderColor="#ccfeec" 
                        shadowColor="#7cfedc" 
                        label={text.pokemessages} 
                        fields={[{label: text.total , value: stat?.Poke.total}]} 
                        onDetails={() => seeDetails(6)}
                    />
                </Box>
                <Box className="wrap-content" justifyContent='center' gap='50px'>
                    <SupervisorCard imgFile="7sto.png" borderColor="linear-gradient(to top right, #f9cdde, #ece2b5)" shadowColor="linear-gradient(to top right, #e452a8, #f0c55c)" label={text.stopmessages}   fields={[{label: text.total , value: stat?.stop.total}]}  onDetails={() => seeDetails(7)}/>
                    <SupervisorCard 
                        imgFile="8pho.png" 
                        borderColor="#edf7fe" 
                        shadowColor="#98c5e1" 
                        label={text.photosrequests} 
                        fields={[{label: text.requested , value: stat?.photo.demander}, {label: text.sent , value: stat?.photo.envoyer}]}
                        onDetails={() => seeDetails(8)}
                    />
                    <SupervisorCard 
                        imgFile="9re.png" 
                        borderColor="#fddbf4" 
                        shadowColor="#fe5ecc" 
                        label={text.report} 
                        fields={[{label: text.unNouv1 , value: stat?.Report.nouveau}, {label: text.total , value: stat?.Report.total}]}
                        onDetails={() => seeDetails(9)}
                    />
                </Box>
                <Box className="wrap-content" justifyContent='center' gap='50px'>
                    <SupervisorCard 
                        isKid = "true"
                        type = "logbook"
                        imgFile="10lo.png" 
                        borderColor="linear-gradient(to top right, #ebf5fa, #f6d5ef" 
                        shadowColor="linear-gradient(to top right, #76ccf5, #ef5aca"  
                        label={text.logbook} 
                        fields={[
                            {label: text.rvr , value: stat?.LogBook.meeting}, 
                            {label: text.prmnnt , value: stat?.LogBook.permanent},
                            {label: text.ntprmnnt , value: stat?.LogBook.not_permanent}, 
                            {label: text.esqv , value: stat?.LogBook.esquive},
                        ]}
                        onDetails={() => seeDetails(10)}
                    />
                    <SupervisorCard imgFile="11du.png" borderColor="#d1faf3" shadowColor="#79eed7" label={text.duplicatedmessages} fields={[{label: text.total , value: ''}]} onDetails={() => seeDetails(11)}/>
                    <SupervisorCard 
                        imgFile="12gi.png" 
                        borderColor="#fef161" 
                        shadowColor="#feb347" 
                        label={'Gifts et Gifs'}
                        fields={[{label: 'Gifts' , value: stat?.Gif.totalGifts}, {label: 'Gifs' , value: stat?.Gif.total}]}
                        onDetails={() => seeDetails(12)}
                    />
                </Box>
            </Stack>
        </Stack>
    )
} 

export default ChatOverview;