import { Box, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import Language from '../Language/Language';
import useLanguage from '../hooks/useLanguage';
import axiosInstance from '../../axios';
import BallotIcon from '@mui/icons-material/Ballot';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { Link, useNavigate } from 'react-router-dom';
import PokeDisplayMessage from './PokeDisplayMessage';
import Pusher from 'pusher-js'
import { CLUSTER, PUSHER_SECRET, apiURL, baseURL } from "../../helper/conf";
import SwitchFeature from './SwitchFeature';
import Logout from './Logout';
import { AuthStateContext } from '../state/AuthStateContext';

const Stop = () => {
    const { authState } = useContext(AuthStateContext)
    const { text } = useLanguage();
    const [ profils, setProfils ] = useState()
    const [ border, setBorder ] = useState()
    const [ vraiId, setVraiId ] = useState()
    const [ fauxId, setFauxId ] = useState()
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    const [ok, setOk] = useState()

    useEffect(()=>{
        if(!token){
            navigate('/login');
        }else{
            axiosInstance.post('/interface/token',{
                "token" : token,
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            })
            .then((response) => {
                // console.log(response)
                if(response.data.success != true){
                    navigate('/login');
                }
                setOk(response.data.success)
            })
            .catch((error) => {
                // console.log(error)
                navigate('/login');
            })
        }
    }, [])

    // GET ALL STOP
    const [ stop, setStop ] = useState([])
    useEffect(() => {
        axiosInstance.get('/stop', {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
        .then((response) => {
        //   console.log(response.data.data)
          setStop(response.data.data)
        })
        .catch((error) => {
            // console.log(error)
        })
    }, [])

    // PUSHER RECEIVE REPONSE STOP
    useEffect(()=>{
        const pusher = new Pusher(PUSHER_SECRET,{cluster:CLUSTER,channelAuthorization:{endpoint:`${apiURL}/pusher/user-auth`,transport:'jsonp'}})
        const channel = pusher.subscribe("private-stop")
        channel.bind(`add-${userId}`, (data) => {
            // console.log(data)
            setStop((prev) => prev.some((d) => d.id === data.id) ? 
                prev.map((d) => (d.id === data.id ? data : d))
                : 
                [...prev, data]
            );
        })
        return()=>{
            channel.unbind()
            pusher.unsubscribe("private-stop")
        }
    },[])


if(ok){
  return (
    <div style={{ maxWidth: "100%", minHeight: "100vh", background: "black", color: "white", paddingBottom: "60px" }} >
        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid gray"}}>
            <Stack maxWidth={50} sx={{ padding: "20px"}}>
                <Link to="/home" >
                  <img src="/images/HeaderImage/logo.png" alt="" height='50px'/>
                </Link>
            </Stack>
            <Typography variant='h4' style={{fontWeight: 'bold', color: "white"}}>
                {text.inter}
            </Typography>
            <Stack maxWidth={50} sx={{ padding: "20px", flexDirection: "row", alignItems: "center", gap: "10px"}}>
                <SwitchFeature />
                <Logout />
            </Stack>
            <Stack maxWidth={50} sx={{ padding: "20px"}}>
                <Language redirectRoute={'/stopmessage'} />
            </Stack>
        </div>
        <div style={{ width: "100%", minHeight: "89vh", display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "40px"}}>
            <div style={{width: "85%"}}>
                <div style={{ marginBottom: "20px", color: "white"}}>
                    <div style={{ marginBottom: "40px"}}>
                        <Typography variant='h2' style={{fontWeight: 'bold', marginBottom: '2px', color: 'orange'}}>
                            {text.biev},
                        </Typography>
                        <Typography variant='h3' style={{marginBottom: '2px', color: '#dddddd'}}>
                            {text.sendInt} {text.tit4}
                        </Typography>
                    </div>
                    <div style={{ marginBottom: "30px"}}>
                        <Stack sx={{marginBottom: '2px', flexDirection: "row", alignItems: "center", gap: "4px"}}>
                            <BallotIcon sx={{ color: 'orange', fontSize: '32px' }} />
                            <Typography variant='h4' style={{fontWeight: 'bold'}}>
                                {text.descri} :
                            </Typography>
                        </Stack>
                        <Typography variant='p' style={{marginBottom: '2px', color: '#dddddd', textAlign: 'justify'}}>
                            {text.stopdes}
                        </Typography>
                    </div>
                    <div style={{ marginBottom: "30px"}}>
                        <Stack sx={{flexDirection: "row", alignItems: "center", gap: "4px"}}>
                            <ManageAccountsIcon sx={{ color: 'orange', fontSize: '32px' }} />
                            <Typography variant='h4' style={{fontWeight: 'bold'}}>
                                {text.instTest} :
                            </Typography>
                        </Stack>
                        <Stack sx={{ color: '#dddddd' }}>
                            <ul style={{ fontSize: "18px"}}>
                                <li>
                                    {text.stopdes1}
                                </li>
                                <li>
                                    {text.stopdes2}
                                </li>
                                <li>
                                    {text.stopdes3}
                                </li>
                                <li>
                                    {text.stopdes4}
                                </li>
                                <li>
                                    {text.stopdes5}
                                </li>
                            </ul>
                        </Stack>
                    </div>
                </div>
                <div>
                    <Typography variant='h4' style={{fontWeight: 'bold', marginBottom: "20px"}}>
                        {text.stopbla} :
                    </Typography>
                    <div style={{display: "flex", justifyContent: "space-between", gap: '10px', marginBottom: '4px', width: "100%"}}>
                        {/* affichage de stop */}
                        <PokeDisplayMessage data={stop} type="stop" />
                    </div>
                </div> 
            </div>
        </div>
    </div>
  )}else{
    return(
        <div style={{ maxWidth: "100%", height: "100vh", background: "black" }} >
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
            >
                <CircularProgress />
            </Box>
        </div>
    )
  }
}


export default Stop