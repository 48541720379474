import { MenuItem, Typography, Menu, Box, Stack, Icon } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import useLanguage from "../hooks/useLanguage";
import Logout from "../Auth/Logout";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import useData from "../hooks/useData";
import { useNavigate } from "react-router-dom";
import { AuthStateContext } from "../state/AuthStateContext";
import { apiURL, CLUSTER, PUSHER_SECRET } from "../../helper/conf";
import Pusher from "pusher-js";

const UserOptions = ({ variant, setOpen, setSee }) => {
  const { authState: { login, userId, userName, userRole, token } } = useContext(AuthStateContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { text } = useLanguage();
  const [hover, setHover] = useState(false);
  const { roles } = useData();
  const storedUserData = JSON.parse(localStorage.getItem('userData')) || {};
  const [updatedUserName, setUpdatedUserName] = useState(storedUserData.userName || userName);

  const getRoleName = (role) => {
    switch (role) {
      case 'administrator': return text.administrator;
      case 'operator': return text.operator;
      case 'supervisor': return text.supervisor;
      default:
        break;
    }
  };

  useEffect(() => {
    const pusher = new Pusher(PUSHER_SECRET, {
      cluster: CLUSTER,
      channelAuthorization: {
        endpoint: `${apiURL}/pusher/user-auth`,
        transport: 'jsonp',
      },
    });

    const channel = pusher.subscribe(`private-${userId}`);
    channel.bind("operatorUpdated", (data) => {
      const updatedData = { ...storedUserData, userName: data.name };
      setUpdatedUserName(data.name);
      localStorage.setItem('userData', JSON.stringify(updatedData));
    });

    return () => {
      pusher.unsubscribe(`private-${userId}`);
    };
  }, [userId, storedUserData]);

  const navigate = useNavigate();

  const getFirstItemData = () => {
    switch (variant) {
      case roles.admin:
        return [
          text.adminid,
          text.notifs,
          "/images/HeaderImage/adminprofil.png",
          false,
          () => {},
        ];
      case roles.accountant:
        return [
          text.accountantid,
          text.notifs,
          "/images/HeaderImage/accprofil.png",
          true,
          () => {},
        ];
      case roles.operator:
        return [
          text.operatorid,
          text.stat,
          "/images/HeaderImage/opprofil.png",
          true,
          () => { setSee(true); setAnchorEl(null); },
        ];
      case roles.qc:
        return [
          text.qcid,
          text.notifs,
          "/images/HeaderImage/qcprofil.png",
          true,
          () => {},
        ];
      case roles.supervisor:
        return [
          text.supervisorid,
          text.notifs,
          "/images/HeaderImage/supprofil.png",
          true,
          () => {},
        ];
      default:
        return [, , , ,];
    }
  };

  const [idIntro, act, img, hasBell, thirdsFunction] = getFirstItemData();

  return (
    <Box>
      <Stack
        height="45px"
        position="relative"
        sx={{ display: 'flex', flexDirection: 'row', placeItems: 'center', gap: 2 }}
      >
        <Stack className="profileheader" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} variant="pdp" sx={{ justifyContent: 'center' }}>
          {hover ? (
            <Icon
              style={{
                position: "absolute",
                bottom: "0",
                right: "0",
                transform: "translate(40%, 40%)",
                color: "#000",
              }}
            >
              <ArrowDropDownIcon />
            </Icon>
          ) : null}
          <img
            style={{ cursor: "pointer", height: "90%" }}
            src={img}
            alt=""
            onClick={(event) => setAnchorEl(anchorEl ? null : event.currentTarget)}
          />
        </Stack>
      </Stack>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            style: { backgroundColor: "transparent", boxShadow: "none" },
          },
        }}
      >
        <MenuItem
          style={{
            backgroundColor: "#de5b6d",
            color: "#fff",
            justifyContent: "center",
          }}
        >
          <Typography>{idIntro}: {updatedUserName}</Typography>
        </MenuItem>
        <MenuItem
          style={{
            backgroundColor: "#f4a100",
            color: "#fff",
            margin: "7px 0",
            justifyContent: "center",
          }}
        >
          <Typography>{text.role}: {getRoleName(userRole)}</Typography>
        </MenuItem>
        <MenuItem
          style={{
            backgroundColor: "#004aad",
            color: "#fff",
            margin: "0 0 7px 0",
            justifyContent: "center",
          }}
          onClick={thirdsFunction}
        >
          <Typography>{act}</Typography>
        </MenuItem>
        <Logout />
      </Menu>
    </Box>
  );
};

export default UserOptions;
