import { Stack, Typography, Button, DialogTitle, DialogActions, DialogContent, Pagination, Box } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import BigContainerAccordion from "../tools/BigContainerAccordion";
import useLanguage from "../hooks/useLanguage";
import SearchFilter from "../tools/SearchFilter";
import TableRow from "../tools/TableRow";
import useData from "../hooks/useData";
import FormRow from "../tools/FormRow";
import {useForm} from 'react-hook-form'
import countriesList from "../../helper/countries";
import useList from "../hooks/useList";
import AuthFormRow from "../tools/AuthFormRow";
import axiosInstance from "../../axios";
import CustomAlert from "../Admin/CustomAlert";
import { format } from "date-fns";
import { AuthStateContext } from "../state/AuthStateContext";
import CustomPopup from "../Admin/CustomPopup";
import Pusher from 'pusher-js'
import { CLUSTER, PUSHER_SECRET, apiURL } from "../../helper/conf";
import { formatDate, formatDate2, convertToPHPArray } from "../../helper/formatDate";
import { roles } from "../../helper/roles";
import { GifsContext } from "../state/gifContext";
import Title from "../Admin2/Comps/Title";

const Agents = () =>{
    const { globalFilter } = useContext(GifsContext)
    const {text} = useLanguage()
    const { languagesList, roleList, languagesgifList, paymentList } = useData()
    const {agentList, setAgentList, platformsPerf, agentsPerf, getOperatorList, getPlatformsPerformance, getAgentsPerformance} = useList()
    const [error,setError] = useState('')
    const [ppError, setPPError] = useState(false)
    const [pp, setPP] = useState(false)
    const [ alerto, setAlerto ] = useState(false)
    const { authState } = useContext(AuthStateContext)
    const [popContenu, setPopContenu] = useState()
    const [ loading, setLoading ] = useState(false)
    const success = text.scc

    useEffect(() =>{
        getPlatformsPerformance()
        getAgentsPerformance()
    },[])
    
    const [filter, setFilter] = useState(() => ({
        ...globalFilter,
    }))

    const [filter2, setFilter2] = useState(() => ({
        ...globalFilter,
        payment: ''
    }))

    const { control, reset, handleSubmit,watch, formState: {errors} } = useForm()

    const [ selectedPayment, setSelectedPayment ] = useState()
    const wPaye = watch('paymentName')
	const newAgent = async({role,mail,password,login,confirmpassword,name, fullName, fullAddress, country,languageName, paymentName, bankname, iban, bic, paymail}) =>{
        if(error){
            setError('')
            setPPError(!pp)
        }
        if(password === confirmpassword){
            try{
                const data = await axiosInstance.post('/register', { mail,password,login,role,name,country,languageName, paymentName, bankname, iban, bic, paymail,fullName,fullAddress},{
                    headers: { 
                        'Authorization': `Bearer ${authState.token}`,
                    },
                })
                setAgents(p => [...p,data.data.userData])
                setPopContenu(success)
                setAlerto(true)
                reset()
            }catch(e){
                switch(e.response.data.errors){
                    case '20':
                        setError(text.min8car);
                        break;
                    case '21':
                        setError(text.aumoins1maj);
                        break;
                    case '22':
                        setError(text.aumoins1sp);
                        break;
                    case '23':
                        setError(text.emailused);
                        break;
                    case '24':
                        setError(text.rolenotfound);
                        break;
                    default:
                        setError(e.response.data.errors)
                        break;
                }
            }
        }else{
            setError(text.passdiff)
        }
	}

    // LIST OPERATOR
    const [ page, setPage] = useState(1)
    const [ pagination, setPagination ] = useState()
    const [ agents, setAgents ] = useState()
    const handleChange = (event, value) => {
        setPage(value);
    };
    useEffect(() => {
        axiosInstance.get(`/user/${roles.operator}?year=${filter?.year}&month=${filter?.month}&date=${formatDate(filter?.date).split('-')[0]}&startingDate=${formatDate(filter?.startingDate)}&closingDate=${formatDate(filter?.closingDate)}&platforms=${filter?.platforms}&language=${filter?.language}&agents=${filter?.agents}&page=${page}`, {
            headers: {
                'Authorization': `Bearer ${authState.token}`
            }
        })
        .then((response) => {
            // console.log(response.data)
            setAgents(response.data.data)
            setPagination(response.data.pagination)
        })
        .catch((e) => {})
    }, [page, filter])

    //UPDATE OPERATOR
    const [ openUpdate, setOpenUpdate ] = useState(false)
    const [ opToUpdate, setOpToUpdate ] = useState()
    const [ newPassword, setNewPassword] = useState("")
    const handleUpdate = () => {
        setLoading(true)
        axiosInstance.put(`/operator/${opToUpdate?.id}`,{
            "newPassword": newPassword,
        },{
            headers: { 
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then((response) => {
            // console.log(response.data)
            setPopContenu(text.mdpupdtd)
            setAlerto(true)
            setLoading(false)
            setOpenUpdate(false)
        })
        .catch((error) => {
            // console.log(error)
            setLoading(false)
        })
    }

    //SUSPEND OPERATOR
    const [ openSuspend, setOpenSuspend ] = useState(false)
    const [ opToSuspend, setOpToSuspend ] = useState()
    const handleSuspend = () => {
        // console.log(authState.token)
        setLoading(true)
        axiosInstance.post(`/operator/suspend`,{
            "id": opToSuspend?.id,
        },{
            headers: { 
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then((response) => {
            setPopContenu(text.opsusp)
            setAgents((prev) => prev.map((a) => a.id == response.data.data.id ? response.data.data : a))
            setAlerto(true)
            setLoading(false)
            setOpenSuspend(false)
        })
        .catch((error) => {
            // console.log(error)
            setLoading(false)
        })
    }

    //KICK OFF OPERATOR
    const [ openDelete, setOpenDelete ] = useState(false)
    const [ opToDelete, setOpToDelete ] = useState()
    const handleDelete = () => {
        setLoading(true)
        axiosInstance.delete(`/kickOff_operator/${opToDelete?.id}`,{
            headers: { 
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then((response) => {
            // console.log(response.data)
            setPopContenu(text.delalert)
            setAlerto(true)
            setLoading(false)
            getOperatorList()
            setOpenDelete(false)
        })
        .catch((error) => {
            // console.log(error)
            setLoading(false)
        })
    }

    //INFO OPERATOR
    const [ openInfo, setOpenInfo ] = useState(false)
    const [ opToInfo, setOpToInfo ] = useState()
    const [ data, setData ] = useState([]);
    const [isEditing, setIsEditing] = useState();

    const handleDoubleClick = (item) => {
        setIsEditing(item);
    };
    const handleBlur = () => {
        setIsEditing();
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            const item = {
                [e.target.name] : e.target.value
            }
            setData((prev) => {
                const index = prev.findIndex((entry) => entry[e.target.name] !== undefined);
                if (index !== -1) {
                    const updatedData = [...prev];
                    updatedData[index] = item;
                    return updatedData;
                } else {
                    return [...prev, item];
                }
            })

            if(e.target.name == "name"){
                setOpToInfo(prev => ({
                    ...prev,
                    name: e.target.value
                }));
            }else if(e.target.name == "mail"){
                setOpToInfo(prev => ({
                    ...prev,
                    mail: e.target.value
                }));
            }else if(e.target.name == "companyname"){
                setOpToInfo(prev => ({
                    ...prev,
                    companyname: e.target.value
                }));
            }else if(e.target.name == "country"){
                setOpToInfo(prev => ({
                    ...prev,
                    country: e.target.value
                }));
            }else if(e.target.name == "fullName"){
                setOpToInfo(prev => ({
                    ...prev,
                    full_name: e.target.value
                }));
            }else if(e.target.name == "address"){
                setOpToInfo(prev => ({
                    ...prev,
                    address: e.target.value
                }));
            }else if(e.target.name == "payment"){
                setOpToInfo(prev => ({
                    ...prev,
                    payment: {
                        ...prev.payment,
                        Name: e.target.value
                    }
                }));
            }else if(e.target.name == "language"){
                setOpToInfo(prev => ({
                    ...prev,
                    language: {
                        ...prev.language,
                        name: e.target.value
                    }
                }));
            }else if(e.target.name == "bankname"){
                setOpToInfo(prev => ({
                    ...prev,
                    bankname: e.target.value
                }));
            }else if(e.target.name == "iban"){
                setOpToInfo(prev => ({
                    ...prev,
                    iban: e.target.value
                }));
            }else if(e.target.name == "bic"){
                setOpToInfo(prev => ({
                    ...prev,
                    bic: e.target.value
                }));
            }else if(e.target.name == "paymail"){
                setOpToInfo(prev => ({
                    ...prev,
                    paymail: e.target.value
                }));
            }

            setIsEditing();
        }
    }

    const handleInfo = (e) => {
        let string = JSON.stringify(data)
        let result = string.replace(/{|}/g, '').replace(/:/g, '=>');
        // console.log(result)
        setLoading(true)
        axiosInstance.put(`/update_operator/${opToInfo?.id}`,{
            "datas" : result,
        },{
            headers: { 
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then((response) => {
            // console.log(response.data.data)
            setPopContenu("Operator update succesfull")
            setAlerto(true)
            setLoading(false)
            getOperatorList()
            setOpenInfo(false)
            setData([])
            setAgents((prev) => prev.map((d) => d.id == response.data.data.id ? response.data.data : d))
        })
        .catch((error) => {
            // console.log(error)
            setLoading(false)
        })
    }

    // DECONNECT OPERATEURS
    const [ openDeconnect, setOpenDeconnect] = useState(false)
    const [ opToDec, setOpToDec ] = useState()
    const deconnectAgent = async(agent,index) => {
        // console.log(opToDec?.id)
        setLoading(true)
        await axiosInstance.put('/AgentDeconnection', {
            id: opToDec?.id
        },{ 
            headers: { 
                'Authorization': `Bearer ${authState.token}`
            }
        })
        .then(rep => {
            // console.log('deconnection : ', opToDec?.id)
            setPopContenu(text.opdec)
            setAlerto(true)
            setLoading(false)
            setAgents((last)=> last.map((p) => p.id == opToDec.id ? {...p, status: 'disconnect'} : { ...p } ))
            setOpenDeconnect(false)
        }).catch((e) => {
            // console.log(e)
            setLoading(false)
        })
    }

    //PUSHER STATUT OPERATOR
    useEffect(()=>{
        const pusher = new Pusher(PUSHER_SECRET,{cluster:CLUSTER,channelAuthorization:{endpoint:`${apiURL}/pusher/user-auth`,transport:'jsonp'}})
        const channel = pusher.subscribe('private-conversation')
        channel.bind(`status-${authState.userId}`, (data)=> {
            // console.log(data.status)
            setAgents((last)=>last.map((a)=> a.id == data.id ? { ...a, status: data.status } : a))
        })
        return()=>{
            channel.unbind()
            pusher.unsubscribe('private-conversation')
        }
    },[])

    // LIST AGENT PERFORMANCE
    const [ datas, setDatas ] = useState()
    const [ pagination2, setPagination2 ] = useState()
    const [page2, setPage2] = useState(1);

    const handleChange2 = (event, value) => {
        setPage2(value);
    };

    useEffect(()=>{
        axiosInstance.get(`/agentRateStat?year=${filter2?.year}&month=${filter2?.month}&date=${formatDate2(filter2?.date)}&platform=${filter2?.platforms}&week=${filter2?.week}&startingDate=${formatDate2(filter2?.startingDate)}&closingDate=${formatDate2(filter2?.closingDate)}&page=${page2}&payment=${filter2?.payment}&agent=${filter2?.agents}&language=${filter2?.language}`, {
            headers: {
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then((response) => {
            setDatas(response.data.data)
            setPagination2(response.data.pagination)
        })
        .catch((error) => {
            // console.log(error)
        })
    },[filter2, page2])

    return(
        <Stack padding="2% 0%" spacing={3} height="100%" style={{ overflowY: "scroll" }}>
            <CustomAlert
                open={alerto}
                autoHideDuration={1500}
                onClose={() => setAlerto(false)}
                severity="success"
                message={popContenu}
            />
            {/* pop up deconnect */}
            <CustomPopup open={openDeconnect} onClose={() => setOpenDeconnect(false)}>
                <div style={{ padding: "18px 30px"}}>
                    <DialogTitle id="alert-dialog-title" sx={{textAlign: "center"}}>
                        <p style={{ fontWeight: "800", fontSize: "22px"}}>
                            {text.decop} {opToDec?.name} ?
                        </p>
                    </DialogTitle>
                    <DialogActions>
                        <Button
                            variant='contained' 
                            sx={{ 
                                fontWeight: "700", 
                                bgcolor: "error.main",
                                color: "white",
                                "&:hover": {
                                    bgcolor: "error.dark",
                                } 
                            }} 
                            onClick={() => setOpenDeconnect(false)}
                        >
                            {text.annuler}
                        </Button>
                        <Button 
                            variant='contained' 
                            sx={{ 
                                fontWeight: "700", 
                                bgcolor: "success.main", 
                                color: "white",
                                "&:hover": {
                                    bgcolor: "success.dark",
                                } 
                            }} 
                            onClick={()=> deconnectAgent()}
                        >
                            {loading ? text.phload : text.cnfrm}
                        </Button>
                    </DialogActions>
                </div>
            </CustomPopup>
            {/* pop up delete */}
            <CustomPopup open={openDelete} onClose={() => setOpenDelete(false)}>
                <div style={{ padding: "18px 30px"}}>
                    <DialogTitle id="alert-dialog-title" sx={{textAlign: "center"}}>
                        <p style={{ fontWeight: "800", fontSize: "22px"}}>
                            {text.frop} {opToDelete?.name} ?
                        </p>
                    </DialogTitle>
                    <DialogActions>
                        <Button
                            variant='contained' 
                            sx={{ 
                                fontWeight: "700", 
                                bgcolor: "error.main",
                                color: "white",
                                "&:hover": {
                                    bgcolor: "error.dark",
                                } 
                            }} 
                            onClick={() => setOpenDelete(false)}
                        >
                            {text.annuler}
                        </Button>
                        <Button 
                            variant='contained' 
                            sx={{ 
                                fontWeight: "700", 
                                bgcolor: "success.main", 
                                color: "white",
                                "&:hover": {
                                    bgcolor: "success.dark",
                                } 
                            }} 
                            onClick={()=> handleDelete()}
                        >
                            {loading ? text.phload : text.cnfrm}
                        </Button>
                    </DialogActions>
                </div>
            </CustomPopup>
            {/* pop up info */}
            <CustomPopup open={openInfo} onClose={() => setOpenInfo(false)}>
                <div style={{ padding: "18px 30px"}}>
                    <DialogTitle id="alert-dialog-title" sx={{textAlign: "center"}}>
                        <p style={{ fontWeight: "800", fontSize: "22px"}}>
                            {text.supInfo} {opToInfo?.name} :
                        </p>
                    </DialogTitle>
                    <DialogContent>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Stack sx={{ fontWeight: "700", fontSize: "20px", mr: "6px" }}>
                            {text.usrnm}:
                            </Stack>
                            <Stack sx={{ fontSize: "20px" }} onDoubleClick={() => handleDoubleClick("name")}>
                                {isEditing == "name" ?
                                    <input 
                                        type="text"
                                        name="name"
                                        onBlur={handleBlur}
                                        onKeyPress={handleKeyPress}
                                        autoFocus
                                        style={{
                                            padding: "4px 8px",
                                            fontSize: "16px",
                                            fontWeight: "500",
                                            border: "1px solid #000",
                                            borderRadius: "5px",
                                            backgroundColor: "#f9f9f9",
                                            color: "#333",
                                            outline: "none",
                                            cursor: "pointer",
                                        }}
                                    />
                                    :
                                    <Stack>
                                        {opToInfo?.name}
                                    </Stack>
                                }
                            </Stack>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Stack sx={{ fontWeight: "700", fontSize: "20px", mr: "6px" }}>
                                {text.login}:
                            </Stack>
                            <Stack sx={{ fontSize: "20px" }} onDoubleClick={() => handleDoubleClick("mail")}>
                                <Stack>
                                    {opToInfo?.login}
                                </Stack>
                            </Stack>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Stack sx={{ fontWeight: "700", fontSize: "20px", mr: "6px" }}>
                                {text.eml}:
                            </Stack>
                            <Stack sx={{ fontSize: "20px" }} onDoubleClick={() => handleDoubleClick("mail")}>
                                {/* {isEditing == "mail" ?
                                    <input 
                                        type="text"
                                        name="mail"
                                        onBlur={handleBlur}
                                        onKeyPress={handleKeyPress}
                                        autoFocus
                                        style={{
                                            padding: "4px 8px",
                                            fontSize: "16px",
                                            fontWeight: "500",
                                            border: "1px solid #000",
                                            borderRadius: "5px",
                                            backgroundColor: "#f9f9f9",
                                            color: "#333",
                                            outline: "none",
                                            cursor: "pointer",
                                        }}
                                    />
                                    :
                                    else
                                } */}
                                <Stack>
                                    {opToInfo?.mail}
                                </Stack>
                            </Stack>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Stack sx={{ fontWeight: "700", fontSize: "20px", mr: "6px" }}>
                                {text.language}:
                            </Stack>
                            <Stack sx={{ fontSize: "20px" }}>
                                {/* {isEditing == "language" ?
                                    <select
                                        name="language"
                                        onBlur={handleBlur}
                                        onKeyPress={handleKeyPress}
                                        autoFocus
                                        style={{
                                            padding: "4px 8px",
                                            fontSize: "16px",
                                            fontWeight: "500",
                                            border: "1px solid #000",
                                            backgroundColor: "#f9f9f9",
                                            color: "#333",
                                            outline: "none",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <option value="fr">fr</option>
                                        <option value="en">en</option>
                                        <option value="es">es</option>
                                        <option value="it">it</option>
                                        <option value="nl">nl</option>
                                        <option value="de">de</option>
                                    </select>
                                    :
                                    else
                                } */}
                                <Stack>
                                    {opToInfo?.language.name}
                                </Stack>
                            </Stack>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Stack sx={{ fontWeight: "700", fontSize: "20px", mr: "6px" }}>
                                {text.sct}:
                            </Stack>
                            <Stack sx={{ fontSize: "20px" }}>
                                {/* {isEditing == "companyname" ?
                                    <input 
                                        type="text"
                                        name="companyname"
                                        onBlur={handleBlur}
                                        onKeyPress={handleKeyPress}
                                        autoFocus
                                        style={{
                                            padding: "4px 8px",
                                            fontSize: "16px",
                                            fontWeight: "500",
                                            border: "1px solid #000",
                                            borderRadius: "5px",
                                            backgroundColor: "#f9f9f9",
                                            color: "#333",
                                            outline: "none",
                                            cursor: "pointer",
                                        }}
                                    />
                                    :
                                    else
                                } */}
                                <Stack>
                                    {opToInfo?.companyname}
                                </Stack>
                            </Stack>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Stack sx={{ fontWeight: "700", fontSize: "20px", mr: "6px" }}>
                                {text.cntr}:
                            </Stack>
                            <Stack sx={{ fontSize: "20px" }} onDoubleClick={() => handleDoubleClick("country")}>
                                {isEditing == "country" ?
                                    <input 
                                        type="text"
                                        name="country"
                                        onBlur={handleBlur}
                                        onKeyPress={handleKeyPress}
                                        autoFocus
                                        style={{
                                            padding: "4px 8px",
                                            fontSize: "16px",
                                            fontWeight: "500",
                                            border: "1px solid #000",
                                            borderRadius: "5px",
                                            backgroundColor: "#f9f9f9",
                                            color: "#333",
                                            outline: "none",
                                            cursor: "pointer",
                                        }}
                                    />
                                    :
                                    <Stack>
                                        {opToInfo?.country}
                                    </Stack>
                                }
                            </Stack>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Stack sx={{ fontWeight: "700", fontSize: "20px", mr: "6px" }}>
                                {text.supInfoFull}:
                            </Stack>
                            <Stack sx={{ fontSize: "20px" }} onDoubleClick={() => handleDoubleClick("fullName")}>
                                {isEditing == "fullName" ?
                                    <input 
                                        type="text"
                                        name="fullName"
                                        onBlur={handleBlur}
                                        onKeyPress={handleKeyPress}
                                        autoFocus
                                        style={{
                                            padding: "4px 8px",
                                            fontSize: "16px",
                                            fontWeight: "500",
                                            border: "1px solid #000",
                                            borderRadius: "5px",
                                            backgroundColor: "#f9f9f9",
                                            color: "#333",
                                            outline: "none",
                                            cursor: "pointer",
                                        }}
                                    />
                                    :
                                    <Stack>
                                        {opToInfo?.full_name}
                                    </Stack>
                                }
                            </Stack>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Stack sx={{ fontWeight: "700", fontSize: "20px", mr: "6px" }}>
                                {text.supInfoAddr}:
                            </Stack>
                            <Stack sx={{ fontSize: "20px" }} onDoubleClick={() => handleDoubleClick("address")}>
                                {isEditing == "address" ?
                                    <input 
                                        type="text"
                                        name="address"
                                        onBlur={handleBlur}
                                        onKeyPress={handleKeyPress}
                                        autoFocus
                                        style={{
                                            padding: "4px 8px",
                                            fontSize: "16px",
                                            fontWeight: "500",
                                            border: "1px solid #000",
                                            borderRadius: "5px",
                                            backgroundColor: "#f9f9f9",
                                            color: "#333",
                                            outline: "none",
                                            cursor: "pointer",
                                        }}
                                    />
                                    :
                                    <Stack>
                                        {opToInfo?.address}
                                    </Stack>
                                }
                            </Stack>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Stack sx={{ fontWeight: "700", fontSize: "20px", mr: "6px" }}>
                                {text.supInfoIns}:
                            </Stack>
                            <Stack sx={{ fontSize: "20px" }}>
                                {opToInfo?.date_inscription.slice(0,10)}
                            </Stack>
                        </div>
                        <div style={{ alignItems: 'center' }}>
                            <Stack sx={{ fontWeight: "700", fontSize: "20px", mr: "6px" }}>
                                {text.supInfoPay}:
                            </Stack>
                            <Stack sx={{ fontSize: "20px" }} onDoubleClick={() => handleDoubleClick("payment")}>
                                {isEditing == "payment" ?
                                    <select
                                        name="payment"
                                        onBlur={handleBlur}
                                        onKeyPress={handleKeyPress}
                                        autoFocus
                                        style={{
                                            padding: "4px 8px",
                                            fontSize: "16px",
                                            fontWeight: "500",
                                            border: "1px solid #000",
                                            backgroundColor: "#f9f9f9",
                                            color: "#333",
                                            outline: "none",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <option value="wise">wise</option>
                                        <option value="paypal">paypal</option>
                                        <option value="yoursafe">yoursafe</option>
                                        <option value="wireTransfer">wireTransfer</option>
                                    </select>
                                    :
                                    <Stack>
                                        {opToInfo?.payment.Name}
                                    </Stack>
                                }
                            </Stack>
                        </div>
                        {opToInfo?.payment.Name == "wireTransfer" ? 
                            <div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Stack sx={{ fontWeight: "700", fontSize: "20px", mr: "6px" }}>
                                        {text.supInfoNam}:
                                    </Stack>
                                    <Stack sx={{ fontSize: "20px" }} onDoubleClick={() => handleDoubleClick("bankname")}>
                                        {isEditing == "bankname" ?
                                            <input 
                                                type="text"
                                                name="bankname"
                                                onBlur={handleBlur}
                                                onKeyPress={handleKeyPress}
                                                autoFocus
                                                style={{
                                                    padding: "4px 8px",
                                                    fontSize: "16px",
                                                    fontWeight: "500",
                                                    border: "1px solid #000",
                                                    borderRadius: "5px",
                                                    backgroundColor: "#f9f9f9",
                                                    color: "#333",
                                                    outline: "none",
                                                    cursor: "pointer",
                                                }}
                                            />
                                            :
                                            <Stack>
                                                {opToInfo?.bankname ? opToInfo?.bankname : "no data"}
                                            </Stack>
                                        }
                                    </Stack>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Stack sx={{ fontWeight: "700", fontSize: "20px", mr: "6px" }}>
                                        IBAN:
                                    </Stack>
                                    <Stack sx={{ fontSize: "20px" }} onDoubleClick={() => handleDoubleClick("iban")}>
                                        {isEditing == "iban" ?
                                            <input 
                                                type="text"
                                                name="iban"
                                                onBlur={handleBlur}
                                                onKeyPress={handleKeyPress}
                                                autoFocus
                                                style={{
                                                    padding: "4px 8px",
                                                    fontSize: "16px",
                                                    fontWeight: "500",
                                                    border: "1px solid #000",
                                                    borderRadius: "5px",
                                                    backgroundColor: "#f9f9f9",
                                                    color: "#333",
                                                    outline: "none",
                                                    cursor: "pointer",
                                                }}
                                            />
                                            :
                                            <Stack>
                                                {opToInfo?.iban ? opToInfo?.iban : 'no data'}
                                            </Stack>
                                        }
                                    </Stack>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Stack sx={{ fontWeight: "700", fontSize: "20px", mr: "6px" }}>
                                        BIC:
                                    </Stack>
                                    <Stack sx={{ fontSize: "20px" }} onDoubleClick={() => handleDoubleClick("bic")}>
                                        {isEditing == "bic" ?
                                            <input 
                                                type="text"
                                                name="bic"
                                                onBlur={handleBlur}
                                                onKeyPress={handleKeyPress}
                                                autoFocus
                                                style={{
                                                    padding: "4px 8px",
                                                    fontSize: "16px",
                                                    fontWeight: "500",
                                                    border: "1px solid #000",
                                                    borderRadius: "5px",
                                                    backgroundColor: "#f9f9f9",
                                                    color: "#333",
                                                    outline: "none",
                                                    cursor: "pointer",
                                                }}
                                            />
                                            :
                                            <Stack>
                                                {opToInfo?.bic ? opToInfo?.bic : 'no data'}
                                            </Stack>
                                        }
                                    </Stack>
                                </div>
                            </div>
                            :
                            <div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Stack sx={{ fontWeight: "700", fontSize: "20px", mr: "6px" }}>
                                        {text.supInfoEm}:
                                    </Stack>
                                    <Stack sx={{ fontSize: "20px" }} onDoubleClick={() => handleDoubleClick("paymail")}>
                                        {isEditing == "paymail" ?
                                            <input 
                                                type="text"
                                                name="paymail"
                                                onBlur={handleBlur}
                                                onKeyPress={handleKeyPress}
                                                autoFocus
                                                style={{
                                                    padding: "4px 8px",
                                                    fontSize: "16px",
                                                    fontWeight: "500",
                                                    border: "1px solid #000",
                                                    borderRadius: "5px",
                                                    backgroundColor: "#f9f9f9",
                                                    color: "#333",
                                                    outline: "none",
                                                    cursor: "pointer",
                                                }}
                                            />
                                            :
                                            <Stack>
                                                {opToInfo?.paymail ? opToInfo?.paymail : "no data"}
                                            </Stack>
                                        }
                                    </Stack>
                                </div>
                            </div>
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='contained' 
                            sx={{ 
                                fontWeight: "700", 
                                bgcolor: "error.main",
                                color: "white",
                                "&:hover": {
                                    bgcolor: "error.dark",
                                } 
                            }} 
                            onClick={() => {setOpenInfo(false); setData([]); setOpToInfo()}}
                        >
                            {text.annuler}
                        </Button>
                        { data?.length != 0 && 
                            <Button 
                                variant='contained' 
                                sx={{ 
                                    fontWeight: "700", 
                                    bgcolor: "success.main", 
                                    color: "white",
                                    "&:hover": {
                                        bgcolor: "success.dark",
                                    } 
                                }} 
                                onClick={()=> handleInfo()}
                            >
                                {loading ? text.phload : "Update"}
                            </Button>
                        }
                    </DialogActions>
                </div>
            </CustomPopup>
            {/* pop up update */}
            <CustomPopup open={openUpdate} onClose={() => setOpenUpdate(false)}>
                <div style={{ padding: "18px 30px"}}>
                    <DialogTitle id="alert-dialog-title" sx={{textAlign: "center"}}>
                        <p style={{ fontWeight: "800", fontSize: "22px"}}>
                            {text.chngmdp} : {opToUpdate?.name}
                        </p>
                    </DialogTitle>
                    <DialogContent>
                        <div style={{ alignItems: 'center' }}>
                            <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", mr: "6px" }}>
                                {text.nwmdp}:
                            </Stack>
                            <input
                                style={{fontSize: "22px", fontWeight: "500", color: "#333333", padding: '10px', width: '100%'}}
                                onChange={(e)=>setNewPassword(e.target.value)}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='contained' 
                            sx={{ 
                                fontWeight: "700", 
                                bgcolor: "error.main",
                                color: "white",
                                "&:hover": {
                                    bgcolor: "error.dark",
                                } 
                            }} 
                            onClick={() => setOpenUpdate(false)}
                        >
                            {text.annuler}
                        </Button>
                        <Button 
                            variant='contained' 
                            sx={{ 
                                fontWeight: "700", 
                                bgcolor: "success.main", 
                                color: "white",
                                "&:hover": {
                                    bgcolor: "success.dark",
                                } 
                            }} 
                            onClick={()=> handleUpdate()}
                        >
                            {loading ? text.phload : text.cnfrm}
                        </Button>
                    </DialogActions>
                </div>
            </CustomPopup>
            {/* pop up suspendre */}
            <CustomPopup open={openSuspend} onClose={() => setOpenSuspend(false)}>
                <div style={{ padding: "18px 30px"}}>
                    <DialogTitle id="alert-dialog-title" sx={{textAlign: "center"}}>
                        <p style={{ fontWeight: "800", fontSize: "22px"}}>
                            {text.suspop} {opToSuspend?.name} ?
                        </p>
                    </DialogTitle>
                    <DialogActions>
                        <Button
                            variant='contained' 
                            sx={{ 
                                fontWeight: "700", 
                                bgcolor: "error.main",
                                color: "white",
                                "&:hover": {
                                    bgcolor: "error.dark",
                                } 
                            }} 
                            onClick={() => setOpenSuspend(false)}
                        >
                            {text.annuler}
                        </Button>
                        <Button 
                            variant='contained' 
                            sx={{ 
                                fontWeight: "700", 
                                bgcolor: "success.main", 
                                color: "white",
                                "&:hover": {
                                    bgcolor: "success.dark",
                                } 
                            }} 
                            onClick={()=> handleSuspend()}
                        >
                            {loading ? text.phload : text.cnfrm}
                        </Button>
                    </DialogActions>
                </div>
            </CustomPopup>
            {/* <Stack style={{display: 'flex', alignItems: 'center', gap: '15px', justifyContent: 'center', flexDirection: 'row'}}>
                <Typography variant="h1" alignSelf='center' style={{fontFamily: 'Fascinate Regular', fontWeight: 'bold', color: '#cb6ce6', WebkitTextStroke: '2px #5e0a76'}}
                >{text.agents}</Typography>
                <img className="card-img-resize-2" src={"/images/supervisor/2age.png"} alt="" height='50px' width='50px'/>
            </Stack> */}
            <Title>{text.agman}</Title>
            <BigContainerAccordion
                transparent
                summaryBg="#004aad"
                summary={text.aglist}
                details={
                    <Stack spacing={2}>
                        <SearchFilter
                            selectedDate={filter.date}
                            onDateChange={{
                                year: y => setFilter({...filter, year: y}),
                                month: m => setFilter({...filter, month: m}),
                                date: d => setFilter({...filter, date: d}),
                            }}
                            items= {[
                                { label: text.startingdate , comp: 'DatePicker', selected: filter.startingDate, onChange: d => setFilter({...filter, startingDate: d})},
                                { label: text.closingdate , comp: 'DatePicker', selected: filter.closingDate, onChange: d => setFilter({...filter, closingDate: d})},
                                { label: text.agents , comp: 'TextInput', onChange: e => setFilter({...filter, agents: e.target.value}) },
                                { label: text.platform , comp: 'TextInput', onChange: e => setFilter({...filter, platforms: e.target.value}) },
                                authState?.userRole === "administrator" && {label: text.language, comp: 'Select', choices: languagesList, onChange: e => setFilter({ ...filter, language: e.target.value }) },
                            ]}
                        />

                        <Stack spacing={'10px'}>
                            <TableRow header columns={[
                                { xs: 2,  text: text.date},
                                { xs: 1,  text: text.agname},
                                { xs: 1,  text: text.agcountry},
                                { xs: 1.5,  text: text.aglogin},
                                { xs: 1,  text: text.agloginpw},
                                { xs: 1,  text: text.langpf},
                                { xs: 1,  text: "company"},
                                { xs: 1,  text: text.status},
                                { xs: 1,  text: text.actions},
                            ]}/>
                            {
                                agents?.map((a, i) => 
                                    <TableRow key={'n' + i} columns={[
                                        { xs: 2,  text: a.date_inscription ? format(new Date(a.date_inscription), 'dd/MM/yyyy') : ''},
                                        { xs: 1,  text: a.name},
                                        { xs: 1,  text: a.country},
                                        { xs: 1.5,  text: a.mail},
                                        {
                                            xs: 1,
                                            customContent: true,
                                            content: <Button 
                                                onClick={()=>{ setOpToUpdate(a); setOpenUpdate(true)} }
                                                style={{width: '100%', height: '100%', textTransform: 'capitalize', color: '#000'}}
                                            >
                                                <Typography style={{fontFamily: 'Delm'}}>
                                                    {text.edit}
                                                </Typography>
                                            </Button>
                                        },
                                        { xs: 1,  text: a.language?.name},
                                        { xs: 1,  text: a.companyname},
                                        {
                                            xs: 1,
                                            customContent: true,
                                            content: 
                                            <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                                            {a.status === "suspend" ? (
                                                <>
                                                    <Typography style={{ color: 'red', fontWeight: 'bold', fontFamily: 'Delm' }}>
                                                        {(() => {
                                                            switch (a.status) {
                                                                case "suspend":
                                                                    return text.suspend;
                                                                case "free":
                                                                    return text.free;
                                                                case "disconnect":
                                                                    return text.disc;
                                                                case "fired":
                                                                    return text.fired;
                                                                default:
                                                                    return a.status; 
                                                            }
                                                        })()}
                                                    </Typography>
                                                    <Typography style={{ color: 'green', fontWeight: 'bold', fontFamily: 'Delm' }}>
                                                        {text.reactivaton} {new Date(a.suspend).toLocaleDateString()}
                                                    </Typography>
                                                </>
                                            ) : (
                                                <Typography style={{ color: 'red', fontWeight: 'bold', fontFamily: 'Delm' }}>
                                                    {(() => {
                                                        switch (a.status) {
                                                            case "suspend":
                                                                return text.suspend;
                                                            case "free":
                                                                return text.free;
                                                            case "disconnect":
                                                                return text.disc;
                                                            case "fired":
                                                                return text.fired;
                                                            default:
                                                                return a.status; 
                                                        }
                                                    })()}
                                                </Typography>
                                            )}
                                        </Box>                                        
                                        },
                                        {
                                            xs: 1,
                                            customContent: true,
                                            content: <Stack>
                                                <Button 
                                                    onClick={()=> {setOpToDec(a); setOpenDeconnect(true);}}
                                                    style={{width: '100%', height: '100%', textTransform: 'capitalize', color: '#000'}}
                                                >
                                                    <Typography style={{fontFamily: 'Delm'}}>
                                                        {text.deconnecter}
                                                    </Typography>
                                                </Button>
                                                <Button 
                                                    onClick={()=>{ setOpToSuspend(a); setOpenSuspend(true)} }
                                                    style={{width: '100%', height: '100%', textTransform: 'capitalize', color: '#000'}}
                                                >
                                                    <Typography style={{fontFamily: 'Delm'}}>
                                                        {text.susp}
                                                    </Typography>
                                                </Button>
                                                <Button 
                                                    onClick={()=>{ setOpToDelete(a); setOpenDelete(true)} }
                                                    style={{width: '100%', height: '100%', textTransform: 'capitalize', color: '#000'}}
                                                >
                                                    <Typography style={{fontFamily: 'Delm'}}>
                                                        {text.koff}
                                                    </Typography>
                                                </Button>
                                                <Button 
                                                    onClick={()=>{ setOpToInfo(a); setOpenInfo(true)} }
                                                    style={{width: '100%', height: '100%', textTransform: 'capitalize', color: '#000'}}
                                                >
                                                    <Typography style={{fontFamily: 'Delm'}}>
                                                        Info
                                                    </Typography>
                                                </Button>
                                            </Stack> 
                                        },
                                    ]}/>
                                )
                            }
                        </Stack>
                        <Stack justifyItems="center" alignItems="center" >
                            <Pagination 
                                page={page}
                                onChange={handleChange}
                                count={pagination?.total_pages}
                                variant="outlined" 
                                shape="rounded" 
                                color="secondary"
                            />
                        </Stack>
                    </Stack>
                }
            />
            <BigContainerAccordion 
                transparent
                summaryBg="#de5b6d"
                summary={text.pfsperf}
                details={
                    <Stack spacing={'10px'}>
                        <TableRow header columns={[
                            { xs: 1.6,  text: text.date},
                            { xs: 1.6,  text: text.platform},
                            { xs: 1.6, text: text.language },
                            { xs: 1.6,  text: text.agnumbewoon},
                            { xs: 1.6,  text: text.totsenmess},
                            { xs: 1.6,  text: text.totrecmess},
                            { xs: 1.6,  text: text.totrep},
                        ]}/>
                        {
                            platformsPerf.map((p, i) =>
                                <TableRow key={'p' + i} columns={[
                                    { xs: 1.6,  text: p.date},
                                    { xs: 1.6,  text: p.platform},
                                    { xs: 1.6, text: p.language ? p.language.charAt(0).toUpperCase() + p.language.charAt(1) : 'Fr' },
                                    { xs: 1.6,  text: p.numberOfFakeProfil},
                                    { xs: 1.6,  text: p.numberOfMessageSent},
                                    { xs: 1.6,  text: p.numberOfMessageReceived},
                                    { xs: 1.6,  text: p.numberOfReport},
                                ]}/>
                            )
                        }
                    </Stack>
                }
            />
            <BigContainerAccordion 
                transparent
                summaryBg="#ff3131"
                summary={text.agperf}
                details={
                    <Stack spacing={2}>
                        <SearchFilter
                            selectedDate={filter2.date}
                            onDateChange={{
                                year: y => setFilter2({...filter2, year: y}),
                                month: m => setFilter2({...filter2, month: m}),
                            }}
                            items= {[
                                { label: text.platform , comp: 'TextInput', onChange: e => setFilter2({...filter2, platforms: e.target.value}) },                    
                                { label: text.language, comp: 'Select', choices: languagesList, onChange: e => setFilter2({ ...filter2, language: e.target.value }) },
                                { label: text.agent, comp: 'TextInput', onChange: e => setFilter2({...filter2, agents: e.target.value}) },
                                // { label: text.paymeth, comp: 'Select', choices: paymentList, onChange: e => setFilter2({...filter2, payment: e.target.value}) },
                            ]}
                        />
                        <Stack spacing={'10px'}>
                            <TableRow header columns={[
                                { xs: 1.4,  text: text.agentid},
                                { xs: 1.4,  text: text.login},
                                { xs: 1.4,  text: text.language},
                                { xs: 1.4,  text: text.platform},
                                { xs: 1.4,  text: text.totsenmess},
                                { xs: 1.4,  text: "Total poke messages"},
                                { xs: 1.4,  text: "Total teaser messages"},
                                { xs: 1.4,  text: "Total stop messages"},
                                // { xs: 0.9,  text: "Total paid messages"},
                                // { xs: 0.9,  text: text.bonus},
                                // { xs: 0.9,  text: "Total payment"},
                                // { xs: 0.9,  text: text.paymeth},
                            ]}/>
                            { datas?.map((data, i) => (
                                <TableRow
                                    key={i}
                                    columns={[
                                        { xs: 1.4, text: data.name },
                                        { xs: 1.4, text: data.login },
                                        { xs: 1.4, text: data.language },
                                        {
                                            xs: 1.4,
                                            customContent: true,
                                            content: (
                                                <Stack>
                                                    {data.dataPlatform.map((d, index) => (
                                                        <Typography key={index}>{d.platform}</Typography>
                                                    ))}
                                                </Stack>
                                            ),
                                        },
                                        {
                                            xs: 1.4,
                                            customContent: true,
                                            content: (
                                                <Stack>
                                                    {data.dataPlatform.map((d, index) => (
                                                        <Typography key={index}>{d.sentMessage}</Typography>
                                                    ))}
                                                </Stack>
                                            ),
                                        },
                                        {
                                            xs: 1.4,
                                            customContent: true,
                                            content: (
                                                <Stack>
                                                    {data.dataPlatform.map((d, index) => (
                                                        <Typography key={index}>{d.pokeMessage}</Typography>
                                                    ))}
                                                </Stack>
                                            ),
                                        },
                                        {
                                            xs: 1.4,
                                            customContent: true,
                                            content: (
                                                <Stack>
                                                    {data.dataPlatform.map((d, index) => (
                                                        <Typography key={index}>{d.teaserMessage}</Typography>
                                                    ))}
                                                </Stack>
                                            ),
                                        },
                                        {
                                            xs: 1.4,
                                            customContent: true,
                                            content: (
                                                <Stack>
                                                    {data.dataPlatform.map((d, index) => (
                                                        <Typography key={index}>{d.stopMessage}</Typography>
                                                    ))}
                                                </Stack>
                                            ),
                                        },
                                        // { xs: 0.9, text: `${data.totalPaid} €` },
                                        // { xs: 0.9, text: `${data.bonus } €`},
                                        // { xs: 0.9, text: `${data.totalPayment} €` },
                                        // { xs: 0.9, text: data.paymentMethod },
                                    ]}
                                />
                            ))}
                        </Stack>
                        <Stack justifyItems="center" alignItems="center" >
                            <Pagination
                                page={page2}
                                onChange={handleChange2}
                                count={pagination2?.total_pages}
                                variant="outlined" 
                                shape="rounded" 
                                color="secondary"
                            />
                        </Stack>
                    </Stack>
                }
            />
            <BigContainerAccordion 
                transparent
                summaryBg="#1cb743"
                summary={text.nwag}
                details={
                    <Stack spacing={2}>
                        <Typography alignSelf="center" sx={{ color: "red"}}>{error}</Typography>
                        <FormRow label={text.role} comp="Select" control={control} options={roleList} name="role" error={errors.role} />
                        <FormRow label={text.lgn} control={control} name="login" error={errors.login} />
                        <FormRow label={text.mail} control={control} name="mail" error={errors.mail} />
                        <FormRow label={text.agname} control={control} name="name" error={errors.name} />
                        <FormRow label={text.supInfoFull} control={control} name="fullName" error={errors.name} />
                        <FormRow label={text.supInfoAddr} control={control} name="fullAddress" error={errors.name} />
                        <FormRow label={text.pays} comp="Select" options={countriesList} control={control} name="country" error={errors.country} />
                        <FormRow label={text.language} comp="Select" options={languagesgifList} control={control} name="languageName" error={errors.languageName} />
                        <FormRow label= {text.paymeth} comp="Select" options={paymentList} control={control} name="paymentName" error={errors.paymentName} />
                        {
                            (wPaye==paymentList[0].value) ?
                                <>
                                    <FormRow label= {text.bankn} control={control} name="bankname" error={errors.bankname} />
                                    <FormRow label= {text.iban} control={control} name="iban" error={errors.iban} />
                                    <FormRow label= {text.bic} control={control} name="bic" error={errors.bic} />
                                </>
                            :<FormRow label= {text.paymail} control={control} name="paymail" error={errors.paymail} />
                        }
                        <FormRow label={text.password} comp="Password" control={control} name="password" error={errors.password} />
                        <FormRow label={text.confirmpass} comp="Password" control={control} name="confirmpassword" error={errors.confirmpassword} />
                        <Stack style={{width: '85%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
                            <Button onClick={handleSubmit(newAgent)} variant="formrow">
                                <Typography>
                                    {text.crnwag}
                                </Typography>
                            </Button>
                        </Stack>
                    </Stack>
                }
            />
        </Stack>
    )
}

export default Agents