import React, {useContext, useEffect, useState} from "react";
import { Stack, Button, Typography } from "@mui/material";
import useLanguage from "../../hooks/useLanguage";
import Title from "./Title";
import TableRow from "../../tools/TableRow";
import { StatContext } from "../../state/StatContext";
import TimeSelect from "../../tools/TimeSelect";
import Pusher from "pusher-js";
import { apiURL, CLUSTER, PUSHER_SECRET } from "../../../helper/conf";
import { AuthStateContext } from "../../state/AuthStateContext";
import axiosInstance from "../../../axios";
import { checkNumber, formatDate2 } from "../../../helper/formatDate";


export default ({onDetails}) => {
    const [filter, setFilter] = useState({
        year: "",
        month: "",
        date: ""
    })
    const { text } = useLanguage()
    const [ conversations, setConversations ] = useState();
    const [ total, setTotal ] = useState()
    const { authState } = useContext(AuthStateContext)

    // GET STATISTIQUE ACCOUTANTS
    useEffect(() => {
        const date = formatDate2(filter.date);
        axiosInstance.get(`/statistic/conversation?year=${filter.year}&month=${filter.month}&date=${date}`, {
            headers: { 
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then((resp) => {
            setConversations(resp.data.data.conversations)
            setTotal(resp.data.total)
        })
        .catch((error) => {
            console.log(error)
        })
    }, [filter])

    //PUSHER STATISTIC CONVERSATION
    const [ data, setData ] = useState()
    useEffect(()=>{
        const pusher = new Pusher( PUSHER_SECRET, {
            cluster: CLUSTER,
            channelAuthorization:{
                endpoint: `${apiURL}/pusher/user-auth`,
                transport: 'jsonp'
            }
        })
        const channel = pusher.subscribe("private-statistic2")
        channel.bind(`${authState?.userId}`, (data)=> {
            if(data.name == "conversations"){
                setData(data)
            }
        })
        return()=>{
            channel.unbind()
            pusher.unsubscribe("private-statistic2")
        }
    },[])

    useEffect(()=>{
        if (data) {
            setConversations((prev) => {
                const updatedStat = JSON.parse(JSON.stringify(prev));
                updatedStat[data.language][data.platform][data.field] += data.value;
                return updatedStat;
            });
        }
    }, [data])

    const languages = Object.keys(conversations ?? {});

    const StatConvTab = (language) => {
        const platforms = Object.keys(conversations[language] ?? {});
        return (
            <TableRow columns={[
                { xs: 2.9,
                    customContent: true,
                    content: <Stack>{language.toUpperCase()}</Stack>
                },
                { xs: 2.9,
                    customContent: true,
                    content: <Stack>
                        {platforms.map(platform => (
                            <Typography key={platform}>
                                {platform.charAt(0).toUpperCase() + platform.slice(1)}
                            </Typography>
                        ))}

                    </Stack>
                },
                { xs: 2.9,
                    customContent: true,
                    content: <Stack>
                        {platforms.map(platform => (
                            <Typography  key={platform}>
                                {checkNumber(conversations[language][platform]?.treated)}
                            </Typography>
                        ))}
                    </Stack>
                },
                { xs: 2.9,
                    customContent: true,
                    content: <Stack>
                        {platforms.map(platform => (
                            <Typography key={platform}>
                                {checkNumber(conversations[language][platform]?.onHold)}
                            </Typography>
                        ))}
                    </Stack>
                },
            ]}/>
        );
    };

    return <Stack padding="2%" spacing={3} height="100%" style={{ overflowY: "scroll" }}>
        <Title>{text.convman}</Title>
        <Stack direction="row" justifyContent="space-between">
            <TimeSelect type='year' onChange={e => {setFilter({ ...filter, year: e.target.value})}}/>
            <TimeSelect type='month' onChange={e => setFilter({ ...filter, month: e.target.value})}/>
            <TimeSelect type='date' selected={filter.date} onChange={e => setFilter({ ...filter, date: e})}/>
            <Button
                variant="contained"
                onClick={() => setFilter({
                    year: "",
                    month: "",
                    date: ""
                })}
            >
                {text.Alld}
            </Button>
        </Stack>
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{
                padding: 2,
                backgroundColor: "#f5f5f5",
                borderRadius: "8px",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            }}
        >
            <div>
                <span style={{ fontSize: "18px", fontWeight: "bold", color: "#3f51b5" }}>
                    {text.nbrEnv} :
                </span>
                <span style={{ marginLeft: "8px", fontWeight: "bold", fontSize: "1.2rem", color: "#000" }}>
                    {total ? checkNumber(total?.nbrSent) : 0}
                </span>
            </div>
            <div>
                <span style={{ fontSize: "18px", fontWeight: "bold", color: "#3f51b5" }}>
                    {text.nbrRecu} :
                </span>
                <span style={{ marginLeft: "8px", fontWeight: "bold", fontSize: "1.2rem", color: "#000" }}>
                    {total ? checkNumber(total?.nbrReceive) : 0}
                </span>
            </div>
        </Stack>
        <Stack spacing={1}>
            <TableRow header columns={[
                { xs: 2.9,  text: text.language},
                { xs: 2.9,  text: text.platform},
                { xs: 2.9,  text: text.treated},
                { xs: 2.9,  text: text.onhld},
            ]}/>
            {languages.map((language) => (<Stack key={language}>{StatConvTab(language)}</Stack>))}
        </Stack>

        <Button onClick={onDetails} style={{ backgroundColor: '#feba3b', textTransform: 'capitalize', color: '#000', borderRadius: '15px', border: '2px solid #fff', width: 'fit-content', alignSelf: 'center'}}><Typography style={{fontFamily: 'Chewy Pro'}}>{ text.details}</Typography></Button>
    </Stack>
}

