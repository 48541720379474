import React, { useContext, useEffect, useState } from "react";
import { Stack, Typography, Button, Pagination, TableContainer, Table, TableHead, TableCell, TableBody, Paper } from "@mui/material";
import useLanguage from '../hooks/useLanguage'
import Header1 from "../Header/Header1";
import SearchFilter from "../tools/SearchFilter";
import TableRow from "../tools/TableRow";
import useData from "../hooks/useData";
import { useNavigate } from "react-router-dom";
import RoutesList from "../../helper/routes";
import axiosInstance from "../../axios";
import { AuthStateContext } from "../state/AuthStateContext";
import { baseURL } from "../../helper/conf";
import { formatDate, formatDate2 } from "../../helper/formatDate";
    

const Statistics = ({setSee}) => {
    const { text } = useLanguage()
    // const [see,setSee] = useState(false)
    const { authState } = useContext(AuthStateContext)

    const [filter, setFilter] = useState({
        year: '',
        month: '',
        date: '',
        startingDate: '',
        closingDate: '',
        platforms: '',
        week: '',
    })

    // GET MESSAGES
    const [ messages, setMessages ] = useState()
    const [ total, setTotal ] = useState()
    const [ pagination, setPagination ] = useState()
    const [page, setPage] = useState(1);
    const handleChange = (event, value) => {
        setPage(value);
    };

    useEffect(()=>{
        axiosInstance.get(`/operateur/stat?year=${filter?.year}&month=${filter?.month}&date=${formatDate2(filter?.date)}&platform=${filter?.platforms}&week=${filter?.week}&startingDate=${formatDate2(filter?.startingDate)}&closingDate=${formatDate2(filter?.closingDate)}`, {
            headers: {
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then((response) => {
            // console.log(response.data)
            setMessages(response.data.data)
            setTotal(response.data.total)
            // setPagination(response.data.pagination)
        })
        .catch((error) => {
            // console.log(error)
        })
    },[filter])

 
    return(
        <Stack width='100%' minHeight="1300px" sx={{top:205,position: "absolute", zIndex: 29, background: "white"}} spacing={2} padding="10px 0" >
            <Stack display='flex' flexDirection='row' justifyContent='space-between' width="100%" height="50px" alignItems="center" style={{backgroundColor: "#d48b70"}}>
                <div className="none"></div>
                <Typography variant="whitetitle" >{ text.operatorid } {authState.userName} {text.stat}</Typography>
                <Button onClick={()=> setSee(false)} style={{marginRight: "20px", backgroundColor: '#feba3b', alignSelf: 'center', textTransform: 'capitalize', color: '#000', borderRadius: '15px', border: '2px solid #fff'}}><Typography fontWeight="bold">{text.back}</Typography></Button>
            </Stack>
            <Stack spacing={3} padding="10px">
                <SearchFilter
                    onSemaineClick={(week)=>setFilter({...filter, week: week})}
                    selectedDate={filter.date}
                    onDateChange={{
                        year: y => setFilter({...filter, year: y}),
                        month: m => setFilter({...filter, month: m}),
                        date: d => setFilter({...filter, date: d}),
                    }}
                    items= {[
                        { label: text.startingdate , comp: 'DatePicker', selected: filter.startingDate, onChange: d => setFilter({...filter, startingDate: d})},
                        { label: text.closingdate , comp: 'DatePicker', selected: filter.closingDate, onChange: d => setFilter({...filter, closingDate: d})},
                        { label: text.platform , comp: 'TextInput', onChange: e => setFilter({...filter, platforms: e.target.value}) },
                    ]}
                />
                <Stack spacing={'10px'} padding="10px 0" >
                    <TableRow header columns={[
                        { xs: 1.4,  text: text.tmslt},
                        { xs: 1.4,  text: text.platform},
                        { xs: 1.4,  text: "Sent message"},
                        { xs: 1.4,  text: "Poke message"},
                        { xs: 1.4,  text: "Teaser message"},
                        { xs: 1.4,  text: "Stop message"},
                        { xs: 1.4,  text: "Total message sent"},
                        { xs: 1.4,  text: text.recmess},
                    ]}/>
                    { messages?.map((d, i) => (
                        <TableRow
                            key={i}
                            columns={[
                                { xs: 1.4, text: d.tranche },
                                {
                                    xs: 1.4,
                                    customContent: true,
                                    content: (
                                    <Stack>
                                        {d.data.map((dd, index) => (
                                            <Typography key={index}>{dd.platform}</Typography>
                                        ))}
                                    </Stack>
                                    ),
                                },
                                {
                                    xs: 1.4,
                                    customContent: true,
                                    content: (
                                    <Stack>
                                        {d.data.map((dd, index) => (
                                            <Typography key={index}>{dd.sentMessage}</Typography>
                                        ))}
                                    </Stack>
                                    ),
                                },
                                {
                                    xs: 1.4,
                                    customContent: true,
                                    content: (
                                    <Stack>
                                        {d.data.map((dd, index) => (
                                            <Typography key={index}>{dd.pokeMessage}</Typography>
                                        ))}
                                    </Stack>
                                    ),
                                },
                                {
                                    xs: 1.4,
                                    customContent: true,
                                    content: (
                                    <Stack>
                                        {d.data.map((dd, index) => (
                                            <Typography key={index}>{dd.teaserMessage}</Typography>
                                        ))}
                                    </Stack>
                                    ),
                                },
                                {
                                    xs: 1.4,
                                    customContent: true,
                                    content: (
                                    <Stack>
                                        {d.data.map((dd, index) => (
                                            <Typography key={index}>{dd.stopMessage}</Typography>
                                        ))}
                                    </Stack>
                                    ),
                                },
                                {
                                    xs: 1.4,
                                    customContent: true,
                                    content: (
                                    <Stack>
                                        {d.data.map((dd, index) => (
                                            <Typography key={index}>{dd.totalSent}</Typography>
                                        ))}
                                    </Stack>
                                    ),
                                },
                                {
                                    xs: 1.4,
                                    customContent: true,
                                    content: (
                                    <Stack>
                                        {d.data.map((dd, index) => (
                                            <Typography key={index}>{dd.totalReceive}</Typography>
                                        ))}
                                    </Stack>
                                    ),
                                },
                            ]}
                        />
                    )) }
                </Stack>

                {/* total */}
                <TableRow
                    columns={[
                        {
                            xs: 1.4,
                            customContent: true,
                            content: (
                                <Stack sx={{ paddingTop: '14px', paddingBottom: '14px', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#b39ddb', width: '100%', height: '100%' }}>
                                    <Typography>GROSS</Typography>
                                    <Typography>TOTAL</Typography>
                                </Stack>
                            ),
                        },
                        {
                            xs: 1.4,
                            customContent: true,
                            content: (
                                <Stack sx={{ paddingTop: '14px', paddingBottom: '14px', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#b39ddb', width: '100%', height: '100%' }}>
                                    <Typography>ALL</Typography>
                                    <Typography>PLATFORM</Typography>
                                </Stack>
                            ),
                        },
                        {
                            xs: 1.4,
                            customContent: true,
                            content: (
                                <Stack sx={{ paddingTop: '14px', paddingBottom: '14px', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#d1c4e9', width: '100%', height: '100%' }}>
                                    <Typography>{total?.sentMessage}</Typography>
                                </Stack>
                            ),
                        },
                        {
                            xs: 1.4,
                            customContent: true,
                            content: (
                                <Stack sx={{ paddingTop: '14px', paddingBottom: '14px', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#d1c4e9', width: '100%', height: '100%' }}>
                                    <Typography>{total?.pokeMessage}</Typography>
                                </Stack>
                            ),
                        },
                        {
                            xs: 1.4,
                            customContent: true,
                            content: (
                                <Stack sx={{ paddingTop: '14px', paddingBottom: '14px', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#d1c4e9', width: '100%', height: '100%' }}>
                                    <Typography>{total?.teaserMessage}</Typography>
                                </Stack>
                            ),
                        },
                        {
                            xs: 1.4,
                            customContent: true,
                            content: (
                                <Stack sx={{ paddingTop: '14px', paddingBottom: '14px', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#d1c4e9', width: '100%', height: '100%' }}>
                                    <Typography>{total?.stopMessage}</Typography>
                                </Stack>
                            ),
                        },
                        {
                            xs: 1.4,
                            customContent: true,
                            content: (
                                <Stack sx={{ paddingTop: '14px', paddingBottom: '14px', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#e53935', width: '100%', height: '100%' }}>
                                    <Typography>{total?.totalSent}</Typography>
                                </Stack>
                            ),
                        },
                        {
                            xs: 1.4,
                            customContent: true,
                            content: (
                                <Stack sx={{ paddingTop: '14px', paddingBottom: '14px', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#52b202', width: '100%', height: '100%' }}>
                                    <Typography>{total?.totalReceive}</Typography>
                                </Stack>
                            ),
                        },
                    ]}
                />

                <Stack justifyItems="center" alignItems="center" >
                    <Pagination
                        page={page}
                        onChange={handleChange}
                        count={pagination?.total_pages}
                        variant="outlined" 
                        shape="rounded" 
                        color="secondary"
                    />
                </Stack>
            </Stack>

            {/* <Button onClick={()=> navigate(RoutesList.operator)} style={{backgroundColor: '#feba3b', alignSelf: 'center', textTransform: 'capitalize', color: '#000', borderRadius: '15px', border: '2px solid #fff'}}><Typography fontWeight="bold">{text.back}</Typography></Button> */}
            <Button onClick={()=> setSee(false)} style={{backgroundColor: '#feba3b', alignSelf: 'center', textTransform: 'capitalize', color: '#000', borderRadius: '15px', border: '2px solid #fff'}}><Typography fontWeight="bold">{text.back}</Typography></Button>

        </Stack>
    )
} 

export default Statistics