import React, {useContext, useEffect, useState} from "react";
import { Stack, Typography, Button, Pagination } from "@mui/material";
import AgentStat from "./AgentStat";
import WireTransfer from "./WireTransfer";
import Paypal from "./Paypal";
import Wise from "./Wise";
import Yoursafe from "./Yoursafe";
import AddAccountant from "./AddAccountant";
import BigContainerAccordion from "../tools/BigContainerAccordion";
import useLanguage from "../hooks/useLanguage";
import useList from "../hooks/useList";
import { format } from "date-fns";
import TableRow from "../tools/TableRow";
import SearchFilter from "../tools/SearchFilter";
import useData from "../hooks/useData";
import { GifsContext } from "../state/gifContext";
import RateAndBonus from "./RateAndBonus"
import axiosInstance from "../../axios";
import { AuthStateContext } from "../state/AuthStateContext";
import { roles } from "../../helper/roles"
import InvoiceAuto from "./InvoiceAuto";
import InvoiceStatement from "./InvoiceStatement";
import Title from "../Admin2/Comps/Title";

const AccountantBody = ({admin=true}) => {
    const { globalFilter, setGlobalFilter } = useContext(GifsContext)
    const { authState } = useContext(AuthStateContext)
    const {text} = useLanguage()
    const { languagesList } = useData()
    const {accountantList, getAccountantList} = useList()
    const [ statement, setStatement] = useState()

    const [filter, setFilter] = useState({
        year: new Date().getFullYear() + 1,
        month: 1,
        date: '',
        startingDate: '',
        closingDate: '',
        platforms: '',
        language: '',
        idconv: '',
        idmess: '',
    })

    // GET LIST ACCOUNTANT
    const [ page, setPage ] = useState(1)
    const [ pagination, setPagination ] = useState()
    const [ comptable, setComptable ] = useState([])
    const handleChange = (event, value) => {
        setPage(value);
    };
    useEffect(()=>{
        axiosInstance.get(`/user/${roles.accountant}?page=${page}`, { 
            headers: {
                'Authorization': `Bearer ${authState.token}`
            }
        })
        .then((response)=>{
            setComptable(response.data.data)
            setPagination(response.data.pagination)
        })
        .catch((error)=>{
            // console.log(error)
        })
    }, [page])


    return <Stack style={{ overflowY: "scroll" }}>
        <Stack display="flex" flexDirection="row" justifyContent="center" alignItems="center" gap={2} paddingBottom="20px">
            {/* <Typography style={{fontFamily: 'Fascinate Regular', fontWeight: 'bold', color: '#c27b7d', 
                WebkitTextStroke: '2px #770e10', textShadow: "3px 3px 0px #c17678"}} variant="h1" >{text.acc}
            </Typography> */}
            <Title>{text.acc}</Title>
            {/* <img src="/images/accountant/4.png" alt="" width="5%"/> */}
        </Stack>
        {
            admin ? 
                <>
                    <BigContainerAccordion transparent
                        summaryBg="#0097b2"
                        summary={text.acc8}
                        details={ <RateAndBonus />}
                    /> 
                </>
                : <></>
        }

        <BigContainerAccordion transparent
            summaryBg="#1e3d58"
            summary={text.acc1}
            details={ <AgentStat /> }
        />
        <BigContainerAccordion  transparent
            summaryBg="#ff3131"
            summary={text.acc2}
            details={ <WireTransfer /> }
        />
        <BigContainerAccordion  transparent
            summaryBg="#00bf15"
            summary={text.acc3}
            details={ <Paypal />}
        />
        <BigContainerAccordion transparent 
            summaryBg="#f4a100"
            summary={text.acc4}
            details={ <Wise />}
        />
        <BigContainerAccordion transparent 
            summaryBg="#1e57be"
            summary={text.acc5}
            details={ <Yoursafe />}
        />
        <BigContainerAccordion transparent
            summaryBg="#ec407a"
            summary="Invoice Automation System"
            details={ <InvoiceAuto setStatement={setStatement} />}
        />
        <BigContainerAccordion transparent
            summaryBg="#ff3d00"
            summary="Invoice Statement"
            details={ <InvoiceStatement statement={statement} />}
        />
        {
            admin ? 
            <>
                <BigContainerAccordion transparent 
                    summaryBg="#cb6ce6"
                    summary={text.acc6}
                    details={ <AddAccountant setComptable={setComptable}/>}
                /> 
                <BigContainerAccordion transparent 
                    summaryBg="#34d4bb"
                    summary={text.lstacc}
                    details={
                        <Stack spacing={2}>
                            <SearchFilter
                                selectedDate={filter.date}
                                onDateChange={{
                                    year: y => setFilter({...filter, year: y}),
                                    month: m => setFilter({...filter, month: m}),
                                    date: d => setFilter({...filter, date: d}),
                                }}
                                items= {[
                                    { label: text.startingdate , comp: 'DatePicker', selected: filter.startingDate, onChange: d => setFilter({...filter, startingDate: d})},
                                    { label: text.closingdate , comp: 'DatePicker', selected: filter.closingDate, onChange: d => setFilter({...filter, closingDate: d})},
                                    { label: text.platform , comp: 'TextInput', onChange: e => setFilter({...filter, platforms: e.target.value}) },
                                    { label: text.language , comp: 'Select', choices: languagesList, onChange: l => setFilter({...filter, language: l }) },
                                ]}
                            />
                            <Stack spacing={'10px'}>
                                <TableRow header columns={[
                                    { xs: 1.83,  text: text.date},
                                    { xs: 1.83,  text: text.platform},
                                    { xs: 1.83,  text: text.language},
                                    { xs: 1.83,  text: text.accnm},
                                    { xs: 1.83,  text: text.acclgn},
                                    { xs: 1.83,  text: text.acclgnpsswrd},
                                ]}/>
                                {
                                    comptable.map(s =>
                                        <TableRow key={s.id} columns={[
                                            { xs: 1.83,  text: s.date_inscription ? format(new Date(s.date_inscription), 'dd/MM/yyyy') : ''},
                                            { xs: 1.83,  text: s.platform},
                                            { xs: 1.83,  text: s.language?.name},
                                            { xs: 1.83,  text: s.name},
                                            { xs: 1.83,  text: s.mail},
                                            {
                                                xs: 1.83,
                                                customContent: true,
                                                content: <Stack width='100%'>
                                                    <Button style={{width: '100%', height: '100%', textTransform: 'capitalize', color: '#000'}}><Typography style={{fontFamily: 'Delm'}}>{text.edit}</Typography></Button>                       
                                                    <Button style={{width: '100%', height: '100%', textTransform: 'capitalize', color: '#000'}}><Typography style={{fontFamily: 'Delm'}}>{text.dlt}</Typography></Button>                       
                                                </Stack>
                                            },
                                        ]}/>
                                    )
                                }
                            </Stack>
                            <Stack justifyItems="center" alignItems="center" >
                                <Pagination 
                                    page={page}
                                    onChange={handleChange}
                                    count={pagination?.total_pages}
                                    variant="outlined" 
                                    shape="rounded" 
                                    color="secondary"
                                />
                            </Stack>
                        </Stack>
                    }
                />
            
            </>
            : <></>
        }
        
    </Stack> 
}

export default AccountantBody