import { Button, Pagination, Stack, Typography,Dialog, DialogActions, DialogTitle, Box, DialogContent } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import useLanguage from "../hooks/useLanguage";
import BigContainerAccordion from "../tools/BigContainerAccordion";
import SearchFilter from "../tools/SearchFilter";
import TableRow from "../tools/TableRow";
import useList from "../hooks/useList";
import axiosInstance from "../../axios";
import { AuthStateContext } from "../state/AuthStateContext";
import Pusher from 'pusher-js'
import { CLUSTER, PUSHER_SECRET, apiURL } from "../../helper/conf";
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import { format } from "date-fns";
import useData from "../hooks/useData";
import { formatDate } from "../../helper/formatDate";
import { GifsContext } from "../state/gifContext";
import { toNameCase } from "../tools/Snackbars";
import SlidingTypography from "../tools/SlidingTypography";
import Title from "../Admin2/Comps/Title";

const Conversations = () => {
    const { globalFilter } = useContext(GifsContext)
    const { text } = useLanguage()
    const {languagesList} = useData()
    const { authState } = useContext(AuthStateContext)

    const [filter, setFilter] = useState(() => ({
        ...globalFilter,
        users: '',
        idconv: '',
        idmess: ''
    }))
    const [filter1, setFilter1] = useState(() => ({
        ...globalFilter,
        users: '',
        idconv: '',
        idmess: ''
    }))

    //GET ALL CONVERSATION
    const [conversation, setConversation] = useState(null)
    const [pagination, setPagination] = useState()
    const [page, setPage] = useState(1);
    const handleChange = (event, value) => {
        setPage(value);
    }; 
    useEffect(() => {
        if(conversation) getConversation()
    }, [page, filter])

    //GET ALL ON_HOLD_CONVERSATION
    const [holdConversation, setHoldConversation] = useState(null)
    const [paginationHold, setPaginationHold] = useState()
    const [pageHold, setPageHold] = useState(1);
    const handleChangeHold = (event, value) => {
        setPageHold(value);
    };
    const getConversation = () => {
        axiosInstance.get(`/conversation/treated?year=${filter?.year}&month=${filter?.month}&date=${formatDate(filter?.date).split('-')[0]}&startingDate=${formatDate(filter?.startingDate)}&closingDate=${formatDate(filter?.closingDate)}&platforms=${filter?.platforms}&language=${filter?.language}&fakeProfil=${filter?.users}&conversation=${filter?.idconv}&message=${filter?.idmess}&agents=${filter?.agents}&page=${page}`, {
            headers: {
                'Authorization': `Bearer ${authState.token}`,
            },
        })
            .then((response) => {
                // console.log(response.data.data)
                setConversation(response.data.data)
                setPagination(response.data.pagination)
            })
            .catch((error) => {
                // console.log(error)
            })
    }
    const getHoldConversation = async() => {
        await axiosInstance.get(`/conversation_on_hold?year=${filter1?.year}&month=${filter1?.month}&date=${formatDate(filter1?.date).split('-')[0]}&startingDate=${formatDate(filter1?.startingDate)}&closingDate=${formatDate(filter1?.closingDate)}&platforms=${filter1?.platforms}&language=${filter1?.language}&fakeProfil=${filter1?.users}&conversation=${filter1?.idconv}&message=${filter1?.idmess}&page=${pageHold}`, {
            headers: {
                'Authorization': `Bearer ${authState.token}`,
            },
        })
            .then((response) => {
                // console.log(response.data)
                setHoldConversation(response.data.data)
                setPaginationHold(response.data.pagination)
            })
            .catch((error) => {
                // console.log(error)
            })
    }
    useEffect(() => {
        if(conversation)
        getHoldConversation()
    }, [pageHold, filter1])
    const getAllData = async() => {
        await getHoldConversation()
        getConversation()
    }
    //PUSHER ADD ON_HOLD
    useEffect(() => {
        const pusher = new Pusher(PUSHER_SECRET, { cluster: CLUSTER, channelAuthorization: { endpoint: `${apiURL}/pusher/user-auth`, transport: 'jsonp' } })
        const channel = pusher.subscribe('private-conversation')
        channel.bind(`add-hold-${authState.userId}`, (data) => {
            setHoldConversation((last) => [data,...last])
        })
        channel.bind(`add-treated-${authState.userId}`, (data) => {
            if(data && data.length)
            data.forEach(p => {
                if(p.wait_id) setHoldConversation(k => k.filter(l => l.message_id !== p.wait_id))
                setConversation((last) => [p, ...last])})
        })
        channel.bind(`change-status-${authState.userId}`, (data) => {
            if(data)
                data.id.forEach(l => setHoldConversation(p => p.map(k => k.message_id === l ? {...k,status:data.type}:k)))
        })
        getAllData()
        return () => {
            channel.unbind()
            pusher.unsubscribe('private-conversation')
        }
    }, [])

    const selectMsg = (n,conv) => {
        setOpenDialog({status:true,type:1,data:n,type_conv:conv})
    }
    const deleteMsg = async () => {
        const n = openDialog.data
        // console.log(openDialog)
        const data = {id_msg:openDialog.type_conv ? n.message_id:n.id,type:openDialog.type_conv}
        await axiosInstance.post(`/message/delete`,data, {
            headers: {
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then(() => {
            openDialog.type_conv ? setConversation(conversation.filter(p => p.message_id !== n.message_id)) : setHoldConversation(p => p.filter(k => k.id !== n.id))
            setOpen(true)
            closeODialog()
        })
        .catch(e => {})
    }
    const [open, setOpen] = useState(false)
    const [openDialog,setOpenDialog] = useState({status:false,data:null,type:1})
    
    
    const closeODialog = () => setOpenDialog({...openDialog,status:false})
    const clickT = (number) => {
        setOpenDialog({status:true,type:2,data:number})
    }
    const title = () => {
        if(openDialog.status){
            return `${openDialog.type === 1 ? text.supprmess : text.messinfo} : ${openDialog.data.message_id}`
        }
    }
    const afficheSR = (n) => {
        return openDialog.status && ((n === 1 ? openDialog.data.sender_profil.Name : openDialog.data.receiver_profil.Name)+' '+((openDialog.data.operator && (openDialog.data.type !== n)) ? '('+text.operator+' : '+openDialog.data.operator+')':''))
    }
    const checkTp = (n) => {
        if(n === 'teaser' || n === 'poke' || n === 'stop') return true
        return false
    }
    const tableContentMess = (c) => {
        if(!c.type_mess || c.type_mess === 'message') return {xs:1.1,text:c.content}
        else{
            const type = c.type_mess === 'teaser' ? 0 : c.type_mess === 'poke' ? 1 : 2
             return {
            xs: 1.1,
            customContent: true,
            content: <Stack style={{width:'100%',height:'100%', placeItems: 'center',justifyContent:'center',backgroundColor:!type ? '#64dedf': type === 1 ? checkTp(c.content) ? '#f572de':'#bb58aa' : '#e37950' }}>
                {checkTp(c.content) ? <Typography style={{color:'#fff',textAlign:'center',fontFamily:'Delm',fontSize:'1em'}}>{toNameCase(c.content)}</Typography>:<SlidingTypography delm color="#fff" >{c.content}</SlidingTypography>}
           </Stack>
        }}
    }
    
    const dateF = (date) => format(new Date(date), 'dd/MM/yyyy hh:mm:ss')
    return (
        <Stack padding="2% 0%" spacing={3} height="100%" style={{ overflowY: "scroll" }}>
            <Snackbar open={open} autoHideDuration={1500} onClose={() => setOpen(false)}>
                <MuiAlert elevation={6} variant="filled" severity='warning'>{text.delalert}</MuiAlert>
            </Snackbar>
            <Dialog open={openDialog.status} onClose={closeODialog}>
                <DialogTitle >
                    <Typography sx={{ fontWeight: 'bold', fontSize: '2em' }}>{title()}</Typography>
                    {/* {openDialog.type === 2 && <Typography sx={{ fontWeight: 'bold', fontSize: '1.2em' }}>Conversation Id :{openDialog.data.conv_id}</Typography>} */}
                    </DialogTitle>
                {openDialog.type === 2 && <DialogContent sx={{minWidth:'420px'}}>
                    <Stack sx={{justifyContent:'space-between',flexDirection:'row',gap:2}}>
                        <Typography sx={{ fontWeight: 'bold', fontSize: '1.2em' }}>{text.conv} : {openDialog.data.conv_id} ({openDialog.data.language})</Typography>
                        <Typography sx={{ fontWeight: 'bold',color:'#858585', fontSize: '1.2em' }}>{dateF(openDialog.data.message_at)}</Typography>

                    </Stack>
                    <Box style={{display:'flex',gap:4}}>
                        <Typography sx={{fontWeight:'bold'}}>{text.exped} :</Typography>
                        <Typography>{afficheSR(1)}</Typography>
                    </Box>
                    <Box style={{display:'flex',gap:4}}>
                        <Typography sx={{fontWeight:'bold'}}>{text.recep} :</Typography>
                        <Typography>{afficheSR(2)}</Typography>
                    </Box>
                    <Box style={{display:'flex',gap:4, maxWidth:'400px',flexDirection:openDialog.data.content ? openDialog.data.content.length > 30 ? 'column':'row':'row'}}>
                        <Typography sx={{fontWeight:'bold'}}>{text.mess} {openDialog.data.content ? openDialog.data.content.length < 30 ? ':':'':''}</Typography>
                        <Typography>{openDialog.data.content}</Typography>
                    </Box>
                    </DialogContent>}
                <DialogActions sx={{ textAlign: 'center', display: 'flex', justifyContent: 'space-around' }}>
                    {openDialog.type === 1 && <Button variant='contained' color='error' onClick={closeODialog}>{text.annuler}</Button>}
                    <Button variant='contained' color='success' onClick={openDialog.type === 1 ? deleteMsg:closeODialog}>{openDialog.type === 1 ? text.vld : text.ok}</Button>
                </DialogActions>
            </Dialog>

            {/* <Stack style={{ display: 'flex', alignItems: 'center', gap: '15px', justifyContent: 'center', flexDirection: 'row' }}>
                <Typography variant="h1" alignSelf='center' style={{ fontFamily: 'Fascinate Regular', fontWeight: 'bold', color: '#ffde59', WebkitTextStroke: '2px #806600' }}
                >{text.convs}</Typography>
                <img className="card-img-resize-2" src={"/images/supervisor/1conv.png"} alt="" height='50px' width='50px' />
            </Stack> */}
            <Title>{text.convman}</Title>
            <BigContainerAccordion
                transparent
                summaryBg="#0cc0df"
                summary={text.trconv}
                details={
                    <Stack spacing={2}>
                        <SearchFilter
                            selectedDate={filter.date}
                            onDateChange={{
                                year: y => setFilter({ ...filter, year: y }),
                                month: m => setFilter({ ...filter, month: m }),
                                date: d => setFilter({ ...filter, date: d }),
                            }}
                            items={[
                                { label: text.startingdate, comp: 'DatePicker', selected: filter.startingDate, onChange: d => setFilter({ ...filter, startingDate: d }) },
                                { label: text.closingdate, comp: 'DatePicker', selected: filter.closingDate, onChange: d => setFilter({ ...filter, closingDate: d }) },
                                { label: text.platform, comp: 'TextInput', onChange: e => setFilter({ ...filter, platforms: e.target.value }) },
                                { label: text.language, comp: 'Select', choices: languagesList, onChange: e => setFilter({ ...filter, language: e.target.value }) },
                                { label: text.agents, comp: 'TextInput', onChange: e => setFilter({ ...filter, agents: e.target.value }) },
                                { label: text.users, comp: 'TextInput', onChange: e => setFilter({ ...filter, users: e.target.value }) },
                                { label: text.idconv, comp: 'TextInput', onChange: e => setFilter({ ...filter, idconv: e.target.value }) },
                                { label: text.idmess, comp: 'TextInput', onChange: e => setFilter({ ...filter, idmess: e.target.value }) },
                            ]}
                        />
                        <Stack spacing={'10px'} >
                            <TableRow header columns={[
                                { xs: 1.1, text: text.date },
                                { xs: 1.1, text: text.idconv },
                                { xs: 1.1, text: text.idmess },
                                { xs: 1.1, text: text.sender },
                                { xs: 1.1, text: text.receiver },
                                { xs: 1.1, text: text.mess },
                                { xs: 1.1, text: text.agent },
                                { xs: 1.1, text: text.platform },
                                // { xs: 1.1,  text: text.status},
                                { xs: 1.1, text: text.language },
                                {
                                    xs: 1.1,
                                    customContent: true,
                                    content: <Box style={{ fontFamily: 'Chewy Pro', width: '70%', display: 'flex', flexDirection: 'column' }}>
                                        <span>{text.status}</span>
                                        <span style={{ textAlign: 'end' }}>{text.actions}</span>
                                    </Box>
                                    // text: text.actions
                                },
                            ]} />
                            {
                                conversation?.map((c, i) =>
                                    <TableRow key={`c${c.conv_id}${c.message_id}`} columns={[
                                        { xs: 1.1, text: dateF(c.message_at) },
                                        { xs: 1.1, text: c.conv_id },
                                        { xs: 1.1, text: c.message_id },
                                        { xs: 1.1, text: c.sender_profil.Name },
                                        { xs: 1.1, text: c.receiver_profil.Name },
                                        tableContentMess(c),
                                        { xs: 1.1, text: c.operator },
                                        { xs: 1.1, text: c.platform },
                                        { xs: 1.1, text: c.language ? toNameCase(c.language) : 'Fr' },
                                        // {
                                        //     xs: 1.1,
                                        //     customContent: true,
                                        //     content: <Typography color='#1bb642' style={{fontFamily: 'Delm'}}>{text.treated}</Typography>
                                        //     },
                                        {
                                            xs: 1.1,
                                            customContent: true,
                                            content: <Stack style={{ placeItems: 'center' }}>
                                                <Typography color='#1bb642' style={{ fontFamily: 'Delm' }}>{text.treated}</Typography>
                                                <Button style={{ textTransform: 'capitalize', color: '#000' }} onClick={() => selectMsg(c,true)}><Typography style={{ fontFamily: 'Delm' }}>{text.dlt}</Typography></Button>
                                            </Stack>
                                        }
                                    ]} clickT={clickT} number={c} stat={1} ind={i > -1 ? true:false}/>
                                )
                            }
                        </Stack>
                        <Stack justifyItems="center" alignItems="center" >
                            <Pagination
                                page={page}
                                onChange={handleChange}
                                count={pagination?.total_pages}
                                variant="outlined"
                                shape="rounded"
                                color="secondary"
                            />
                        </Stack>
                    </Stack>
                }
            />
            <BigContainerAccordion
                transparent
                summaryBg="#f4a100"
                summary={text.convonhold}
                details={
                    <Stack spacing={2}>
                        <SearchFilter
                            selectedDate={filter1.date}
                            onDateChange={{
                                year: y => setFilter1({ ...filter1, year: y }),
                                month: m => setFilter1({ ...filter1, month: m }),
                                date: d => setFilter1({ ...filter1, date: d }),
                            }}
                            items={[
                                { label: text.startingdate, comp: 'DatePicker', selected: filter1.startingDate, onChange: d => setFilter1({ ...filter1, startingDate: d }) },
                                { label: text.closingdate, comp: 'DatePicker', selected: filter1.closingDate, onChange: d => setFilter1({ ...filter1, closingDate: d }) },
                                { label: text.platform, comp: 'TextInput', onChange: e => setFilter1({ ...filter1, platforms: e.target.value }) },
                                { label: text.language1, comp: 'Select', choices: languagesList, onChange: e => setFilter1({ ...filter1, language: e.target.value }) },
                                { label: text.users, comp: 'TextInput', onChange: e => setFilter1({ ...filter1, users: e.target.value }) },
                                { label: text.idconv, comp: 'TextInput', onChange: e => setFilter1({ ...filter1, idconv: e.target.value }) },
                                { label: text.idmess, comp: 'TextInput', onChange: e => setFilter1({ ...filter1, idmess: e.target.value }) },
                            ]}
                        />
                        <Stack spacing={'10px'}>
                            <TableRow header columns={[
                                { xs: 1.22, text: text.date },
                                { xs: 1.22, text: text.idconv },
                                { xs: 1.22, text: text.idmess },
                                { xs: 1.22, text: text.sender },
                                { xs: 1.22, text: text.platform },
                                { xs: 1.1, text: text.language },
                                { xs: 1.22, text: text.mess },
                                { xs: 1.22, text: text.receiver },
                                {
                                    xs: 1.1,
                                    customContent: true,
                                    content: <Box style={{ fontFamily: 'Chewy Pro', width: '70%', display: 'flex', flexDirection: 'column' }}>
                                        <span>{text.status}</span>
                                        <span style={{ textAlign: 'end' }}>{text.actions}</span>
                                    </Box>
                                    // text: text.actions
                                },
                            ]} />
                            {
                                holdConversation?.map((c,i) =>
                                    <TableRow key={`c${c.idconv}${c.message_id}`} columns={[
                                        { xs: 1.22, text: dateF(c.message_at)},
                                        { xs: 1.22, text: c.conv_id },
                                        { xs: 1.22, text: c.message_id },
                                        { xs: 1.22, text: c.sender_profil.Name },
                                        { xs: 1.22, text: c.platform },
                                        { xs: 1.1, text: c.language ? c.language.charAt(0).toUpperCase() + c.language.charAt(1) : 'Fr' },
                                        { xs: 1.22, text: c.content },
                                        { xs: 1.22, text: c.receiver_profil.Name },
                                        // {
                                        //     xs: 1.22,
                                        //     color: '#f00',
                                        //     text: text.oh
                                        //     //color: c.onHold ? '#f00' : '#1bb642',
                                        //     //text: c.onHold ? text.oh : text.treated
                                        // },
                                        {
                                            xs: 1.1,
                                            customContent: true,
                                            content: <Stack style={{ placeItems: 'center' }}>
                                                <Typography color='#1bb642' style={{ fontFamily: 'Delm' }}>{c.status ? text.enc : text.oh}</Typography>
                                                <Button disabled={c.status} style={{ textTransform: 'capitalize', color: c.status ? '#dbdbdb':'#000' }} onClick={() => selectMsg(c,false)}><Typography style={{ fontFamily: 'Delm' }}>{text.dlt}</Typography></Button>
                                            </Stack>
                                        }
                                    ]}  clickT={clickT} number={c} stat={2} ind={i > -1 ? true:false} />
                                )
                            }
                        </Stack>
                        <Stack justifyItems="center" alignItems="center" >
                            <Pagination
                                page={pageHold}
                                onChange={handleChangeHold}
                                count={paginationHold?.total_pages}
                                variant="outlined"
                                shape="rounded"
                                color="secondary"
                            />
                        </Stack>
                    </Stack>
                }
            />
        </Stack>
    )
}

export default Conversations