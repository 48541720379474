import React, { useContext, useState } from 'react'
import CustomPopup from '../Admin/CustomPopup'
import { Box, Button, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material'
import axiosInstance from '../../axios'
import { AuthStateContext } from '../state/AuthStateContext'
import useLanguage from '../hooks/useLanguage'

const PopupUpdateInfo = ({type, infoToUpdate, openUpdate, setOpenUpdate, setInfo, setMeet, setAlertContenu, setAlert}) => {

    const { authState } = useContext(AuthStateContext)
    const {text} = useLanguage()
    const [ loading, setLoading ] = useState()

    const [ place, setPlace ] = useState(infoToUpdate?.place || '')
    const [ date, setDate ] = useState(infoToUpdate?.date || '')
    const [ nature, setNature ] = useState(infoToUpdate?.nature || '')
    const [ contenu, setContenu ] = useState(infoToUpdate?.content || '')

    const handleInfoUpdate = () => {
        setLoading(true)

        let data = type == "meeting" ? {
            "id" : infoToUpdate?.id,
            "place" : place,
            "date" : date,
            "type" : 'meeting'
        } : {
            "id" : infoToUpdate?.id,
            "nature" : nature,
            "contenu" : contenu,
            "type" : 'info'
        }

        axiosInstance.post(`/information/update`, data, {
            headers: {
                'Authorization': `Bearer ${authState.token}`
            }
        })
        .then((response)=>{
            setAlertContenu("Mis à jour.")
            setAlert(true)
            if(type == "meeting"){
                setMeet((last) => last.map((a) => a.id == infoToUpdate?.id ? response.data.data : a))
            }else{
                setInfo((last) => last.map((a) => a.id == infoToUpdate?.id ? response.data.data : a))
            }
            setLoading(false)
            setOpenUpdate(false)
            setPlace('')
            setDate('')
            setNature('')
            setContenu('')
        })
        .catch((error)=>{
            // console.log(error)
            setLoading(false)
        })
    }

  return (
    <div>
        <CustomPopup open={openUpdate} onClose={() => setOpenUpdate(false)}>
            <div style={{ padding: "18px 30px"}}>
                <DialogTitle id="alert-dialog-title" sx={{textAlign: "center"}}>
                    <p style={{ fontWeight: "800", fontSize: "22px"}}>
                        Update { type == "meeting" ? "Meeting" : "Information" } :
                    </p>
                </DialogTitle>
                <DialogContent>
                    <Box>
                        { type == "meeting" ? (
                            <div>
                                <label style={{ display: "block", marginBottom: "8px" }}>
                                    Place :
                                    <input
                                        type="text"
                                        value={place}
                                        onChange={(e) => setPlace(e.target.value)}
                                        style={{
                                            width: "100%",
                                            padding: "8px",
                                            margin: "8px 0",
                                            border: "1px solid #ccc",
                                            borderRadius: "4px"
                                        }}
                                    />
                                </label>
                                <label style={{ display: "block", marginBottom: "8px" }}>
                                    Date :
                                    <input
                                        type="date"
                                        value={date}
                                        onChange={(e) => setDate(e.target.value)}
                                        style={{
                                            width: "100%",
                                            padding: "8px",
                                            margin: "8px 0",
                                            border: "1px solid #ccc",
                                            borderRadius: "4px"
                                        }}
                                    />
                                </label>
                            </div>
                        ) : (
                            <div>
                                <label style={{ display: "block", marginBottom: "8px" }}>
                                    Nature :
                                    <input
                                        type="text"
                                        value={nature}
                                        onChange={(e) => setNature(e.target.value)}
                                        style={{
                                            width: "100%",
                                            padding: "8px",
                                            margin: "8px 0",
                                            border: "1px solid #ccc",
                                            borderRadius: "4px"
                                        }}
                                    />
                                </label>
                                <label style={{ display: "block", marginBottom: "8px" }}>
                                    Content :
                                    <textarea
                                        value={contenu}
                                        onChange={(e) => setContenu(e.target.value)}
                                        style={{
                                            width: "100%",
                                            padding: "8px",
                                            margin: "8px 0",
                                            border: "1px solid #ccc",
                                            borderRadius: "4px",
                                            resize: "none",
                                            height: "80px"
                                        }}
                                    />
                                </label>
                            </div>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant='contained' 
                        sx={{ 
                            fontWeight: "700", 
                            bgcolor: "error.main",
                            color: "white",
                            "&:hover": {
                                bgcolor: "error.dark",
                            } 
                        }} 
                        onClick={() => setOpenUpdate(false)}
                    >
                        {text.annuler}
                    </Button>
                    <Button 
                        variant='contained' 
                        sx={{ 
                            fontWeight: "700", 
                            bgcolor: "success.main", 
                            color: "white",
                            "&:hover": {
                                bgcolor: "success.dark",
                            } 
                        }} 
                        onClick={()=> handleInfoUpdate()}
                    >
                        {loading ? text.phload : text.updt}
                    </Button>
                </DialogActions>
            </div>
        </CustomPopup>
    </div>
  )
}

export default PopupUpdateInfo