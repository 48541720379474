import React, { useState, useEffect, useContext } from "react";
import { Stack, Typography, Button, DialogTitle, DialogContent, Box, DialogActions, Pagination, FormControl, Select, MenuItem } from "@mui/material";
import useLanguage from "../hooks/useLanguage";
import BigContainerAccordion from "../tools/BigContainerAccordion";
import SearchFilter from "../tools/SearchFilter";
import TableRow from "../tools/TableRow";
import useData from "../hooks/useData";
import FormRow from "../tools/FormRow";
import { useForm } from "react-hook-form";
import useList from "../hooks/useList";
import CustomAlert from "./CustomAlert";
import AuthFormRow from "../tools/AuthFormRow";
import axiosInstance from "../../axios";
import countriesList from "../../helper/countries";
import { format } from "date-fns";
import CustomPopup from "./CustomPopup";
import { AuthStateContext } from "../state/AuthStateContext";
import { roles } from "../../helper/roles";
import { formatDate } from "../../helper/formatDate";
import Pusher from 'pusher-js'
import { apiURL, CLUSTER, PUSHER_SECRET } from "../../helper/conf";
import { GifsContext } from "../state/gifContext";
import Title from "../Admin2/Comps/Title";

const SupervisorAndTrafficManagement = () => {
    const { globalFilter, setGlobalFilter } = useContext(GifsContext)
    const {text} = useLanguage()
    const { authState } = useContext(AuthStateContext)
    const { languagesList, trafficManagement, languagesgifList, roleList, paymentList  } = useData()
    const {supervisorList,  getSupervisorList} = useList()
    const [ alerto, setAlerto ] = useState(false)
    const [error,setError] = useState('')
    const [pp, setPP] = useState(false)
    const [ppError, setPPError] = useState(false)
    const [ alertContenu, setAlertContenu] = useState("")

    const [filter, setFilter] = useState({
        year: '',
        month: '',
        date: '',
        startingDate: '',
        closingDate: '',
        platforms: '',
        agents: '',
        language: '',
    })

    const { control, reset, handleSubmit,getValues,watch, formState: {errors} } = useForm()
    const [operatorList,setOperatorList] = useState([])

    useEffect(() => {
        const pusher = new Pusher(PUSHER_SECRET, { cluster: CLUSTER, channelAuthorization: { endpoint: `${apiURL}/pusher/user-auth`, transport: 'jsonp' } })
        const channel = pusher.subscribe('private-conversation')
        channel.bind(`add-hold-${authState.userId}`, (data) => {
            setHoldConversation((last) => [data,...last])
        })
        channel.bind(`change-status-${authState.userId}`, (data) => {
            if(data)
                data.id.forEach(l => setHoldConversation(p => p.map(k => k.message_id === l ? {...k,status:data.type}:k)))
        })
        channel.bind(`status-${authState.userId}`, (data)=> {
            // console.log(data.status)
            setOperatorList((last)=>last.map((a)=> a.agent_id == data.id ? { ...a, status: data.status } : a))
        })
        return () => {
            channel.unbind()
            pusher.unsubscribe('private-conversation')
        }
    }, [])

    // LIST SUPERVISEUR
    const [ page, setPage] = useState(1)
    const [ pagination, setPagination ] = useState()
    const [ supervisor, setSupervisor ] = useState()
    const handleChange = (event, value) => {
        setPage(value);
    };
    useEffect(() => {
        if (authState.userRole === 'administrator'){
            axiosInstance.get(`/user/${roles.supervisor}?year=${filter?.year}&month=${filter?.month}&date=${formatDate(filter?.date).split('-')[0]}&startingDate=${formatDate(filter?.startingDate)}&closingDate=${formatDate(filter?.closingDate)}&platforms=${filter?.platforms}&language=${filter?.language}&agents=${filter?.agents}&page=${page}`, {
                headers: {
                    'Authorization': `Bearer ${authState.token}`
                }
            })
            .then((response) => {
                setSupervisor(response.data.data)
                setPagination(response.data.pagination)
            })
            .catch((e) => {
                // console.log(e)
            })
        }
    }, [page, filter])

    // ADD SUPERVISEUR
    const selectedPayment = watch('paymentName')
	const addSupervisor = async({role,mail,password,login,confirmpassword,name, fullName, fullAddress, country,languageName, paymentName, bankname, iban, bic, paymail}) =>{
        if(error) setError('')
            setPPError(!pp)
        if(password === confirmpassword) {
            try{
                const resp = await axiosInstance.post('/register',{
                    mail,
                    password,
                    login,
                    role,
                    name,
                    country,
                    languageName, 
                    paymentName, 
                    bankname, 
                    iban, 
                    bic, 
                    paymail,
                    fullName,
                    fullAddress,
                }, {
                    headers: {
                        'Authorization': `Bearer ${authState.token}`,
                    },
                })
                setAlertContenu(text.scc)
                setAlerto(true)
                setSupervisor((prev) => [...prev, resp.data.userData])
                reset()
            }catch(e){
                // console.log(e)
                setError(e.response)
            }
        }
        else setError(text.passdiff)
    }

    // DELETE SUPERVISOR
    const [ loadingDelete, setLoadingDelete] = useState(false)
    const [ popPanic, setPopPanic ] = useState(false)
    const [ SuperToDelete, setSuperToDelete ] = useState()
    const handleDelete = () => {
        setLoadingDelete(true)
        axiosInstance.delete(`/supervisor_delete/${SuperToDelete?.id}`, {
            headers: { 
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then((response) => {
            // console.log(response.data)
            setAlertContenu(response.data.message)
            setLoadingDelete(false)
            setAlerto(true)
            supervisorList.filter((s)=>s.id !== SuperToDelete?.id)
            setPopPanic(false)
        })
        .catch((error) => {
            // console.log(error)
            setLoadingDelete(false)
        })
    }

    // UPDATE PASSWORD
    const [ SuperToUpdate, setSuperToUpdate ] = useState()
    const [ newPassword, setNewPassword ] = useState()
    const [ popUpdate, setPopUpdate ] = useState(false)
    const handelUpdate = () => {
        setLoadingDelete(true)
        axiosInstance.put(`/supervisor/${SuperToUpdate?.id}`,{
            'newPassword': newPassword,
        },{
            headers: {
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then((response) => {
            // console.log(response.data)
            setAlertContenu(response.data.message)
            setLoadingDelete(false)
            setAlerto(true)
            setPopUpdate(false)
        })
        .catch((error) => {
            // console.log(error)
            setLoadingDelete(false)
        })
    }
    const dateF = (date) => format(new Date(date), 'dd/MM/yyyy hh:mm:ss')

    const [holdConversation, setHoldConversation] = useState()
    const [paginationHold, setPaginationHold] = useState()
    const [pageHold, setPageHold] = useState(1);
    const handleChangeHold = (event, value) => {
        setPageHold(value);
    };
    const [filter1, setFilter1] = useState(() => ({
        ...globalFilter,
        users: '',
        idconv: '',
        idmess: ''
    }))

    useEffect(() => {
        axiosInstance.get(`/conversation_on_hold?year=${filter1?.year}&month=${filter1?.month}&date=${formatDate(filter1?.date).split('-')[0]}&startingDate=${formatDate(filter1?.startingDate)}&closingDate=${formatDate(filter1?.closingDate)}&platforms=${filter1?.platforms}&language=${filter1?.language}&fakeProfil=${filter1?.users}&conversation=${filter1?.idconv}&message=${filter1?.idmess}&page=${pageHold}`, {
            headers: {
                'Authorization': `Bearer ${authState.token}`,
            },
        })
            .then((response) => {
                // console.log(response.data)
                setHoldConversation(response.data.data)
                setOperatorList(response.data.operator)
                setPaginationHold(response.data.pagination)
            })
            .catch((error) => {
                // console.log(error)
            })
    }, [pageHold, filter1])

const handleClickAllocate = async(ind) => {
    const conv = holdConversation[ind].conv_id
    const agent_id = operatorList[holdConversation[ind].opAssign].agent_id
    const agent = operatorList[holdConversation[ind].opAssign].agent
    const data = {conv:conv,priority:agent_id}
    await axiosInstance.post('/GestionTrafic',data, {
        headers: {
            'Authorization': `Bearer ${authState.token}`,
        },
    })
    .then(() => 
        setHoldConversation(p => p.map(k => k.conv_id === conv ? {...k,agent:agent,agent_id:agent_id,opAssign:''}:k))
    )
    .catch(e =>{})
}
const handleChangeResult = (e,ind) => {
    // console.log(e.target.value)
    setHoldConversation(p => p.map((k,i) => i === ind ? {...k,opAssign:e.target.value}:k))
}
    return <Stack spacing={2} style={{ overflowY: "scroll" }}>
        <CustomAlert
            open={alerto}
            autoHideDuration={1500}
            onClose={() => setAlerto(false)}
            severity="success"
            message={alertContenu}
        />
        <CustomPopup open={popUpdate} onClose={() => setPopUpdate(false)}>
            <div style={{ padding: "18px 30px"}}>
                <DialogTitle id="alert-dialog-title" sx={{textAlign: "center"}}>
                    <p style={{ fontWeight: "800", fontSize: "22px"}}>
                        {text.updtmdp}
                    </p>
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", mr: "6px" }}>
                                {text.supnm}:
                            </Stack>
                            <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", color: "#757575" }}>
                                {SuperToUpdate?.name}
                            </Stack>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", mr: "6px" }}>
                                {text.suplgn}:
                            </Stack>
                            <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", color: "#757575" }}>
                                {SuperToUpdate?.mail}
                            </Stack>
                        </div>
                        <div style={{ alignItems: 'center' }}>
                            <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", mr: "6px" }}>
                                {text.nwmdp}:
                            </Stack>
                            <input
                                style={{fontSize: "22px", fontWeight: "500", color: "#333333", padding: '10px', width: '100%'}}
                                onChange={(e)=>setNewPassword(e.target.value)}
                            />
                        </div>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant='contained' 
                        sx={{ 
                            fontWeight: "700", 
                            bgcolor: "error.main",
                            color: "white",
                            "&:hover": {
                                bgcolor: "error.dark",
                            } 
                        }} 
                        onClick={() => setPopUpdate(false)}
                    >
                        {text.delrefuse}
                    </Button>
                    <Button 
                        variant='contained' 
                        sx={{ 
                            fontWeight: "700", 
                            bgcolor: "success.main", 
                            color: "white",
                            "&:hover": {
                                bgcolor: "success.dark",
                            } 
                        }} 
                        onClick={()=> handelUpdate()}
                    >
                        {loadingDelete ? text.phload : text.delaccept}
                    </Button>
                </DialogActions>
            </div>
        </CustomPopup>
        <CustomPopup open={popPanic} onClose={() => setPopPanic(false)}>
            <div style={{ padding: "18px 30px"}}>
                <DialogTitle id="alert-dialog-title" sx={{textAlign: "center"}}>
                    <p style={{ fontWeight: "800", fontSize: "22px"}}>
                        {text.dltsup} 
                    </p>
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", mr: "6px" }}>
                                {text.supnm}:
                            </Stack>
                            <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", color: "#757575" }}>
                                {SuperToDelete?.name}
                            </Stack>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", mr: "6px" }}>
                                {text.suplgn}:
                            </Stack>
                            <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", color: "#757575" }}>
                                {SuperToDelete?.mail}
                            </Stack>
                        </div>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant='contained' 
                        sx={{ 
                            fontWeight: "700", 
                            bgcolor: "error.main",
                            color: "white",
                            "&:hover": {
                                bgcolor: "error.dark",
                            } 
                        }} 
                        onClick={() => setPopPanic(false)}
                    >
                        {text.delrefuse}
                    </Button>
                    <Button 
                        variant='contained' 
                        sx={{ 
                            fontWeight: "700", 
                            bgcolor: "success.main", 
                            color: "white",
                            "&:hover": {
                                bgcolor: "success.dark",
                            } 
                        }} 
                        onClick={()=> handleDelete()}
                    >
                        {loadingDelete ? text.phload : text.delaccept}
                    </Button>
                </DialogActions>
            </div>
        </CustomPopup>
        <Stack style={{display: 'flex', alignItems: 'center', gap: '15px', justifyContent: 'center', flexDirection: 'row'}}>
            {/* <Typography variant="h1" alignSelf='center' style={{fontFamily: 'Fascinate Regular', fontWeight: 'bold', color: '#c1ff72', WebkitTextStroke: '2px #4e8614'}}
            >{text.suptraffman}</Typography> */}
            <Title>{text.suptraffman}</Title>
            {/* <img className="card-img-resize-2" src={"/images/admin/supimg.png"} alt="" height='50px' width='50px'/> */}
        </Stack>

        {authState.userRole === 'administrator' && 
            <BigContainerAccordion 
                    transparent
                summaryBg="#f33"
                summary={text.lstsup}
                details={
                    <Stack spacing={2}>
                        <SearchFilter
                            selectedDate={filter.date}
                            onDateChange={{
                                year: y => setFilter({...filter, year: y}),
                                month: m => setFilter({...filter, month: m}),
                                date: d => setFilter({...filter, date: d}),
                            }}
                            items= {[
                                { label: text.startingdate , comp: 'DatePicker', selected: filter.startingDate, onChange: d => setFilter({...filter, startingDate: d})},
                                { label: text.closingdate , comp: 'DatePicker', selected: filter.closingDate, onChange: d => setFilter({...filter, closingDate: d})},
                                { label: text.platform , comp: 'TextInput', onChange: e => setFilter({...filter, platforms: e.target.value}) },
                                { label: text.language , comp: 'Select', choices: languagesList, onChange: e => setFilter({...filter, language: e.target.value }) },
                            ]}
                        />
                        <Stack spacing={'10px'}>
                            <TableRow header columns={[
                                { xs: 1.83,  text: text.date},
                                { xs: 1.83,  text: text.platform},
                                { xs: 1.83,  text: text.language},
                                { xs: 1.83,  text: text.supnm},
                                { xs: 1.83,  text: text.suplgn},
                                { xs: 1.83,  text: text.suplgnpsswrd},
                            ]}/>
                            {
                                supervisor?.map(s =>
                                    <TableRow key={s.id} columns={[
                                        { xs: 1.83,  text: s.date_inscription ? format(new Date(s.date_inscription), 'dd/MM/yyyy') : ''},
                                        { xs: 1.83,  text: s.platform},
                                        { xs: 1.83,  text: s.language?.name},
                                        { xs: 1.83,  text: s.name},
                                        { xs: 1.83,  text: s.mail},
                                        {
                                            xs: 1.83,
                                            customContent: true,
                                            content: <Stack width='100%'>
                                                <Button 
                                                    style={{width: '100%', height: '100%', textTransform: 'capitalize', color: '#000'}}
                                                    onClick={()=>{setSuperToUpdate(s); setPopUpdate(true)}}
                                                >
                                                    <Typography style={{fontFamily: 'Delm'}}>
                                                        {text.edit}
                                                    </Typography>
                                                </Button>                 
                                                <Button 
                                                    style={{width: '100%', height: '100%', textTransform: 'capitalize', color: '#000'}}
                                                    onClick={()=>{setSuperToDelete(s); setPopPanic(true)}}
                                                >
                                                    <Typography style={{fontFamily: 'Delm'}}>
                                                        {text.dlt}
                                                    </Typography>
                                                </Button>          
                                            </Stack>
                                        },
                                    ]}/>
                                )
                            }
                        </Stack>
                        <Stack justifyItems="center" alignItems="center" >
                            <Pagination
                                page={page}
                                onChange={handleChange}
                                count={pagination?.total_pages}
                                variant="outlined"
                                shape="rounded"
                                color="secondary"
                            />
                        </Stack>
                    </Stack>
                }
            />
        }

        {authState.userRole === 'administrator' && 
            <BigContainerAccordion
                    transparent
                summaryBg="#8c52ff"
                summary={text.ddnwsup}
                details={
                    
                    <Stack spacing={2}>
                        <Typography alignSelf="center" sx={{ color: "red"}}>{error}</Typography>
                        <FormRow label={text.role} comp="Select" control={control} options={roleList} name="role" error={errors.role} />
                        <FormRow label={text.lgn} control={control} name="login" error={errors.login} />
                        <FormRow label={text.mail} control={control} name="mail" error={errors.mail} />
                        <FormRow label={text.agname} control={control} name="name" error={errors.name} />
                        <FormRow label="Full Name" control={control} name="fullName" error={errors.name} />
                        <FormRow label="Full Address" control={control} name="fullAddress" error={errors.name} />
                        <FormRow label={text.pays} comp="Select" options={countriesList} control={control} name="country" error={errors.country} />
                        <FormRow label={text.language} comp="Select" options={languagesgifList} control={control} name="languageName" error={errors.languageName} />
                        <FormRow label= {text.paymeth} comp="Select" options={paymentList} control={control} name="paymentName" error={errors.paymentName} />
                        {
                            (selectedPayment==paymentList[0].value) ?
                                <>
                                    <FormRow label= {text.bankn} control={control} name="bankname" error={errors.bankname} />
                                    <FormRow label= {text.iban} control={control} name="iban" error={errors.iban} />
                                    <FormRow label= {text.bic} control={control} name="bic" error={errors.bic} />
                                </>
                            :<FormRow label= {text.paymail} control={control} name="paymail" error={errors.paymail} />
                        }
                        <FormRow label={text.password} comp="Password" control={control} name="password" error={errors.password} />
                        <FormRow label={text.confirmpass} comp="Password" control={control} name="confirmpassword" error={errors.confirmpassword} />
                        <Stack style={{width: '85%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
                            <Button onClick={handleSubmit(addSupervisor)} variant="formrow">
                                <Typography>
                                    {text.crtnwsup}
                                </Typography>
                            </Button>
                        </Stack>
                    </Stack>
                }
            />
        }

        <BigContainerAccordion 
                transparent
            summaryBg="#3e90c9"
            summary={text.statconvatt}
            details={
                <Stack spacing={2}>
                    <SearchFilter
                    selectedDate={filter.date}
                    onDateChange={{
                        year: y => setFilter({...filter, year: y}),
                        month: m => setFilter({...filter, month: m}),
                        date: d => setFilter({...filter, date: d}),
                    }}
                        items= {[
                            { label: text.platform , comp: 'TextInput', onChange: e => setFilter({...filter, platforms: e.target.value}) },
                            { label: text.language , comp: 'Select', choices: languagesList, onChange: e => setFilter({...filter, language: e.target.value }) },
                            { label: text.agent , comp: 'Select', choices: languagesList, onChange: e => setFilter({...filter, language: e.target.value }) },
                            { label: text.conv , comp: 'Select', choices: languagesList, onChange: e => setFilter({...filter, language: e.target.value }) },
                            { label: text.mid , comp: 'Select', choices: languagesList, onChange: e => setFilter({...filter, language: e.target.value }) },
                        ]}
                    />
                    <Stack spacing={'10px'}>
                        <TableRow header columns={[
                            { xs: 1.2,  text: text.agentid},
                            { xs: 1.2,  text: text.aglogin},
                            { xs: 1.2,  text: text.conv},
                            { xs: 1.2,  text: text.idmess},
                            { xs: 1.2,  text: text.platform},
                            { xs: 1.2,  text: text.language},
                            { xs: 1.4,  text: text.mess},
                            { xs: 1.2,  text: text.exp},
                            { xs: 1.2,  text: text.recep},
                        ]}/>
                        {
                            holdConversation?.map((t, i) => !t.status && t.agent_id &&
                            <TableRow key={`t${i}`} columns={[
                                { xs: 1.2,  text: t.agent_id},
                                { xs: 1.2,  text: t.agent_log},
                                { xs: 1.2,  text: t.conv_id},
                                { xs: 1.2,  text: t.id},
                                { xs: 1.2,  text: t.platform},
                                { xs: 1.2, text: t.language ? t.language.charAt(0).toUpperCase() + t.language.charAt(1) : 'Fr' },
                                { xs: 1.4,  text: t.content},
                                { xs: 1.2,  text: t.sender_profil.Name},
                                { xs: 1.2,  text: t.receiver_profil.Name},
                            ]} number={t} stat={7} ind={i > -1 ? true:false}/>
                        )
                        }
                    </Stack>
                    <Stack justifyItems="center" alignItems="center" >
                        <Pagination 
                            page={page}
                            onChange={handleChange}
                            count={pagination?.total_pages}
                            variant="outlined" 
                            shape="rounded" 
                            color="secondary"
                        />
                    </Stack>
                </Stack>
            }
        />
        <BigContainerAccordion
                transparent
            summaryBg="#07ad32"
            summary={text.trffcmng}
            details={
                <Stack spacing={2}>
                <SearchFilter
                selectedDate={filter.date}
                onDateChange={{
                    year: y => setFilter({...filter, year: y}),
                    month: m => setFilter({...filter, month: m}),
                    date: d => setFilter({...filter, date: d}),
                }}
                    items= {[
                        { label: text.platform , comp: 'TextInput', onChange: e => setFilter({...filter, platforms: e.target.value}) },
                        { label: text.language , comp: 'Select', choices: languagesList, onChange: e => setFilter({...filter, language: e.target.value }) },
                    ]}
                />
                <Stack spacing='10px'>
                    <TableRow header columns={[
                        { xs: 1.1,  text: text.date},
                        { xs: 1.2,  text: text.platform},
                        { xs: 1, text: text.language },
                        { xs: 1,  text: text.idconv},
                        { xs: 1.3,  text: text.idmess},
                        { xs: 1.3,  text: text.mess},
                        { xs: 1.3,  text: text.operator},
                        { xs: 1.3,  text: text.agntLgn},
                        { xs: 1.3,  text: text.msgalltag},
                    ]}/>
                    {
                        holdConversation?.map((t, i) => !t.status &&
                            <TableRow key={`t${i}`} columns={[
                                { xs: 1.1,  text: dateF(t.message_at)},
                                { xs: 1.2,  text: t.platform},
                                { xs: 1, text: t.language ? t.language.charAt(0).toUpperCase() + t.language.charAt(1) : 'Fr' },
                                { xs: 1,  text: t.conv_id},
                                { xs: 1.3,  text: t.id},
                                { xs: 1.3,  text: t.content},
                                { xs: 1.3,  text: t.agent},
                                { xs: 1.3,  customContent: true,
                                    content: <Stack textAlign="center" style={{ gap: 2, height: '100%' }} width='100%'>
                                    <FormControl fullWidth style={{ height: '100%' }}>
                                        <Select
                                            style={{ height: '100%' }}
                                            value={t.opAssign}
                                            onChange={e => handleChangeResult(e,i)}
                                        >
                                            {operatorList.map((p, i) => p.language === t.language && p.status !== 'disconnect' && p.agent_id !== t.agent_id &&
                                                <MenuItem value={i} key={i} style={{ cursor: "pointer" }}>
                                                    <Typography sx={{ fontFamily: 'Delm'}}>{p.agent}</Typography>
                                                </MenuItem>)}
                                        </Select>
                                    </FormControl>

                                </Stack>
                                },
                                { xs: 1.3,  customContent: true, content: <Button disabled={!t.opAssign ? true : false} style={{width: '100%', height: '100%', textTransform: 'capitalize', color: !t.opAssign ? '':'#000'}} onClick={() => handleClickAllocate(i)}><Typography style={{fontFamily: 'Delm'}}>{text.allct}</Typography></Button>},
                            ]}/>
                        )
                    }
                    <Stack justifyItems="center" alignItems="center" >
                            <Pagination
                                page={pageHold}
                                onChange={handleChangeHold}
                                count={paginationHold?.total_pages}
                                variant="outlined"
                                shape="rounded"
                                color="secondary"
                            />
                        </Stack>
                </Stack>
                </Stack>
            }
        />
    </Stack>
}

export default SupervisorAndTrafficManagement