import React, { useState, useEffect } from "react";
import { Stack, Typography, Box, Divider, Icon } from "@mui/material";
import { Person } from "@mui/icons-material"
import CButton from "./CButton";
import useLanguage from "../hooks/useLanguage";
import { keyframes } from "@emotion/react"

const Home = () => {
    const { text } = useLanguage()
    const datas = {
        dailyFlows: "1,5M+",
        features: "15+"
    }

    const Floater = ({max=45}) => {
        const [number, setNumber] = useState(0)
        const [scale, setScale] = useState(0)
        const [asc, setAsc] = useState(true)
        
        const ascDuration = 2500
        const descDuration = 750
        const maxSleep = 4000
        const minSleep = 1500

        const float = keyframes`
            0%{translate : -50% -50%;}
            50%{translate : -50% calc(-50% + 45px);}
            100%{translate : -50% -50%;}
        `

        useEffect(() => {
            const interval = setInterval(() => {
                setNumber(prevNumber => {
                    if (asc) {
                        setScale(1)
                        if (prevNumber < max) return prevNumber + 1
                        else {
                            setTimeout(() => setAsc(false), maxSleep)
                            clearInterval(interval)
                            return prevNumber
                        }
                    }
                    else{
                        if (prevNumber < max / 2) setScale(0)
                        if (prevNumber > 0) return prevNumber - 1
                        else {
                            setTimeout(() => setAsc(true), minSleep)
                            clearInterval(interval)
                            return prevNumber
                        }
                    }
                })
            }, asc ? ascDuration/max : descDuration/max)
        }, [asc, max])

        return <Stack sx={{ position: "absolute", width: "45%", height: "37%", opacity: 0.2, transition: "transform 0.5s", alignItems: "center", zIndex: 2, top: "50%", left: "50%", animation: `${float} 2s infinite ease`, transform: `scale(${scale})` }}>
            <Stack sx={{ height: "100%", width: "100%", background: "#e08787", borderRadius: "50px", fontSize: "100%", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: "20px" }}>
                <Icon style={{ color: "#fff", fontSize: "15vh" }}><Person style={{ fontSize: "15vh" }}/></Icon>
                <Typography variant="h0" style={{ color: "#fff", fontWeight: "bold", fontSize: "15vh" }}>{number}</Typography>
            </Stack>
            <Box sx={{ width: 0, height: 0, borderLeft: '50px solid transparent', borderRight: '50px solid transparent', borderTop: '50px solid #e08787' }} />
        </Stack>
    }

    const Flag = ({imageName, index}) => {

        const rotate = keyframes`
            from{transform: rotate(${360 * index/6}deg);}
            to{transform: rotate(${360 +360 * index/6}deg);}
        `

        return <Stack sx={{ position: "absolute", width: "80%", height: "80%", alignItems: "center", zIndex: 2, animation: `${rotate} 10s infinite linear` }}>
            <Box component="img" src={`/images/landing/${imageName}`} sx={{ height: "75px", aspectRatio: "1/1" }}/>
        </Stack>
    }

    return <Stack height="100vh" sx={{ flexDirection: { xs: "column", md: "row" } }}>
        <Stack id="home" sx={{ width: { xs: "100%", md: "40%" } }}>
            <Stack sx={{ height: "100%", gap: "30px", alignItems: "center", justifyContent: "center", padding: "10px", boxSizing: "border-box" }}>
                <Stack spacing={2}>
                    <Typography fontWeight="bold" variant="h1">{text.orpltfrm1}</Typography>
                    <Typography fontWeight="bold" variant="h1" color="#f2a491">{text.orpltfrm2}</Typography>
                    <Typography>{text.orpltfrmtxt}</Typography>
                </Stack>
                <CButton>{text.rqstdmo}</CButton>
            </Stack>
        </Stack>
        <Stack sx={{ position: "relative", width: { xs: "100%", md: "60%" }, height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Stack sx={{ background: "#473426", position: "absolute", height: "31%", aspectRatio: "1/1", borderRadius: "50%", left: "0%", bottom: "0%", zIndex: 0 }}/>
            <Stack sx={{ border: "3px solid #473426", position: "absolute", height: "29%", aspectRatio: "1/1", borderRadius: "50%", left: "calc(0% - 10px)", bottom: "calc(0% - 5px)", zIndex: 0 }}/>
            <Stack sx={{ border: "3px solid #473426", position: "absolute", height: "29%", aspectRatio: "1/1", borderRadius: "50%", left: "1%", bottom: "3%", zIndex: 0 }}/>
            <Box component="img" src="/images/landing/home.png" sx={{ height: "100%", zIndex: 0}}/>
            {
                ["fr.png", "en.png", "it.png", "nl.png", "es.png", "de.png"]
                .map((imageName, i) => <Flag key={i} imageName={imageName} index={i}/>)
            }
            <Floater/>
            <Stack sx={{ position: "absolute", gap: "10px", flexDirection: "row", padding: "20px 35px", top: "65%", right: "50%", background: "#ffffff", borderRadius: "10px" }}>
                <Stack spacing={1}>
                    <Typography>{text.flxqtdns}</Typography>
                    <Typography color="#d78529" textAlign="center" fontWeight="bold" variant="h3">{datas.dailyFlows}</Typography>
                </Stack>
                <Divider orientation="vertical" flexItem/>
                <Stack spacing={1}>
                    <Typography>{text.ftrs}</Typography>
                    <Typography color="#d48b70" textAlign="center" fontWeight="bold" variant="h3">{datas.features}</Typography>
                </Stack>
            </Stack>
        </Stack>

    </Stack> 
}

export default Home