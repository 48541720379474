import React, { useContext, useEffect } from "react";
import { Stack, Typography} from "@mui/material";
import useLanguage from "../hooks/useLanguage";
import QcHeader from "../Header/QcHeader";
import QualityControllerBody from "./QualityControllerBody";
import Pusher from "pusher-js";
import { apiURL, CLUSTER, PUSHER_SECRET } from "../../helper/conf";
import { AuthStateContext } from "../state/AuthStateContext";
import useAuth from "../hooks/useAuth";

const QualityController = () => {
    const { text } = useLanguage()
    const { authState } = useContext(AuthStateContext)
    const { logout } = useAuth()

    useEffect(() => {
        console.log("useEffect...")
        const pusher = new Pusher(PUSHER_SECRET, { 
            cluster: CLUSTER, 
            channelAuthorization: { 
                endpoint: `${apiURL}/pusher/user-auth`, 
                transport: 'jsonp' 
            } 
        })
        const channel = pusher.subscribe('private-' + authState.userId.toString())
    
        channel.bind('setDisconnect', data => {
            console.log("teste ûsher...", data)
            logout()
        })

        return () => {
            channel.unbind()
            pusher.unsubscribe('private-' + authState.userId.toString())
        }
    }, [])
    
    return (
        <Stack spacing={2} padding="20px 0">
            <QcHeader /> 
            <Stack style={{ position: 'relative', alignItems: 'center' }}>
                <img className="imgbg" src='/images/qc/3.png' alt="Background" style={{ display: 'block'}}/>
                <Typography className="horizon-regular" variant="h2"
                    style={{fontFamily: 'Horizon Regular', position: 'absolute', zIndex: 2, top: '50%',
                    textShadow: '2px 2px 2px #8396af', left: '50%', transform: 'translate(-50%, -50%)', 
                    textAlign: 'center', color: '#f51b01'} } 
                >
                    {text.qcdash.toUpperCase()}
                </Typography>
                
            </Stack>
            <QualityControllerBody/>
        </Stack>
    )
} 

export default QualityController;