import { useState, useContext } from "react"
import RoutesList from "../../helper/routes"
import { useNavigate } from 'react-router-dom'
import { AuthStateContext } from "../state/AuthStateContext"
import useLanguage from "./useLanguage"
import useData from "./useData"
import axiosInstance from "../../axios"
import { apiURL } from "../../helper/conf"
import axios from "axios"

const useAuth = () =>{
    const { text } = useLanguage()
    const [error, setError] = useState('')
    const navigate = useNavigate()
    const { setAuthenticated, authState } = useContext(AuthStateContext)
    const { roleList } = useData()

    const getDest = role =>{
        switch (role){
            case roleList[0].value: return [RoutesList.operator, RoutesList.statistics]
            case roleList[1].value: return [RoutesList.supervisor]
            case roleList[2].value: return [RoutesList.adminNew]
            case roleList[3].value: return [RoutesList.accountant]
            case roleList[4].value: return [RoutesList.qualitycontroller]
            default: return [RoutesList.auth] // non connexté
        }
    }

    const login = async ({mail, password, role}, setLoading) =>{
        if(error) setError('')
        setLoading(true)
        try{
            const rep = await axiosInstance.post('/login',{mail,password,role})
            if(rep.status === 200){
                await setAuthenticated(mail, rep.data.userId, rep.data.userName, role, rep.data.token, rep.data.languageName, rep.data.accessareas)
                navigate(getDest(role)[0])
            }
            setLoading(false)
        }catch(e){
            switch(e.response?.data.message){
                case '1':
                    setError(text.emailNonTrouve);
                    break;
                case '2':
                    setError(text.roleNonAutorise);
                    break;
                case '3':
                    setError(text.motDePasseInvalide);
                    break;
                case '4':
                    setError(text.compteSuspendu);
                    break;
                default:
                    setError(text.nonConnecte); 
                    break;
            }
            setLoading(false)
        }
    }

    const logout = async() =>{
        setAuthenticated(null)
        navigate(RoutesList.auth)
    }
    
    return {login, logout, error, getDest}
}
export default useAuth