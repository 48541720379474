import { Stack, Typography, Button, Snackbar, Dialog, DialogActions, DialogContent, DialogTitle, Accordion, AccordionSummary, SnackbarContent, Alert } from "@mui/material";
import React, { useState,useRef,useContext, useEffect } from "react";
import useLanguage from "../hooks/useLanguage";
import BigContainerAccordion from "../tools/BigContainerAccordion";
import TableRow from "../tools/TableRow";
import useData from "../hooks/useData";
import SearchFilter from "../tools/SearchFilter";
import useList from "../hooks/useList";
import { format } from "date-fns";
import MuiAlert from '@mui/material/Alert'
import axiosInstance from "../../axios";
import { AuthStateContext } from "../state/AuthStateContext";
import { GifsContext } from "../state/gifContext";
import Title from "../Admin2/Comps/Title";
import Pusher from "pusher-js";
import { apiURL, CLUSTER, PUSHER_SECRET } from "../../helper/conf";
import { formatDate2 } from "../../helper/formatDate";

const Report = () =>{
    const { globalFilter } = useContext(GifsContext)
    const {text} = useLanguage()

    const { reports, photoRequests, languagesList } = useData()
    const {authState} = useContext(AuthStateContext)
    const [open, setOpen] = useState(false)
    const [ reportList, setReportList ] = useState([])

    const [openDialog, setOpenDialog] = useState(false)
    const [messageRep,setMessageRep] = useState('')
    const [ reportToAdd, setReportToAdd ] = useState()
    const [ archive, setArchive ] = useState();
    const [ loading, setLoading ] = useState(false)

    const [filter, setFilter] = useState({
        year: '',
        month: '',
        date: '',
        startingDate: '',
        closingDate: '',
        platform: '',
        agent: '',
        language: ''
    })
    const [filter2, setFilter2] = useState({
        year: '',
        month: '',
        date: '',
        startingDate: '',
        closingDate: '',
        platform: '',
        agent: '',
        language: ''
    })

    useEffect(()=>{
        axiosInstance.get(`/all_report?year=${filter.year}&month=${filter.month}&date=${formatDate2(filter.date)}&startingDate=${formatDate2(filter.startingDate)}&closingDate=${formatDate2(filter.closingDate)}&agent=${filter.agent}&platform=${filter.platform}&language=${filter.language}`, { 
            headers: {
                'Authorization': `Bearer ${authState.token}`
            }
        })
        .then((response)=>{
            setReportList(response.data)
        })
        .catch((error)=>{
            // console.log(error)
        })
   },[filter])

    useEffect(()=>{
        axiosInstance.get(`/archive_report?year=${filter2.year}&month=${filter2.month}&date=${formatDate2(filter2.date)}&startingDate=${formatDate2(filter2.startingDate)}&closingDate=${formatDate2(filter2.closingDate)}&agent=${filter2.agent}&platform=${filter2.platform}&language=${filter2.language}`, { 
            headers: {
                'Authorization': `Bearer ${authState.token}`
            }
        })
        .then((response)=>{
            setArchive(response.data)
        })
        .catch((error)=>{
            // console.log(error)
        })
   },[filter2])
   
    const reportAgent = () => {
        setLoading(true)
        axiosInstance.post(`/Notifications/Add/${reportToAdd.operatorId}`, {
            type: 'Report',
            content: messageRep,
            id_message:reportToAdd.id_message,
            id_report:reportToAdd.id_report,
            sender:true
        },{
            headers: { 
                'Authorization': `Bearer ${authState.token}` 
            }
        })
        .then(res => {
            if(res.status === 200){
                setReportList(p => p.filter((k) => k.id != reportToAdd.id))
                setArchive(p => [...p, res.data])
                // console.log(res.data)
                setOpen(true)
                setMessageRep('')
                setOpenDialog(false)
                setLoading(false)
            }
        })
        .catch(e => {})
    }

    // PUSHER RECEIVE REPORT
    useEffect(()=> {
        const pusher = new Pusher(PUSHER_SECRET,{
            cluster: CLUSTER, 
            channelAuthorization: {
                endpoint:`${apiURL}/pusher/user-auth`,
                transport:'jsonp'
            }
        })
        const channel = pusher.subscribe("private-report")
        channel.bind(`add-${authState.userId}`, (data) => {
            setReportList((d) => [ ...d, data])
        })
        return()=>{
            channel.unbind()
            pusher.unsubscribe("private-report")
        }
    },[])

    return(
        <Stack spacing={2} style={{ overflowY: "scroll" }}>
            <Snackbar
                open={open}
                autoHideDuration={1500}
                onClose={() => setOpen(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={() => setOpen(false)} severity="success">
                    {reportToAdd?.operatorName}: {text.agntinfrmd}
                </Alert>
            </Snackbar>
            {openDialog && <>
                <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                    <DialogTitle sx={{fontWeight:'bold',fontSize:'2em'}}>
                        {text.infrmagnt} {reportToAdd.operatorName} ?
                    </DialogTitle>
                    <DialogContent sx={{ fontWeight: 'bold', fontSize: '2em' }}>
                        <Stack sx={{gap:'5px'}}>
                            <Typography sx={{ fontWeight: 'bold' }}>
                                {text.mid} : {reportToAdd.id_message}
                            </Typography>
                            <textarea 
                                value={messageRep} 
                                onChange={e => setMessageRep(e.target.value)} 
                                style={{minHeight:'4em',padding:'5px',resize:"none"}} 
                                placeholder="Message a reporter..." 
                            ></textarea>
                        </Stack>
                    </DialogContent>
                    <DialogActions sx={{ textAlign: 'center', display: 'flex', justifyContent: 'space-around' }}>
                        <Button 
                            variant='contained' color='error' 
                            onClick={() => setOpenDialog(false)}
                        >
                            {text.annuler}
                        </Button>
                        <Button 
                            variant='contained' 
                            color='success' 
                            onClick={() => reportAgent()}
                        >
                            { loading ? text.phload : text.vld}
                        </Button>
                    </DialogActions>
                </Dialog>
            </>}
            <Stack style={{display: 'flex', alignItems: 'center', gap: '15px', justifyContent: 'center', flexDirection: 'row'}}>
                {/* <Typography variant="h1" alignSelf='center' style={{fontFamily: 'Fascinate Regular', fontWeight: 'bold', color: '#fff', WebkitTextStroke: '2px #ff9898'}}
                >{text.report}</Typography> */}
                <Title>{text.agrep}</Title>
                {/* <img className="card-img-resize-2" src={"/images/supervisor/9re.png"} alt="" height='50px' width='50px'/> */}
            </Stack>
            <BigContainerAccordion 
                transparent
                summaryBg="#f4a100"
                summary={text.report}
                details={
                    <Stack spacing={2}>
                        <SearchFilter
                            selectedDate={filter.date}
                            onDateChange={{
                                year: y => setFilter({...filter, year: y}),
                                month: m => setFilter({...filter, month: m}),
                                date: d => setFilter({...filter, date: d}),
                            }}
                            items= {[
                                { label: text.startingdate , comp: 'DatePicker', selected: filter.startingDate, onChange: d => setFilter({...filter, startingDate: d})},
                                { label: text.closingdate , comp: 'DatePicker', selected: filter.closingDate, onChange: d => setFilter({...filter, closingDate: d})},
                                { label: text.platform , comp: 'TextInput', onChange: e => setFilter({...filter, platform: e.target.value}) },
                                { label: text.language, comp: 'Select', choices: languagesList, onChange: e => setFilter({ ...filter, language: e.target.value }) },
                                { label: "Agent" , comp: 'TextInput', onChange: e => setFilter({...filter, agent: e.target.value}) },
                            ]}
                        />
                        <Stack spacing={'10px'}>
                            <TableRow header columns={[
                                { xs: .9,  text: text.date},
                                { xs: 1,  text: text.platform},
                                { xs: .5, text: text.language },
                                { xs: .8,  text: text.idrprt},
                                { xs: .8,  text: text.idmess},
                                { xs: 1.2,  text: text.mess},
                                { xs: 1.2,  text: text.user},
                                { xs: 1,  text: text.agentReport},
                                { xs: 1.2,  text: text.agent},
                                { xs: 1.2,  text: text.raison},
                                { xs: 1,  text: text.actions},
                            ]}/>
                            {
                                reportList?.map((r,i) => 
                                    <TableRow key={`r${i}`} columns={[
                                        { xs: .9,  text: r.reportdate ? format(new Date(r.reportdate), 'dd/MM/yyyy') : ''},
                                        { xs: 1,  text: r.platform},
                                        { xs: .5, text: r.language},
                                        { xs: .8,  text: r.id_report},
                                        { xs: .8,  text: r.id_message},
                                        { xs: 1.2,  text: r.message},
                                        { xs: 1.2,  text: r.sender_profil.Name},
                                        { xs: 1,  text: r.operatorName},
                                        { xs: 1.2,  text: r.agentSign},
                                        { xs: 1.2,  text: r.content},
                                        {
                                            xs: 1,
                                            customContent: true,
                                            content: <Button 
                                                style={{width: '100%', height: '100%', textTransform: 'capitalize', color: '#000'}} 
                                                onClick={()=> {setReportToAdd(r); setOpenDialog(true)}}
                                            >
                                                <Typography 
                                                    style={{fontFamily: 'Delm',color:r.status ? 'limegreen':'tomato'}}
                                                >
                                                    {!r.status ? text.infrmagnt:text.agentInform}
                                                </Typography>
                                            </Button>                       
                                        },
                                    ]} />
                                )
                            }
                        </Stack>
                    </Stack>
                }
            />
            <BigContainerAccordion 
                transparent
                summaryBg="#f4a100"
                summary="Archive Reports"
                details={
                    <Stack spacing={2}>
                        <SearchFilter
                            selectedDate={filter2.date}
                            onDateChange={{
                                year: y => setFilter2({...filter2, year: y}),
                                month: m => setFilter2({...filter2, month: m}),
                                date: d => setFilter2({...filter2, date: d}),
                            }}
                            items= {[
                                { label: text.startingdate , comp: 'DatePicker', selected: filter2.startingDate, onChange: d => setFilter2({...filter2, startingDate: d})},
                                { label: text.closingdate , comp: 'DatePicker', selected: filter2.closingDate, onChange: d => setFilter2({...filter2, closingDate: d})},
                                { label: text.platform , comp: 'TextInput', onChange: e => setFilter2({...filter2, platform: e.target.value}) },
                                { label: text.language, comp: 'Select', choices: languagesList, onChange: e => setFilter2({ ...filter2, language: e.target.value }) },
                                { label: "Agent" , comp: 'TextInput', onChange: e => setFilter2({...filter2, agent: e.target.value}) },
                            ]}
                        />
                        <Stack spacing={'10px'}>
                            <TableRow header columns={[
                                { xs: .8,  text: text.date},
                                { xs: 1,  text: text.platform},
                                { xs: .7, text: text.language },
                                { xs: .7,  text: text.idrprt},
                                { xs: .8,  text: text.idmess},
                                { xs: 1.1,  text: text.mess},
                                { xs: .8,  text: text.agentReport},
                                { xs: 1,  text: text.agent},
                                { xs: 1.1,  text: text.raison},
                                { xs: 1,  text: "Evaluator"},
                                { xs: 1,  text: "Supervisor message"},
                                { xs: .9,  text: text.actions},
                            ]}/>
                            {
                                archive?.map((r,i) => 
                                    <TableRow key={`r${i}`} columns={[
                                        { xs: .8,  text: r.reportdate ? format(new Date(r.reportdate), 'dd/MM/yyyy') : ''},
                                        { xs: 1,  text: r.platform},
                                        { xs: .7, text: r.language},
                                        { xs: .7,  text: r.id_report},
                                        { xs: .8,  text: r.id_message},
                                        { xs: 1.1,  text: r.message},
                                        { xs: .8,  text: r.operatorName},
                                        { xs: 1,  text: r.agentSign},
                                        { xs: 1.1,  text: r.content},
                                        { xs: 1,  text: r.NameAdmin},
                                        { xs: 1,  text: r.messAdmin},
                                        {
                                            xs: .9,
                                            customContent: true,
                                            content: <Button 
                                                style={{width: '100%', height: '100%', textTransform: 'capitalize', color: '#000'}} 
                                                onClick={()=>{ console.log("deleted..")}}
                                            >
                                                <Typography 
                                                    style={{fontFamily: 'Delm'}}
                                                >
                                                    {text.dlt}
                                                </Typography>
                                            </Button>                       
                                        },
                                    ]} />
                                )
                            }
                        </Stack>
                    </Stack>
                }
            />
        </Stack>
    )
}

export default Report